import React from "react";
import PageTitle from "components/Title";
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import _ from "lodash";
import ReactTable from "react-table";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

import WithGamePlatforms from "./actions";

class GamePlatformsContainer extends React.Component {
  componentDidMount = () => {
    this.props.getGamePlatforms();
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Game platforms"
          subheading="All game platforms in the system."
          icon="pe-7s-config icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={10}
                  data={this.props.games}
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name",
                    },
                    {
                      Header: "Starting amount",
                      accessor: "starting_amount",
                    },
                    {
                      Header: "Refund",
                      accessor: "refund",
                    },
                    {
                      Header: "Topup",
                      accessor: "topup",
                    },
                    {
                      Header: "Total bonus",
                      accessor: "total_bonus",
                    },
                    {
                      Header: "Total deposits",
                      accessor: "total_deposits",
                    },
                    {
                      Header: "Total transfer in",
                      accessor: "total_transfer_in",
                    },
                    {
                      Header: "Total transfer out",
                      accessor: "total_transfer_out",
                    },
                    {
                      Header: "Total withdrawal",
                      accessor: "total_withdrawal",
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.props.onLoadGames && <LoadingOverlay />}
      </>
    );
  };
}

export default WithGamePlatforms(GamePlatformsContainer);
