import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import _ from "lodash";
import { z } from "zod";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

const formSchema = z.object({
  user_id: z.number().min(1, "Bank id is required"),
  game_platform_id: z.number().min(1, "Staff id is required"),
  status: z.enum(["active", "inactive"]),
  is_selectable: z.boolean().nullable().optional(),
});

const UpdateGamePlatformModal = (props) => {
  const [errors, updateErrors] = React.useState([]);

  const onChangeGPModalData = (key, value) => {
    let tmp = _.cloneDeep(props.gamePlatformUser);
    tmp[key] = value;
    return props.oCGamePlatformUsersHOC("gamePlatformUser", tmp);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"md"}>
      <ModalHeader toggle={props.toggle}>Update game platform user</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Staff</Label>
            <Input
              type="select"
              value={props.gamePlatformUser.user_id}
              onChange={(e) => {
                onChangeGPModalData("user_id", e.target.value);
              }}
            >
              {props.staffs.map((user) => {
                return (
                  <option key={`Staff${user.id}`} value={user.id}>
                    {user.username}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Game</Label>
            <Input
              type="select"
              value={props.gamePlatformUser.bank_id}
              onChange={(e) => {
                onChangeGPModalData("bank_id", e.target.value);
              }}
            >
              {props.games.map((game) => {
                return (
                  <option key={`Game${game.id}`} value={game.id}>
                    {game.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Balance</Label>
            <Input
              type="number"
              value={props.gamePlatformUser.balance}
              disabled={true}
              min="0"
            />
          </FormGroup>
          <FormGroup>
            <Label>Status</Label>
            <Input
              type="select"
              value={props.gamePlatformUser.status}
              onChange={(e) => {
                onChangeGPModalData("status", e.target.value);
              }}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
          <FormGroup style={{ display: "flex", flexDirection: "row" }}>
            <Input
              addon
              type="checkbox"
              style={{ marginRight: "5px" }}
              checked={props.gamePlatformUser.is_selectable}
              onChange={(e) => {
                onChangeGPModalData("is_selectable", e.target.checked);
              }}
            />
            <Label for="name" style={{ marginBottom: 0 }}>
              Is selectable?
            </Label>
          </FormGroup>
        </Form>
        {props.onLoadGamePlatformUsers && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            let tmpErr = formSchema.safeParse(props.gamePlatformUser);
            if (!tmpErr.success) {
              console.log(tmpErr, "tmperr");
              updateErrors(tmpErr.error.issues);
            } else {
              // call api
              props.patchGamePlatformUser(props.gamePlatformUser);
            }
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateGamePlatformModal;
