import React, { Component } from "react";
import { connect } from "react-redux";
import Hamburger from "react-hamburgers";
import icon from "../../../assets/TemplateImages/logo.png";

import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "reducers/ThemeOptions";

import MobileMenu from "../Menu/MobileMenu";

class Logo extends Component {
  state = {
    active: false,
    mobile: false,
    activeSecondaryMenuMobile: false,
  };

  render = () => {
    return (
      <>
        <div className="app-header__logo">
          {/* <div className="logo-src" /> */}
          <div className="header__pane ml-auto">
            <div
              onClick={() =>
                this.props.setEnableClosedSidebar(
                  !this.props.enableClosedSidebar
                )
              }
            >
              <Hamburger
                active={!this.props.enableClosedSidebar}
                type="elastic"
                onClick={() => this.setState({ active: !this.state.active })}
              />
            </div>
          </div>
          {/* <img src={ icon } style={{ width: 100, height: 50, marginLeft: 10 }}></img> */}
        </div>
        <MobileMenu />
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

export default connect(mapStateToProps, {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
})(Logo);
