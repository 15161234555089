import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import _ from "lodash";

const UpdateBankModal = (props) => {
  return (
    <Modal isOpen={props.showUpdate}>
      <ModalHeader toggle={props.toggle}>Update Bank</ModalHeader>
      <ModalBody>
        <Form>
          {/* <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              value={props.bank.name}
              onChange={(e) => {
                let tmp = _.cloneDeep(props.bank);
                tmp.name = e.target.value;
                return props.oCBanksHOC("bank", tmp);
              }}
            />
          </FormGroup> */}
          <FormGroup>
            <Label>Bank charge</Label>
            <Input
              type="text"
              value={props.bank.bank_charge}
              onChange={(e) => {
                let tmp = _.cloneDeep(props.bank);
                tmp.bank_charge = e.target.value;
                return props.oCBanksHOC("bank", tmp);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Incentive</Label>
            <Input
              type="text"
              value={props.bank.incentive}
              onChange={(e) => {
                let tmp = _.cloneDeep(props.bank);
                tmp.incentive = e.target.value;
                return props.oCBanksHOC("bank", tmp);
              }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.putBank(props.bank);
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateBankModal;
