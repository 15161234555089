import React from "react";
import ReactTable from "react-table";
import Moment from "moment";
import { Badge, Button } from "reactstrap";
import _ from "lodash";
import Pagination from "components/Pagination";

// reload this table by calling get deposits api every 5s
const TableWithdrawalResult = (props) => {
  return (
    <>
      <ReactTable
        pageSize={10}
        data={props.successWithdrawals.data}
        showPagination={false}
        columns={[
          {
            Header: "Agent",
            Cell: (row) => {
              return <div>{row.original.user.username}</div>;
            },
          },
          {
            Header: "Player ID",
            Cell: (row) => {
              return <div>{row.original.player_account.player.slug}</div>;
            },
          },
          {
            Header: "Game platform",
            Cell: (row) => {
              return (
                <div>{row.original.game_platform_user.game_platform.name}</div>
              );
            },
          },
          {
            Header: "Game ID",
            Cell: (row) => {
              return (
                <div>{row.original.player_account.game_platform_username}</div>
              );
            },
          },
          {
            Header: "Withdraw amount",
            Cell: (row) => {
              return <div>{parseInt(row.original.amount, 10)}</div>;
            },
          },
          {
            Header: "Player bank account holder",
            Cell: (row) => {
              return (
                <div>
                  {row.original.player_bank_account
                    ? row.original.player_bank_account.holder_name
                    : ""}
                </div>
              );
            },
          },
          {
            Header: "Player bank",
            Cell: (row) => {
              return (
                <div>
                  {row.original.player_bank_account
                    ? row.original.player_bank_account.bank.name
                    : ""}
                </div>
              );
            },
          },
          {
            Header: "Player bank account number",
            Cell: (row) => {
              return (
                <div>
                  {row.original.player_bank_account
                    ? row.original.player_bank_account.account_number
                    : ""}
                </div>
              );
            },
          },
          {
            Header: "Status",
            Cell: (row) => (
              <div>
                {row.original.status === "pending" && (
                  <Badge color={"primary"}>{row.original.status}</Badge>
                )}
                {row.original.status === "success" && (
                  <Badge color={"success"}>{row.original.status}</Badge>
                )}
                {row.original.status === "failed" && (
                  <Badge color={"danger"}>{row.original.status}</Badge>
                )}
                {row.original.status === "rejected" && (
                  <Badge color={"warning"}>{row.original.status}</Badge>
                )}
              </div>
            ),
          },
          {
            Header: "Created at",
            Cell: (row) => {
              return (
                <div>
                  {Moment(row.original.created_at).format("DD/MM/YYYY HH:mm")}
                </div>
              );
            },
          },
          {
            Header: "Total tips",
            Cell: (row) => {
              return (
                <div>
                  {row.original.total_tips ? parseInt(row.original.total_tips, 10) : 0}
                </div>
              );
            },
          },
          {
            Header: "Total burns",
            Cell: (row) => {
              return (
                <div>
                  {row.original.total_burn ? parseInt(row.original.total_burn, 10) : 0}
                </div>
              );
            },
          },
          {
            Header: "Actions",
            Cell: (row) => (
              <div>
                <Button
                  color="primary"
                  className="btn-icon btn-icon-only"
                  onClick={() => {
                    props.onClickEditBank(
                      row.original.id,
                      row.original.bank_user?.id
                    );
                  }}
                >
                  <i className="pe-7s-pen btn-icon-wrapper" />
                </Button>
              </div>
            ),
          },
        ]}
      />
      <Pagination
        pages={props.successWithdrawals.meta.pages}
        currentPage={props.search.page}
        onPageChange={(pg) => {
          let tmp = _.cloneDeep(props.search);
          tmp["page"] = pg;
          props.onChangeSearch(tmp);
          props.getSuccessWithdrawals(tmp);
        }}
      />
    </>
  );
};

export default TableWithdrawalResult;
