import React from "react";
import { Route } from "react-router-dom";
import _ from "lodash";

import Header from "./Header";
import Sidemenu from "./Menu/Sidemenu";
import Shifts from "./Shifts";
import Deposits from "./Deposits";
import BulkDeposits from "./BulkDeposits";
import Withdrawals from "./Withdrawals";
import Users from "./Users";
import Players from "./Players";
import Promotions from "./Promotions";
import Banks from "./Banks";
import BankUsers from "./BankUsers";
import GamePlatforms from "./GamePlatforms";
import GamePlatformUsers from "./GamePlatformUsers";
import KioskBalances from "./KioskBalances";
import Reports from "./Reports";
import ReportsAccountTxns from "./Reports/AccountTransactions";
import ClosingReports from "./ClosingReports";
import Transfers from "./Transfers";
import Statistics from "./Stats";
import Adjustments from "./Adjustments";

import LoadingOverlay from "components/Indicator/LoadingOverlay";

import WithProfile from "actions/Profile";

import AxiosInterceptors from "AxiosInterceptors";

class Dashboard extends React.Component {
  componentDidMount = () => {
    this.props.getLoginUserProfile();
  };

  render = () => {
    return (
      <>
        {this.props.profile.role !== "" && (
          <Header history={this.props.history} profile={this.props.profile} />
        )}
        <div className="app-main">
          <Sidemenu profile={this.props.profile} />
          <div className="app-main__outer">
            <div className="app-main__inner">
              {this.props.profile.role !== "" && (
                <>
                  <Route exact path="/dashboard/stats">
                    <Statistics profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/shifts">
                    <Shifts profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/deposits">
                    <Deposits profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/bulk-deposits">
                    <BulkDeposits profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/withdrawals">
                    <Withdrawals profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/users">
                    <Users profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/players">
                    <Players profile={this.props.profile} />
                  </Route>
                  <Route
                    exact
                    path="/dashboard/promotions"
                    component={Promotions}
                  />
                  <Route exact path="/dashboard/banks" component={Banks} />
                  <Route
                    exact
                    path="/dashboard/bank-users"
                    component={BankUsers}
                  />
                  <Route
                    exact
                    path="/dashboard/games"
                    component={GamePlatforms}
                  />
                  <Route exact path="/dashboard/game-platform-users">
                    <GamePlatformUsers profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/kiosk-balances">
                    <KioskBalances profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/reports">
                    <Reports profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/reports-account-txns">
                    <ReportsAccountTxns profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/closing-reports">
                    <ClosingReports profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/transfers">
                    <Transfers profile={this.props.profile} />
                  </Route>
                  <Route exact path="/dashboard/adjustments">
                    <Adjustments profile={this.props.profile} />
                  </Route>
                </>
              )}
            </div>
          </div>
        </div>
        {this.props.onLoadProfile && <LoadingOverlay />}
      </>
    );
  };
}

export default WithProfile(Dashboard);
