import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      promotions: [],
      promotion: {},
    };

    oCPromoHOC = (key, val) => this.setState({ [key]: val });

    getPromotions = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(`${STAGING_BACKEND_URL}/promotions`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({ promotions: response.data.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    postPromotion = async (promotion) => {
      this.setState({ loading: true });
      try {
        await Axios.post(`${STAGING_BACKEND_URL}/promotions`, promotion, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.getPromotions();
        toast.success("Promotion created successfully");
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    updatePromotion = async (promotion) => {
      this.setState({ loading: true });
      try {
        await Axios.put(
          `${STAGING_BACKEND_URL}/promotions/${promotion.id}`,
          promotion,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.getPromotions();
        toast.success("Promotion updated successfully");
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadPromotions={this.state.loading}
          promotions={this.state.promotions}
          promotion={this.state.promotion}
          getPromotions={this.getPromotions}
          postPromotion={this.postPromotion}
          updatePromotion={this.updatePromotion}
          oCPromoHOC={this.oCPromoHOC}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
