import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import ReactTable from "react-table";

const ViewSelectedAdjustment = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="xl">
      <ModalHeader toggle={props.toggle}>View Selected Adjustment</ModalHeader>
      <ModalBody>
        <Card className="mb-1">
          <CardHeader>Failed deposits</CardHeader>
          <CardBody>
            <ReactTable
              data={props.selectedAdjustment.failing_payload.deposits}
              columns={[
                {
                  Header: "Staff ID",
                  accessor: "staff_username",
                },
                {
                  Header: "Bank ID",
                  accessor: "bank_id",
                },
                {
                  Header: "Bank",
                  Cell: (row) => {
                    return props.banks.map((item) => {
                      if (item.id === row.original.bank_id) {
                        return <span>{item.name}</span>;
                      }
                    });
                  },
                },
                {
                  Header: "Player ID",
                  accessor: "player_slug",
                },
                {
                  Header: "Game",
                  Cell: (row) => {
                    return props.games.map((item) => {
                      if (item.id === row.original.game_platform_id) {
                        return <span>{item.name}</span>;
                      }
                    });
                  },
                },
                {
                  Header: "Game ID",
                  accessor: "player_game_platform_username",
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                },
                {
                  Header: "Promotion",
                  Cell: (row) => {
                    return props.promotions.map((item) => {
                      if (item.id === row.original.promotion_id) {
                        return <span>{item.name}</span>;
                      }
                    });
                  },
                },
                {
                  Header: "Bonus",
                  accessor: "bonus_amount",
                },
                {
                  Header: "Errors",
                  Cell: (row) => {
                    return row.original.errors.map((item) => {
                      return <span>{item}</span>;
                    });
                  },
                },
              ]}
              pageSize={10}
            />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Failed withdrawals</CardHeader>
          <CardBody>
            <ReactTable
              data={props.selectedAdjustment.failing_payload.withdrawals}
              columns={[
                {
                  Header: "Staff ID",
                  accessor: "staff_username",
                },
                {
                  Header: "Player ID",
                  accessor: "player_slug",
                },
                {
                  Header: "Game",
                  Cell: (row) => {
                    return props.games.map((item) => {
                      if (item.id === row.original.game_platform_id) {
                        return <span>{item.name}</span>;
                      }
                    });
                  },
                },
                {
                  Header: "Game ID",
                  accessor: "player_game_platform_username",
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                },
                {
                  Header: "Player bank",
                  Cell: (row) => {
                    return props.banks.map((item) => {
                      if (item.id === row.original.player_bank_id) {
                        return <span>{item.name}</span>;
                      }
                    });
                  },
                },
                {
                  Header: "Player bank account number",
                  accessor: "player_bank_account_number",
                },
                {
                  Header: "Errors",
                  Cell: (row) => {
                    return row.original.errors.map((item) => {
                      return <span>{item}</span>;
                    });
                  },
                },
              ]}
              pageSize={10}
            />
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default ViewSelectedAdjustment;
