import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import Axios from "axios";
import { toast } from "react-toastify";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error, "errrr");
    const originalRequest = error.config;
    if (error.config.url.indexOf("/refresh") > -1) {
      if (error.response.data.code === 460) {
        alert(
          "Due to inactivity, your session has expired, please login again."
        );
        window.localStorage.setItem("GAMEPORT_ACCESS_TOKEN", "");
        window.localStorage.setItem("GAMEPORT_ACCESS_TOKEN_EXPIRED", "");
        window.localStorage.setItem("GAMEPORT_CURRENT_LOGIN_USER_ID", "");
        window.location.href = "/";
      }
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return Axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;
      return new Promise((resolve, reject) => {
        Axios.put(
          `${STAGING_BACKEND_URL}/tokens/refresh`,
          {
            refresh_token: window.localStorage.getItem(
              "GAMEPORT_REFRESH_TOKEN"
            ),
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-public-key": X_PUBLIC_KEY,
            },
          }
        )
          .then((response) => {
            console.log(response, "response");
            window.localStorage.setItem(
              "GAMEPORT_ACCESS_TOKEN",
              response.data.access_token
            );
            window.localStorage.setItem(
              "GAMEPORT_REFRESH_TOKEN",
              response.data.refresh_token
            );
            Axios.defaults.headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            };
            originalRequest.headers["Authorization"] =
              "Bearer " + response.data.access_token;
            processQueue(null, response.data.access_token);
            resolve(Axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    if(error.response.status === 422) {
      toast.error(error.response.data.errors[0]);
    }
    return Promise.reject(error);
  }
);
