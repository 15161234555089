import React, { Component } from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      loadingSub: false,
      playerAccounts: [],
      playerAccountsSub: [],
    };

    findPlayerAccounts = async (player_id, game_platform_id) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/player_accounts?q[player_slug_eq]=${player_id}&q[game_platform_id_eq]=${game_platform_id}&q[status_eq]=0`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ playerAccounts: response.data.data });
      } catch (error) {
        toast.error("Error fetching player accounts");
      } finally {
        this.setState({ loading: false });
      }
    };

    findPlayerAccountsSub = async (player_id, game_platform_id) => {
      this.setState({ loadingSub: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/player_accounts?q[player_slug_eq]=${player_id}&q[game_platform_id_eq]=${game_platform_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ playerAccountsSub: response.data.data });
      } catch (error) {
        toast.error("Error fetching player accounts");
      } finally {
        this.setState({ loadingSub: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          loadingSub={this.state.loadingSub}
          onLoadPlayerAccounts={this.state.loading}
          playerAccounts={this.state.playerAccounts}
          playerAccountsSub={this.state.playerAccountsSub}
          findPlayerAccounts={this.findPlayerAccounts}
          findPlayerAccountsSub={this.findPlayerAccountsSub}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
