import React, { useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  Button,
  UncontrolledButtonDropdown,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ChangePasswordModal from "./ChangePassword";

import WithChangePassword from "./actions";

import avatar1 from "assets/images/av1.png";

const Details = ({ open, content, onClose }) => {
  return (
    <Modal isOpen={open} size={"md"}>
      <ModalHeader
        toggle={() => onClose()}
      >{`Notification Details`}</ModalHeader>
      <ModalBody>
        <span>{content}</span>
      </ModalBody>
    </Modal>
  );
};

const Userbox = ({
  data,
  history,
  // onLoadForgotPassword,
  // setNotificationRead,
  // getLoginUserProfile,
  profile,
  old_password,
  new_password,
  user_id,
  onLoadChangePassword,
  showChangePassword,
  oCPasswordHOC,
  putPassword,
}) => {
  // const [ showDetails, onToggleDetail ] = useState( false )
  // const [ selectedNotification, onChangeSelected ] = useState( false )
  // const { current_role_id } = data.ProfileReducer

  // const {
  //   notification
  // } = data.NotificationReducer
  // const {
  //   email,
  //   name
  // } = data.ProfileReducer.profile.user

  return (
    <>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              {/* <NotificationContent
                history={ history }
                notification={ notification }
                setNotificationRead={ setNotificationRead }
                onToggleDetail={ onToggleDetail }
                onChangeSelected={ onChangeSelected }
              /> */}
              {/* <SwitchRolesContent 
                history={ history }
              /> */}
              {/* {
                current_role_id !== 13 && (
                  <SwitchSessions/>
                )
              }  */}
              <span className="mr-3">{profile.username}</span>
              <span className="mr-3">{profile.role}</span>
              <span className="mr-3">{profile.name}</span>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <img
                    width={42}
                    className="rounded-circle"
                    src={avatar1}
                    alt=""
                  />
                  <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu
                  right
                  className="rm-pointers dropdown-menu-lg p-0"
                >
                  <div
                    className="dropdown-menu-header"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="dropdown-menu-header-inner bg-white">
                      <div className="menu-header-image opacity-2" />
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img
                                width={42}
                                className="rounded-circle"
                                src={avatar1}
                                alt=""
                              />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading text-dark">
                                {profile.username}
                              </div>
                              <span className="badge badge-success">
                                {profile.role}
                              </span>
                              <div className="widget-subheading text-dark opacity-8">
                                {profile.name}
                              </div>
                              <Button
                                className="btn-pill bg-primary text-light btn-shadow btn-shine"
                                color="focus"
                                onClick={() => {
                                  oCPasswordHOC(
                                    "showChangePassword",
                                    !showChangePassword
                                  );
                                }}
                              >
                                Change password
                              </Button>
                            </div>
                            <div className="widget-content-right mr-2">
                              <Button
                                className="btn-pill bg-dark text-light btn-shadow btn-shine"
                                color="focus"
                                onClick={() => {
                                  // clearItem( 'USM_IMS_TOKEN' )
                                  window.localStorage.setItem(
                                    "GAMEPORT_ACCESS_TOKEN",
                                    ""
                                  );
                                  window.localStorage.setItem(
                                    "GAMEPORT_ACCESS_TOKEN_EXPIRED",
                                    ""
                                  );
                                  window.localStorage.setItem(
                                    "GAMEPORT_CURRENT_LOGIN_USER_ID",
                                    ""
                                  );
                                  history.push("/");
                                }}
                              >
                                Logout
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
      {/* <Details
        open={ showDetails }
        content={ selectedNotification?.content??'' }
        onClose={ () => onToggleDetail( false )}
      /> */}
      <ChangePasswordModal
        old_password={old_password}
        new_password={new_password}
        user_id={user_id}
        onLoadChangePassword={onLoadChangePassword}
        isOpen={showChangePassword}
        oCPasswordHOC={oCPasswordHOC}
        profile={profile}
        putPassword={putPassword}
        toggle={() => {
          oCPasswordHOC("showChangePassword", !showChangePassword);
        }}
      />
    </>
  );
};

export default WithChangePassword(Userbox);
