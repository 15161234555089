export const AdminNav = [
  {
    icon: "pe-7s-graph3",
    label: "Statistics",
    to: "#/dashboard/stats",
  },
  {
    icon: "pe-7s-users",
    label: "Users",
    to: "#/dashboard/users",
  },
  {
    icon: "pe-7s-config",
    label: "Promotions",
    to: "#/dashboard/promotions",
  },
  {
    icon: "pe-7s-cash",
    label: "Bank Accounts",
    to: "#/dashboard/banks",
  },
  {
    icon: "pe-7s-user",
    label: "Kiosk balance - banks",
    to: "#/dashboard/bank-users",
  },
  {
    icon: "pe-7s-users",
    label: "Kiosk balance - games",
    to: "#/dashboard/game-platform-users",
  },
  {
    icon: "pe-7s-cash",
    label: "Games",
    to: "#/dashboard/games",
  },
  {
    icon: "pe-7s-way",
    label: "Transfers",
    to: "#/dashboard/transfers",
  },
  {
    icon: "pe-7s-users",
    label: "Players",
    to: "#/dashboard/players",
  },
  {
    icon: "pe-7s-cash",
    label: "Deposits",
    to: "#/dashboard/deposits",
  },
  {
    icon: "pe-7s-cash",
    label: "Bulk Deposits",
    to: "#/dashboard/bulk-deposits",
  },
  {
    icon: "pe-7s-download",
    label: "Withdrawals",
    to: "#/dashboard/withdrawals",
  },
  {
    icon: "pe-7s-mail-open-file",
    label: "Reports",
    to: "#/dashboard/reports",
  },
  {
    icon: "pe-7s-mail-open-file",
    label: "Report - Account Txns",
    to: "#/dashboard/reports-account-txns",
  },
  {
    icon: "pe-7s-mail-open-file",
    label: "Report - Closing",
    to: "#/dashboard/closing-reports",
  },
  {
    icon: "pe-7s-repeat",
    label: "Adjustments",
    to: "#/dashboard/adjustments",
  },
  {
    icon: "pe-7s-safe",
    label: "Shifts",
    to: "#/dashboard/shifts",
  },
];

export const StaffNav = [
  {
    icon: "pe-7s-graph3",
    label: "Statistics",
    to: "#/dashboard/stats",
  },
  {
    icon: "pe-7s-safe",
    label: "Shifts",
    to: "#/dashboard/shifts",
  },
  {
    icon: "pe-7s-users",
    label: "Players",
    to: "#/dashboard/players",
  },
  {
    icon: "pe-7s-way",
    label: "Transfers",
    to: "#/dashboard/transfers",
  },
  {
    icon: "pe-7s-wallet",
    label: "Kiosk Balance",
    to: "#/dashboard/kiosk-balances",
  },
  {
    icon: "pe-7s-cash",
    label: "Deposits",
    to: "#/dashboard/deposits",
  },
  {
    icon: "pe-7s-cash",
    label: "Bulk Deposits",
    to: "#/dashboard/bulk-deposits",
  },
  {
    icon: "pe-7s-download",
    label: "Withdrawals",
    to: "#/dashboard/withdrawals",
  },
  {
    icon: "pe-7s-repeat",
    label: "Adjustments",
    to: "#/dashboard/adjustments",
  },
  {
    icon: "pe-7s-mail-open-file",
    label: "Report - Closing",
    to: "#/dashboard/closing-reports",
  },
];
