import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ModalFooter,
} from "reactstrap";
import { z } from "zod";
import _ from "lodash";

import LoadingOverlay from "components/Indicator/LoadingOverlay";

const formSchema = z.object({
  old_password: z.string().min(1, "Old password is required"),
  new_password: z.string().min(1, "New password is required"),
});

const ChangePasswordModal = (props) => {
  const [errors, updateErrors] = React.useState([]);
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"md"}>
      <ModalHeader toggle={props.toggle}>Change password</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Old password</Label>
            <Input
              type="password"
              value={props.old_password}
              onChange={(e) => {
                props.oCPasswordHOC("old_password", e.target.value);
              }}
            />
            {errors.length > 0 &&
              _.findIndex(errors, { path: ["old_password"] }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, {
                        path: ["old_password"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Label>New password</Label>
            <Input
              type="password"
              value={props.new_password}
              onChange={(e) => {
                props.oCPasswordHOC("new_password", e.target.value);
              }}
            />
            {errors.length > 0 &&
              _.findIndex(errors, { path: ["new_password"] }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, {
                        path: ["new_password"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
        </Form>
        {props.onLoadChangePassword && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            const result = formSchema.safeParse({
              old_password: props.old_password,
              new_password: props.new_password,
            });
            if (!result.success) {
              updateErrors(result.error.errors);
              return;
            }
            props.putPassword({
              old_password: props.old_password,
              new_password: props.new_password,
              user_id: props.profile.id,
            });
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangePasswordModal;
