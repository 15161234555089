import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      players: [],
      playersMeta: {
        pages: 0,
        items: 50,
        page: 1,
      },
      toRemovePlayerID: 0,
      showConfirmDeletePlayer: false,
      newPlayer: {
        player_id: 0,
        game_platform_username: "",
        password: "",
        game_platform_id: 0,
        register_mode: "manual",
      },
    };

    oCPlayerHOC = (key, val) => this.setState({ [key]: val });

    getPlayers = async (search, pg) => {
      this.setState({ loading: true });
      let tmp = "";
      if (search) {
        Object.keys(search).map((key) => {
          if (
            search[key] &&
            search[key] !== "" &&
            typeof search[key] !== "number"
          ) {
            if (tmp.length === 0) {
              tmp += `&${key}=${search[key]}`;
            } else {
              tmp += `&${key}=${search[key]}`;
            }
          }
          if (typeof search[key] === "number") {
            tmp += `&${key}=${search[key]}`;
          }
        });
      }
      try {
        let res = await Axios.get(
          `${STAGING_BACKEND_URL}/players${
            tmp.length > 0
              ? `?paginate=true&page=${
                  pg ? pg : this.state.playersMeta.page
                }${tmp}`
              : `?paginate=true&page=${pg ? pg : this.state.playersMeta.page}`
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ players: res.data.data, playersMeta: res.data.meta });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    postPlayer = async (payload) => {
      this.setState({ loading: true });
      try {
        await Axios.post(`${STAGING_BACKEND_URL}/players`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({
          newPlayer: {
            player_id: 0,
            game_platform_username: "",
            password: "",
            game_platform_id: 0,
            register_mode: "manual",
            player_slug: "",
          },
        });
        toast.success(`Player successfully added!`);
        this.getPlayers();
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    createPlayer = async (game_platform_id) => {
      this.setState({ loading: true });
      try {
        await Axios.post(
          `${STAGING_BACKEND_URL}/game_platforms/${game_platform_id}/add_player`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        toast.success(`Player successfully added!`);
        this.getPlayers();
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    putPlayer = async (id, slug) => {
      this.setState({ loading: true });
      try {
        await Axios.patch(
          `${STAGING_BACKEND_URL}/players/${id}`,
          {
            slug,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        toast.success(`Player successfully updated!`);
        this.getPlayers();
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    deletePlayer = async () => {
      this.setState({ loading: true });
      try {
        let res = await Axios.delete(
          `${STAGING_BACKEND_URL}/players/${this.state.toRemovePlayerID}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          showConfirmDeletePlayer: false,
          toRemovePlayerID: 0,
        });
        this.getPlayers();
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadPlayers={this.state.loading}
          players={this.state.players}
          showConfirmDeletePlayer={this.state.showConfirmDeletePlayer}
          playersMeta={this.state.playersMeta}
          newPlayer={this.state.newPlayer}
          getPlayers={this.getPlayers}
          postPlayer={this.postPlayer}
          putPlayer={this.putPlayer}
          deletePlayer={this.deletePlayer}
          oCPlayerHOC={this.oCPlayerHOC}
          createPlayer={this.createPlayer}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
