import React from "react";
import PageTitle from "components/Title";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Table,
  FormGroup,
  Label,
  Input,
  CardHeader,
} from "reactstrap";
import _ from "lodash";
import { compose } from "redux";
import ReactTable from "react-table";
import Moment from "moment";
import { Badge } from "reactstrap";

import Pagination from "components/Pagination";
import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import PromotionTabs from "./Promotions";

import WithDeposits from "./actions";
import WithBanks from "../Banks/actions";
import WithGames from "../GamePlatforms/actions";
import WithPlayerAccounts from "../Deposits/actions/playerAccounts";
import WithPromotions from "../Promotions/actions";

class BulkDeposits extends React.Component {
  state = {
    currentTab: "banks", // players
    playerId: "",
    gamePlatformId: "",
    upperLevelTab: "bulk-deposit", // fail-deposit-history
  };

  componentDidMount = () => {
    this.props.getBanks();
    this.props.getGamePlatforms();
    this.props.getPromotions();
    this.props.listDeposits(1);
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Bulk Deposits"
          subheading="Place to reload deposits for the players."
          icon="pe-7s-cash icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <Row>
          <Col lg={12}>
            <div className="d-flex mb-2">
              <Button
                color="primary"
                className="mr-2"
                onClick={() => this.setState({ upperLevelTab: "bulk-deposit" })}
              >
                Bulk Deposit
              </Button>
              <Button
                color="secondary"
                className="mr-2"
                onClick={() =>
                  this.setState({ upperLevelTab: "fail-deposit-history" }, () =>
                    this.props.getDepositHistories()
                  )
                }
              >
                Fail Deposit History
              </Button>
            </div>
            {this.state.upperLevelTab === "bulk-deposit" && (
              <Card className="main-card mb-3">
                <CardBody>
                  <FormGroup>
                    <p>The input data will need to be in such format: </p>
                    <p>{`Sequence: Product ID, Player ID, Player Account ID, Promotion ID, Bonus Amount`}</p>
                    <p>{`sample : 3,101,77,6,60;`}</p>
                  </FormGroup>
                  <FormGroup>
                    <Label>Deposit data</Label>
                    <br />
                    <textarea
                      style={{ width: "100%", height: "30vh" }}
                      value={this.props.newDeposit.deposit_data}
                      onChange={(e) => {
                        this.props.oCKioskDepositHOC("newDeposit", {
                          deposit_data: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    color="primary"
                    className="ml-auto"
                    onClick={() =>
                      this.props.postDeposit(this.props.newDeposit)
                    }
                  >
                    Submit
                  </Button>
                </CardFooter>
              </Card>
            )}
            {this.state.upperLevelTab === "fail-deposit-history" && (
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={this.props.histories.data}
                    pageSize={this.props.histories.data.length}
                    showPagination={false}
                    align="center"
                    columns={[
                      {
                        Header: "Action type",
                        accessor: "action_type",
                      },
                      {
                        Header: "Payload",
                        Cell: (row) => (
                          <div>
                            {JSON.stringify(row.original.payload, 2, 2)}
                          </div>
                        ),
                      },
                      {
                        Header: "Response",
                        Cell: (row) => (
                          <div>
                            {row.original.response.map((item) => {
                              return (
                                <p style={{ marginBottom: "5px" }}>{item}</p>
                              );
                            })}
                          </div>
                        ),
                      },
                      {
                        Header: "Created at",
                        Cell: (row) => (
                          <span>
                            {Moment(row.original.created_at).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </span>
                        ),
                      },
                    ]}
                  />
                  <Pagination
                    pages={this.props.histories.meta.pages}
                    onPageChange={(pg) => {
                      this.props.getDepositHistories(pg);
                    }}
                    currentPage={this.props.histories.meta.page}
                  />
                </CardBody>
              </Card>
            )}
            <Card>
              <CardBody>
                <div className="d-flex mb-2">
                  <Button
                    color="primary"
                    className="mr-2"
                    onClick={() => this.setState({ currentTab: "banks" })}
                  >
                    Banks/Products
                  </Button>
                  <Button
                    color="secondary"
                    className="mr-2"
                    onClick={() => this.setState({ currentTab: "players" })}
                  >
                    Players/Player accounts
                  </Button>
                </div>
                {this.state.currentTab === "banks" && (
                  <>
                    <Row>
                      <Col md={3}>
                        <FormGroup className="pb-2">
                          <Label className="mr-2">Banks</Label>
                          <Table>
                            <thead>
                              <td>ID</td>
                              <td>Name</td>
                            </thead>
                            <tbody>
                              {this.props.banks.map((item) => {
                                return (
                                  <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="pb-2">
                          <Label className="mr-2">Products</Label>
                          <Table>
                            <thead>
                              <td>ID</td>
                              <td>Name</td>
                            </thead>
                            <tbody>
                              {this.props.games.map((item) => {
                                return (
                                  <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </FormGroup>
                      </Col>
                      <PromotionTabs promotions={this.props.promotions} />
                    </Row>
                  </>
                )}
                {this.state.currentTab === "players" && (
                  <Row>
                    <Col md={6}>
                      <FormGroup className="pb-2">
                        <Label className="mr-2">Players</Label>
                        <Input
                          type="text"
                          value={this.state.playerId}
                          onChange={(e) => {
                            this.setState({ playerId: e.target.value });
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="pb-2">
                        <Label className="mr-2">Game platform</Label>
                        <Input
                          type="select"
                          className="form-control"
                          value={this.state.gamePlatformId}
                          onChange={(e) => {
                            this.setState({ gamePlatformId: e.target.value });
                          }}
                        >
                          <option></option>
                          {this.props.games.map((item) => (
                            <option
                              key={`GamePlatform-${item.id}`}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      <Button
                        color="primary"
                        disabled={
                          this.state.playerId === "" ||
                          this.state.gamePlatformId === ""
                        }
                        onClick={() => {
                          this.props.findPlayerAccounts(
                            this.state.playerId,
                            this.state.gamePlatformId
                          );
                        }}
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col md={6}>
                      <Table>
                        <thead>
                          <td>Player ID</td>
                          <td>Player account username</td>
                          <td>Player account ID</td>
                          <td>Status</td>
                        </thead>
                        <tbody>
                          {this.props.playerAccounts.map((item) => {
                            return (
                              <tr key={item.id}>
                                <td>{item.player.slug}</td>
                                <td>{item.game_platform_username}</td>
                                <td>{item.id}</td>
                                <td>{item.status}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>Bonus Listings</CardHeader>
              <CardBody>
                <ReactTable
                  data={this.props.deposits.data}
                  pageSize={this.props.deposits.data.length}
                  showPagination={false}
                  align="center"
                  columns={[
                    {
                      Header: "Player ID",
                      Cell: (row) => (
                        <span>{row.original.player_account.player.slug}</span>
                      ),
                    },
                    {
                      Header: "Product",
                      Cell: (row) => (
                        <span>
                          {row.original.game_platform_user.game_platform.name}
                        </span>
                      ),
                    },
                    {
                      Header: "Game ID",
                      Cell: (row) => (
                        <span>
                          {row.original.player_account.game_platform_username}
                        </span>
                      ),
                    },
                    {
                      Header: "Promotion",
                      accessor: "promotion.name",
                    },
                    {
                      Header: "Promo Amount",
                      Cell: (row) => (
                        <span>
                          {row.original.bonus_amount
                            ? row.original.bonus_amount
                            : ""}
                        </span>
                      ),
                    },
                    {
                      Header: "Status",
                      Cell: (row) => (
                        <>
                          <Badge
                            color={
                              row.original.status === "success"
                                ? "success"
                                : "danger"
                            }
                          >
                            {row.original.status}
                          </Badge>
                        </>
                      ),
                    },
                    {
                      Header: "Created at",
                      Cell: (row) => (
                        <span>
                          {Moment(row.original.created_at).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </span>
                      ),
                    },
                  ]}
                />
                <Pagination
                  pages={this.props.deposits.meta.pages}
                  onPageChange={(pg) => {
                    this.props.listDeposits(pg);
                  }}
                  currentPage={this.props.deposits.meta.page}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {(this.props.onLoadDeposits ||
          this.props.onLoadBanks ||
          this.props.onLoadGames ||
          this.props.onLoadPlayerAccounts ||
          this.props.onLoadPromotions) && <LoadingOverlay />}
      </>
    );
  };
}

export default compose(
  WithDeposits,
  WithBanks,
  WithGames,
  WithPlayerAccounts,
  WithPromotions
)(BulkDeposits);
