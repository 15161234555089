import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      users: [],
      staffs: [],
      admins: [],
      showCreateUserModal: false,
      showUpdateUserModal: false,
      user: {
        name: "",
        role: "",
      },
      showPromptModal: false,
      tempDeleteUserID: 0,
    };

    oCUsersHOC = (key, val) => this.setState({ [key]: val });

    getUsers = async () => {
      this.setState({ loading: true });
      try {
        let res = await Axios.get(`${STAGING_BACKEND_URL}/users`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({ users: res.data.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getUsersWithRoleStaffs = async () => {
      this.setState({ loading: true });
      try {
        let res = await Axios.get(
          `${STAGING_BACKEND_URL}/users?q[role_eq]=0&q[status_eq]=0`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ staffs: res.data.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getUsersWithRoleAdmins = async () => {
      this.setState({ loading: true });
      try {
        let res = await Axios.get(`${STAGING_BACKEND_URL}/users?q[role_eq]=1`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({ admins: res.data.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    postUser = async (user) => {
      this.setState({ loading: true });
      try {
        let res = await Axios.post(`${STAGING_BACKEND_URL}/users`, user, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({ showCreateUserModal: false });
        this.getUsers();
        toast.success("User is created successfully");
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    patchUser = async (user) => {
      this.setState({ loading: true });
      try {
        let res = await Axios.patch(
          `${STAGING_BACKEND_URL}/users/${user.id}`,
          user,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        toast.success("User is updated successfully");
        this.getUsers();
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getUser = async (id, success) => {
      this.setState({ loading: true });
      try {
        let res = await Axios.get(`${STAGING_BACKEND_URL}/users/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({ user: res.data });
        if (success) {
          success();
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    deleteUser = async (id) => {
      this.setState({ loading: true });
      try {
        let res = await Axios.delete(`${STAGING_BACKEND_URL}/users/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({ showPromptModal: false });
        toast.success("User is removed successfully");
        this.getUsers();
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadUsers={this.state.loading}
          users={this.state.users}
          staffs={this.state.staffs}
          admins={this.state.admins}
          showCreateUserModal={this.state.showCreateUserModal}
          showUpdateUserModal={this.state.showUpdateUserModal}
          user={this.state.user}
          tempDeleteUserID={this.state.tempDeleteUserID}
          showPromptModal={this.state.showPromptModal}
          deleteUser={this.deleteUser}
          oCUsersHOC={this.oCUsersHOC}
          getUsers={this.getUsers}
          postUser={this.postUser}
          getUser={this.getUser}
          patchUser={this.patchUser}
          getUsersWithRoleStaffs={this.getUsersWithRoleStaffs}
          getUsersWithRoleAdmins={this.getUsersWithRoleAdmins}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
