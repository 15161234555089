import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { z } from "zod";
import _ from "lodash";

const formSchema = z
  .object({
    username: z
      .string({
        required_error: "Username is required",
      })
      .min(1, "Username is required")
      .max(30),
    role: z.string().min(1, "Role is required"),
    status: z.string().min(1, "Status is required"),
    password: z
      .string({
        required_error: "Password is required",
      })
      .min(1, "Password is required")
      .min(8, "Password must have more than 8 characters"),
    password_confirmation: z
      .string({
        required_error: "Password confirmation is required",
      })
      .min(1, "Password confirmation is required"),
    team_id: z.number(),
    // parent_id: z.number().min(1, "Under which admin is required"),
  })
  .refine((data) => {
    return (
      data.password === data.password_confirmation,
      {
        path: ["confirm_password"],
        message: "Passwords do not match",
      }
    );
  })
  .refine((data) => {
    return (
      data.role === "staff",
      {
        path: ["parent_id"],
        message: "Under which admin is required",
      }
    );
  });

const CreateUserModal = (props) => {
  const [newUser, setNewUser] = React.useState({
    name: "admin",
    role: "admin",
    status: "active",
    password: "",
    password_confirmation: "",
    parent_id: 0,
    username: "",
    team_id: 0,
  });
  const [errors, setErrors] = React.useState([]);

  React.useEffect(() => {
    if (props.isOpen) {
      props.getUsersWithRoleAdmins();
    }
  }, [props.isOpen]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"md"}>
      <ModalHeader toggle={props.toggle}>Create User</ModalHeader>
      <ModalBody>
        <Form>
          {/* required */}
          <FormGroup>
            <Label for="username">Username</Label>
            <Input
              type="text"
              id="username"
              value={newUser.username}
              onChange={(e) =>
                setNewUser({ ...newUser, username: e.target.value })
              }
            />
            {errors.length > 0 &&
              _.findIndex(errors, { path: ["username"] }) > -1 && (
                <span className="text-danger">
                  {errors[_.findIndex(errors, { path: ["username"] })].message}
                </span>
              )}
          </FormGroup>
          {/* required */}
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              type="password"
              id="password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
            />
            {errors.length > 0 &&
              _.findIndex(errors, { path: ["password"] }) > -1 && (
                <span className="text-danger">
                  {errors[_.findIndex(errors, { path: ["password"] })].message}
                </span>
              )}
          </FormGroup>
          {/* required */}
          <FormGroup>
            <Label for="confirm_password">Confirm password</Label>
            <Input
              type="password"
              id="confirm_password"
              value={newUser.password_confirmation}
              onChange={(e) =>
                setNewUser({
                  ...newUser,
                  password_confirmation: e.target.value,
                })
              }
            />
            {errors.length > 0 &&
              _.findIndex(errors, { path: ["password_confirmation"] }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, { path: ["password_confirmation"] })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
          {/* required */}
          <FormGroup>
            <Label for="role">Role</Label>
            <Input
              type="select"
              id="role"
              className="form-control"
              value={newUser.role}
              onChange={(e) => {
                setNewUser({
                  ...newUser,
                  role: e.target.value,
                  name: e.target.value,
                });
              }}
            >
              <option value="admin">Admin</option>
              <option value="staff">Staff</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="role">Status</Label>
            <Input
              type="select"
              id="role"
              className="form-control"
              value={newUser.status}
              onChange={(e) => {
                setNewUser({ ...newUser, status: e.target.value });
              }}
            >
              <option value="active">active</option>
              <option value="inactive">inactive</option>
            </Input>
          </FormGroup>
          {(props.profile.role === "super_admin" ||
            props.profile.role === "admin") && (
            <FormGroup>
              <Label for="role">Team ID</Label>
              <Input
                type="number"
                className="form-control"
                value={newUser.team_id}
                onChange={(e) => {
                  setNewUser({
                    ...newUser,
                    team_id: parseInt(e.target.value, 10),
                  });
                }}
              />
            </FormGroup>
          )}
          {/* required if role is staff */}
          {newUser.role === "staff" && (
            <FormGroup>
              <Label for="name">Under which admin</Label>
              <Input
                type="select"
                value={newUser.parent_id}
                onChange={(e) => {
                  setNewUser({ ...newUser, parent_id: e.target.value });
                }}
              >
                <option></option>
                {props.admins.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {`${item.username}`}
                    </option>
                  );
                })}
              </Input>
              {errors.length > 0 &&
                _.findIndex(errors, { path: ["parent_id"] }) > -1 && (
                  <span className="text-danger">
                    {
                      errors[_.findIndex(errors, { path: ["parent_id"] })]
                        .message
                    }
                  </span>
                )}
            </FormGroup>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="mr-2"
          onClick={() => {
            let tmpErr = formSchema.safeParse(newUser);
            if (!tmpErr.success) {
              console.dir(tmpErr);
              setErrors(tmpErr.error.issues);
            } else {
              // call api
              props.postUser(newUser);
            }
          }}
          color="primary"
        >
          Submit
        </Button>
        <Button color="secondary" onClick={props.toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateUserModal;
