import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Input,
  CardFooter,
  Button,
  Label,
  FormGroup,
  Table,
  Form,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import Moment from "moment";

import LoadingIndicator from "components/Indicator/LoadingOverlay";

import WithStats from "./actions";

const Stats = (props) => {
  const [search, updateSearch] = React.useState({
    start_date: Moment().format("YYYY-MM-DD"),
    end_date: Moment().format("YYYY-MM-DD"),
  });
  let history = useHistory();

  React.useEffect(() => {
    props.getDashboardStats(search);
  }, []);

  return (
    <Row>
      <Col lg={12}>
        <Card className="main-card mb-3">
          <CardBody>
            <Form>
              <FormGroup>
                <Label>Start date</Label>
                <Input
                  type="date"
                  value={search.start_date}
                  onChange={(e) => {
                    updateSearch({
                      ...search,
                      start_date: e.target.value,
                    });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>End date</Label>
                <Input
                  type="date"
                  value={search.end_date}
                  onChange={(e) => {
                    updateSearch({
                      ...search,
                      end_date: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="mr-1"
              onClick={() => {
                props.getDashboardStats(search);
              }}
            >
              Search
            </Button>
          </CardFooter>
        </Card>
        <Card className="main-card mb-2">
          <CardBody>
            <Row>
              <Col md={4}>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push("/dashboard/deposits");
                  }}
                  className="w-100"
                >
                  Go to deposit
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  color="success"
                  onClick={() => {
                    history.push("/dashboard/withdrawals");
                  }}
                  className="w-100"
                >
                  Go to withdrawal
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  color="warning"
                  onClick={() => {
                    history.push("/dashboard/transfers");
                  }}
                  className="w-100"
                >
                  Go to transfer
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Row>
          <Col lg={6}>
            <Card className="main-card mb-2">
              <CardHeader>Total Sales</CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <td>Item</td>
                    <td>Amount</td>
                  </thead>
                  <tbody>
                    {Object.keys(props.stats.total_sales).map((key) => {
                      return (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>{props.stats.total_sales[key]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="main-card mb-2" style={{ height: "301.91px" }}>
              <CardHeader>Pending Withdrawal</CardHeader>
              <CardBody
                className="d-flex align-items-center justify-content-center"
                style={{ flexDirection: "column" }}
              >
                <h1>{props.stats.pending_withdrawal.count}</h1>
                <br />
                <Button
                  color="primary"
                  onClick={() => {
                    history.push("/dashboard/withdrawals");
                  }}
                >
                  <h4>Go to check</h4>
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="main-card mb-2" style={{ height: "415.25px" }}>
              <CardHeader>Bank Balance</CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <td>Name</td>
                    <td>Amount</td>
                  </thead>
                  <tbody>
                    {props.stats.bank_balance.map((item) => {
                      return (
                        <tr key={item.bank_name}>
                          <td>{item.bank_name}</td>
                          <td>{item.balance}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="main-card">
              <CardHeader>Kiosk Balance</CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <td>Platform</td>
                    <td>Amount</td>
                  </thead>
                  <tbody>
                    {props.stats.kiosk_balance.map((item) => {
                      return (
                        <tr key={item.game_platform_name}>
                          <td>{item.game_platform_name}</td>
                          <td>{item.balance}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
      {props.onLoadStats && <LoadingIndicator />}
    </Row>
  );
};

export default WithStats(Stats);
