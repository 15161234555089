import React from "react";
import PageTitle from "components/Title";
import { Row, Col, Card, CardHeader, CardBody, Button } from "reactstrap";
import _ from "lodash";
import ReactTable from "react-table";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import PromotionForm from "./Form";
import UpdatePromotionModal from "./Update";

import WithPromotions from "./actions";

class PromotionsContainer extends React.Component {
  state = {
    newPromotion: {
      name: "",
      claimable_percent: 0,
      claimable_quantity: 0,
      max_capacity: 0,
      status: "active",
      is_flexible_amount: false,
    },
    errors: [],
    showUpdatePromotionModal: false,
    showCreatePromotion: false,
  };

  componentDidMount = () => {
    this.props.getPromotions();
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Promotions"
          subheading="All promotions in the system."
          icon="pe-7s-config icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        {/* create promotion from here */}
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardHeader
                onClick={() =>
                  this.setState({
                    showCreatePromotion: !this.state.showCreatePromotion,
                  })
                }
              >
                Create new promotion
              </CardHeader>
              {this.state.showCreatePromotion && (
                <PromotionForm
                  data={this.state.newPromotion}
                  onSubmit={(tmp) => {
                    this.props.postPromotion(tmp);
                  }}
                />
              )}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={10}
                  data={this.props.promotions}
                  columns={[
                    {
                      Header: "Name",
                      accessor: "name",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Percent",
                      accessor: "claimable_percent",
                    },
                    {
                      Header: "Amount(RM)",
                      accessor: "claimable_amount",
                    },
                    {
                      Header: "Claimable quantity",
                      accessor: "claimable_quantity",
                    },
                    {
                      Header: "Claimable quantity per player account",
                      accessor: "claimable_quantity_per_player_account",
                    },
                    {
                      Header: "Claimed quantity",
                      accessor: "claimed_quantity",
                    },
                    {
                      Header: "Max cap(RM)",
                      accessor: "max_capacity",
                    },
                    {
                      Header: "Is flexible?",
                      Cell: (row) => {
                        return (
                          <span>
                            {row.original.is_flexible_amount ? "Yes" : "No"}
                          </span>
                        );
                      },
                    },
                    {
                      Header: "Action",
                      Cell: (row) => (
                        <Button
                          color="primary"
                          className="btn-icon btn-icon-only"
                          onClick={() => {
                            this.props.oCPromoHOC("promotion", row.original);
                            this.setState({
                              showUpdatePromotionModal: true,
                            });
                          }}
                        >
                          <i className="pe-7s-pen btn-icon-wrapper" />
                        </Button>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <UpdatePromotionModal
          isOpen={this.state.showUpdatePromotionModal}
          toggle={() => this.setState({ showUpdatePromotionModal: false })}
          promotion={this.props.promotion}
          updatePromotion={this.props.updatePromotion}
          onLoadPromotions={this.props.onLoadPromotions}
        />
        {this.props.onLoadPromotions && <LoadingOverlay />}
      </>
    );
  };
}

export default WithPromotions(PromotionsContainer);
