import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

// pre: props.selectedPlayer
const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      playerBankAccounts: [],
      playerBankAccountsSlug: [],
      newPlayerBankAccount: {
        player_id: 0,
        bank_id: 0,
        holder_name: "",
        account_number: "",
      },
      selectedPlayerBankAccount: {
        player_id: 0,
        bank_id: 0,
        holder_name: "",
        account_number: "",
      },
      toDeletePlayerBankAccountId: 0,
      showCreatePlayerBankAccount: false,
      showUpdatePlayerBankAccount: false,
      showConfirmDeletePlayerBankAccount: false,
    };

    oCPlayerBankAccountsHOC = (key, value) => this.setState({ [key]: value });

    getPlayerBankAccounts = async (player_id) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/player_bank_accounts?q[player_id_eq]=${player_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ playerBankAccounts: response.data });
      } catch (error) {
        toast.error("Error fetching player bank accounts");
      } finally {
        this.setState({ loading: false });
      }
    };

    getPlayerBankAccountsViaSlug = async (player_id) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/player_bank_accounts?q[player_slug_eq]=${player_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ playerBankAccountsSlug: response.data });
      } catch (error) {
        toast.error("Error fetching player bank accounts");
      } finally {
        this.setState({ loading: false });
      }
    };

    getSelectedPlayerBankAccount = async (data) => {
      this.setState({
        selectedPlayerBankAccount: data,
        showUpdatePlayerBankAccount: true,
      });
    };

    createPlayerBankAccount = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/player_bank_accounts`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ showCreatePlayerBankAccount: false });
        this.getPlayerBankAccounts(this.props.selectedPlayer.id);
      } catch (error) {
        toast.error("Error posting player account");
      } finally {
        this.setState({ loading: false });
      }
    };

    updatePlayerBankAccount = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/player_bank_accounts/${data.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          showUpdatePlayerBankAccount: false,
          selectedPlayerBankAccount: {
            player_id: 0,
            bank_id: 0,
            holder_name: "",
            account_number: "",
          },
        });
        this.getPlayerBankAccounts(this.props.selectedPlayer.id);
      } catch (error) {
        toast.error("Error updating player account");
      } finally {
        this.setState({ loading: false });
      }
    };

    deletePlayerBankAccount = async (id) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.delete(
          `${STAGING_BACKEND_URL}/player_bank_accounts/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          showConfirmDeletePlayerBankAccount: false,
          toDeletePlayerBankAccountId: 0,
        });
        this.getPlayerBankAccounts(this.props.selectedPlayer.id);
      } catch (error) {
        toast.error("Error updating player account");
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          getPlayerBankAccounts={this.getPlayerBankAccounts}
          getSelectedPlayerBankAccount={this.getSelectedPlayerBankAccount}
          createPlayerBankAccount={this.createPlayerBankAccount}
          updatePlayerBankAccount={this.updatePlayerBankAccount}
          deletePlayerBankAccount={this.deletePlayerBankAccount}
          oCPlayerBankAccountsHOC={this.oCPlayerBankAccountsHOC}
          getPlayerBankAccountsViaSlug={this.getPlayerBankAccountsViaSlug}
          playerBankAccountsSlug={this.state.playerBankAccountsSlug}
          showCreatePlayerBankAccount={this.state.showCreatePlayerBankAccount}
          showUpdatePlayerBankAccount={this.state.showUpdatePlayerBankAccount}
          showConfirmDeletePlayerBankAccount={
            this.state.showConfirmDeletePlayerBankAccount
          }
          toDeletePlayerBankAccountId={this.state.toDeletePlayerBankAccountId}
          newPlayerBankAccount={this.state.newPlayerBankAccount}
          playerBankAccounts={this.state.playerBankAccounts}
          selectedPlayerBankAccount={this.state.selectedPlayerBankAccount}
          onLoadPlayerBankAccounts={this.state.loading}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
