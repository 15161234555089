import React, { Component } from "react"
import { connect, useSelector } from "react-redux"
import { compose } from "redux"
import { Switch, Route, withRouter, Redirect } from "react-router-dom"

import Containers from "./containers"


function CustomRouter({ location, ...props }) {
  return (
    <Switch>
      <Route path="/" component={Containers} />
    </Switch>
  )
}

const mapStateToProps = (state) => ({ data: state })
export default compose(connect(mapStateToProps), withRouter)(CustomRouter)
