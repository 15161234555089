import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      banks: [],
      bankDetails: [],
      bank: {},
      showUpdate: false,
    };

    oCBanksHOC = (key, value) => this.setState({ [key]: value });

    getBanks = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(`${STAGING_BACKEND_URL}/banks`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({ banks: response.data.data, loading: false });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getBankDetails = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/bank_account_details`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ bankDetails: response.data.data, loading: false });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    postBank = async (bank) => {
      this.setState({ loading: true });
      try {
        await Axios.post(`${STAGING_BACKEND_URL}/banks`, bank, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.getBanks();
        toast.success("Bank is created successfully");
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    postBankDetails = async (bank) => {
      this.setState({ loading: true });
      try {
        await Axios.post(`${STAGING_BACKEND_URL}/bank_account_details`, bank, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.getBankDetails();
        toast.success("Bank details is created successfully");
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    putBank = async (bank) => {
      this.setState({ loading: true });
      try {
        await Axios.put(
          `${STAGING_BACKEND_URL}/bank_account_details/${bank.id}`,
          {
            bank_charge: bank.bank_charge,
            incentive: bank.incentive,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.getBanks();
        this.getBankDetails();
        toast.success("Bank is updated successfully");
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadBanks={this.state.loading}
          banks={this.state.banks}
          bank={this.state.bank}
          bankDetails={this.state.bankDetails}
          oCBanksHOC={this.oCBanksHOC}
          getBanks={this.getBanks}
          getBankDetails={this.getBankDetails}
          postBank={this.postBank}
          postBankDetails={this.postBankDetails}
          putBank={this.putBank}
          showUpdate={this.state.showUpdate}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
