import React, { Component } from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      adjustments: {
        data: [],
        meta: {},
      },
      showUploadExcel: false,
    };

    oCAdjustments = (key, val) => this.setState({ [key]: val });

    getAdjustments = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(`${STAGING_BACKEND_URL}/adjustments`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({ adjustments: response.data });
      } catch (error) {
        toast.error("Error fetching adjustments data.");
      } finally {
        this.setState({ loading: false });
      }
    };

    uploadAdjustmentExcel = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/adjustments`,
          {
            document: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState(
          {
            showUploadExcel: false,
          },
          () => {
            toast.success("Excel is uploaded successfully.");
            this.getAdjustments();
          }
        );
      } catch (error) {
        toast.error("Error uploading adjustments data.");
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadAdjustments={this.state.loading}
          adjustments={this.state.adjustments}
          showUploadExcel={this.state.showUploadExcel}
          getAdjustments={this.getAdjustments}
          oCAdjustments={this.oCAdjustments}
          uploadAdjustmentExcel={this.uploadAdjustmentExcel}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
