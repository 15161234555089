import React from "react";
import { Button, Input } from "reactstrap";
import { z } from "zod";
import _ from "lodash";

const formSchema = z.object({
  amount: z.number().min(1, "Amount is required"),
});

const InputTopup = (props) => {
  const [amt, setAmt] = React.useState(0);
  const [isEditing, setIsEditing] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  return (
    <div>
      <div className="d-flex">
        <Input
          type="number"
          value={amt}
          onChange={(e) => {
            setAmt(e.target.value);
            setIsEditing(true);
          }}
          min="0"
          style={{ width: "125px" }}
        />
        {props.onLoadTransact.indexOf(props.data.id) > -1 ? (
          <p className="mb-0">Loading.....</p>
        ) : (
          <Button
            color="primary"
            className="btn-icon btn-icon-only"
            onClick={() => {
              let tmp = {
                amount: parseInt(amt, 10),
              };
              let tmpErr = formSchema.safeParse(tmp);
              if (!tmpErr.success) {
                console.dir(tmpErr);
                setErrors(tmpErr.error.issues);
              } else {
                // call api
                props.putTransact({
                  id: props.data.id,
                  action_type: props.type === "in" ? "cash_in" : "cash_out",
                  amount: tmp.amount,
                });
                setIsEditing(false);
                setAmt(0);
              }
            }}
          >
            <i className="pe-7s-check btn-icon-wrapper" />
          </Button>
        )}
      </div>
      {errors.length > 0 && _.findIndex(errors, { path: ["amount"] }) > -1 && (
        <span className="text-danger">
          {
            errors[
              _.findIndex(errors, {
                path: ["amount"],
              })
            ].message
          }
        </span>
      )}
      {isEditing && <p className="text-danger mb-0">Unsaved changes!</p>}
    </div>
  );
};

export default InputTopup;
