import React, { Component } from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showNewWithdrawal: false,
      withdrawals: [],
      successWithdrawals: {
        data: [],
        meta: {
          page: 1,
        },
      },
      loadingCreateWithdrawal: false,
    };

    oCWithdrawHOC = (key, value) => this.setState({ [key]: value });

    getWithdrawals = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/withdrawals?q[status_eq]=pending`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ withdrawals: response.data.data });
      } catch (error) {
        toast.error("Error fetching withdrawals data.");
      } finally {
        this.setState({ loading: false });
      }
    };

    getSuccessWithdrawals = async (search) => {
      this.setState({ loading: true });
      let tmp = "";
      if (search) {
        Object.keys(search).map((key) => {
          if (
            search[key] &&
            search[key] !== "" &&
            typeof search[key] !== "number"
          ) {
            if (tmp.length === 0) {
              tmp += `${key}=${search[key]}`;
            } else {
              tmp += `&${key}=${search[key]}`;
            }
          }
          if (typeof search[key] === "number") {
            tmp += `&${key}=${search[key]}`;
          }
        });
      }
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/withdrawals?paginate=true&q[status_in][]=3&q[status_in][]=4&q[status_in][]=2${tmp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ successWithdrawals: response.data });
      } catch (error) {
        toast.error("Error fetching withdrawals data.");
      } finally {
        this.setState({ loading: false });
      }
    };

    postWithdrawal = async (data) => {
      this.setState({ loadingCreateWithdrawal: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/kiosks/withdraw`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ showNewWithdrawal: false });
        toast.success("New withdrawal created successfully");
        this.getWithdrawals();
      } catch (error) {
        this.getWithdrawals();
        this.getSuccessWithdrawals();
        toast.error(error.response.data.errors[0]);
      } finally {
        this.setState({ loadingCreateWithdrawal: false });
      }
    };

    approveWithdrawal = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/kiosks/withdrawals/${data.id}/approve`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.getWithdrawals();
        this.getSuccessWithdrawals();
        toast.success("New withdrawal is updated successfully");
      } catch (error) {
        this.getWithdrawals();
        this.getSuccessWithdrawals();
        toast.error(error.response.data.errors[0]);
      } finally {
        this.setState({ loading: false });
      }
    };

    rejectWithdrawal = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/kiosks/withdrawals/${data.id}/reject`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.getWithdrawals();
        this.getSuccessWithdrawals();
        toast.success("New withdrawal is updated successfully");
      } catch (error) {
        this.getWithdrawals();
        this.getSuccessWithdrawals();
        toast.error(error.response.data.errors[0]);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          showNewWithdrawal={this.state.showNewWithdrawal}
          onLoadWithdrawals={this.state.loading}
          withdrawals={this.state.withdrawals}
          successWithdrawals={this.state.successWithdrawals}
          loadingCreateWithdrawal={this.state.loadingCreateWithdrawal}
          postWithdrawal={this.postWithdrawal}
          getWithdrawals={this.getWithdrawals}
          oCWithdrawHOC={this.oCWithdrawHOC}
          approveWithdrawal={this.approveWithdrawal}
          getSuccessWithdrawals={this.getSuccessWithdrawals}
          rejectWithdrawal={this.rejectWithdrawal}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
