import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

const UpdatePlayerAccountPasswordModal = (props) => {
  const [pwdObj, updatePwdObj] = React.useState({
    new_password: "",
    password_confirmation: "",
  });
  React.useEffect(() => {
    if (props.isOpen) {
      updatePwdObj({
        new_password: "",
        password_confirmation: "",
      });
    }
  }, [props.isOpen]);
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        Update Player Account Password
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="password">New password</Label>
            <Input
              value={pwdObj.new_password}
              type={"password"}
              onChange={(e) => {
                updatePwdObj({
                  ...pwdObj,
                  new_password: e.target.value,
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Confirm password</Label>
            <Input
              value={pwdObj.password_confirmation}
              type={"password"}
              onChange={(e) => {
                updatePwdObj({
                  ...pwdObj,
                  password_confirmation: e.target.value,
                });
              }}
            />
          </FormGroup>
        </Form>
        {props.onLoadPlayerAccounts && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.changePlayerAccountPassword(
              props.selectedPlayerAccount.id,
              pwdObj.new_password,
              pwdObj.password_confirmation,
              props.selectedPlayer
            );
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdatePlayerAccountPasswordModal;
