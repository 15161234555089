import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
  CardFooter,
  Table,
} from "reactstrap";
import _ from "lodash";
import Moment from "moment";
import { compose } from "redux";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import PageTitle from "components/Title";

import WithReports from "./actions";
import WithShifts from "../Shifts/actions/Shifts";
import WithBanks from "../Banks/actions";
import WithGames from "../GamePlatforms/actions";

const ACCOUNT_TYPES = [
  { value: "PlayerAccount" },
  { value: "GamePlatformUser" },
  { value: "BankUser" },
  // { value: "GamePlatformUserShift" },
  // { value: "BankUserShift" },
];

const ACTION_TYPES = [
  { value: 0, label: "setup" },
  { value: 1, label: "topup" },
  { value: 2, label: "deposit" },
  { value: 3, label: "withdrawal" },
  { value: 4, label: "cash_in" },
  { value: 5, label: "cash_out" },
  { value: 6, label: "manual_register" },
  { value: 7, label: "api_register" },
];

class AccountTransactionsContainer extends React.Component {
  state = {
    account_type: "",
    account_id: "",
    action_type: "",
    action_by: "",
    shift_id: 0,
  };

  componentDidMount = () => {
    this.props.getShifts();
    this.props.getBanks();
    this.props.getGamePlatforms();
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Account Transactions Reports"
          subheading="All account transactions in the system."
          icon="pe-7s-note2 icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <Form>
                  {/* <FormGroup>
                    <Label>Account type</Label>
                    <Input
                      type="select"
                      value={this.state.account_type}
                      onChange={(e) =>
                        this.setState({ account_type: e.target.value })
                      }
                    >
                      <option></option>
                      {ACCOUNT_TYPES.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.value}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup> */}
                  <FormGroup>
                    <Label>Player ID</Label>
                    <Input
                      type="number"
                      value={this.state.account_id}
                      onChange={(e) =>
                        this.setState({ account_id: e.target.value })
                      }
                      min="0"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Action type</Label>
                    <Input
                      type="select"
                      value={this.state.action_type}
                      onChange={(e) =>
                        this.setState({ action_type: e.target.value })
                      }
                    >
                      <option></option>
                      {ACTION_TYPES.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Action by</Label>
                    <Input
                      type="number"
                      value={this.state.action_by}
                      onChange={(e) =>
                        this.setState({ action_by: e.target.value })
                      }
                      min="0"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Shift ID</Label>
                    <Input
                      type="select"
                      value={this.state.shift_id}
                      onChange={(e) =>
                        this.setState({ shift_id: e.target.value })
                      }
                    >
                      <option></option>
                      {this.props.shifts.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.id}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  className="ml-auto"
                  onClick={() => {
                    this.props.getAccountTransactions(
                      this.state.account_type,
                      this.state.account_id,
                      this.state.action_type,
                      this.state.action_by,
                      this.state.shift_id
                    );
                  }}
                >
                  Search
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Card className="main-card mb-3">
              <CardHeader>Banks</CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.banks.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="main-card mb-3">
              <CardHeader>Games</CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.games.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Player ID</th>
                      <th>Staff ID</th>
                      <th>Action type</th>
                      <th>Before/After</th>
                      <th>Created at</th>
                      {/* <th>Payload</th> */}
                      <th>Shift</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.accountTxns.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>{item.player_id}</td>
                          <td>{item.action_user.name}</td>
                          <td>{item.action_type}</td>
                          <td>
                            <Table striped bordered>
                              <thead>
                                <tr>
                                  <th>Platform username</th>
                                  <th>Total bonus</th>
                                  <th>Total deposits</th>
                                  <th>Total withdrawal</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!_.isEmpty(item.before) && (
                                  <tr>
                                    <td>
                                      {item.before.game_platform_username}
                                    </td>
                                    <td>{item.before.total_bonus}</td>
                                    <td>{item.before.total_deposits}</td>
                                    <td>{item.before.total_withdrawal}</td>
                                  </tr>
                                )}
                                {!_.isEmpty(item.after) && (
                                  <tr>
                                    <td>{item.after.game_platform_username}</td>
                                    <td>{item.after.total_bonus}</td>
                                    <td>{item.after.total_deposits}</td>
                                    <td>{item.after.total_withdrawal}</td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </td>
                          <td>
                            {Moment(item.created_at).format("DD/MM/YYYY HH:mm")}
                          </td>
                          {/* <td> */}
                          {/* here different payload based on different action type */}
                          {/* {
                              !_.isEmpty(item.payload) && item.action_type === "setup" && (
                                <Table striped bordered>
                                  <thead>
                                    <tr>
                                      <th>Game Platform ID</th>
                                      <th>Starting amount</th>
                                      <th>Status</th>
                                      <th>User ID</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{item.payload.game_platform_id}</td>
                                      <td>{item.payload.starting_amount}</td>
                                      <td>{item.payload.status}</td>
                                      <td>{item.payload.user_id}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              )
                            }
                            {
                              !_.isEmpty(item.payload) && item.action_type === "topup" && (
                                <Table striped bordered>
                                  <thead>
                                    <tr>
                                      <th>id</th>
                                      <th>Action</th>
                                      <th>Amount</th>
                                      <th>Controller</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{item.payload.id}</td>
                                      <td>{item.payload.action}</td>
                                      <td>{item.payload.amount}</td>
                                      <td>{item.payload.controller}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              )
                            }
                            {
                              !_.isEmpty(item.payload) && item.action_type === "deposit" && (
                                <Table striped bordered>
                                  <thead>
                                    <tr>
                                      <th>Action</th>
                                      <th>Bank ID</th>
                                      <th>Bonus amount</th>
                                      <th>Controller</th>
                                      <th>Deposit amount</th>
                                      <th>Game platform ID</th>
                                      <th>Kiosk</th>
                                      <th>Player ID</th>
                                      <th>Promotion ID</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{item.payload.action}</td>
                                      <td>{item.payload.bank_id}</td>
                                      <td>{item.payload.bonus_amount}</td>
                                      <td>{item.payload.controller}</td>
                                      <td>{`RM${item.payload.deposit_amount}`}</td>
                                      <td>{item.payload.game_platform_id}</td>
                                      <td>
                                        {
                                          !_.isEmpty(item.payload.kiosk) && (
                                            <ul>
                                              <li>{`Bank ID: ${item.payload.kiosk.bank_id}`}</li>
                                              <li>{`Bonus amount: ${item.payload.kiosk.bonus_amount}`}</li>
                                              <li>{`Deposit amount: ${item.payload.kiosk.deposit_amount}`}</li>
                                              <li>{`Game platform ID: ${item.payload.kiosk.game_platform_id}`}</li>
                                              <li>{`Player ID: ${item.payload.kiosk.player_id}`}</li>
                                              <li>{`Promotion ID: ${item.payload.kiosk.promotion_id}`}</li>
                                            </ul>
                                          )
                                        }
                                      </td>
                                      <td>{item.payload.player_id}</td>
                                      <td>{item.payload.promotion_id}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              )
                            } */}
                          {/* {!_.isEmpty(item.payload) && (
                              <p style={{ width: "200px" }}>
                                {JSON.stringify(item.payload, undefined, 2)}
                              </p>
                            )} */}
                          {/* </td> */}
                          <td>
                            {!_.isEmpty(item.shift) && (
                              <Table striped bordered>
                                <thead>
                                  <tr>
                                    <th>Status</th>
                                    <th>Started at</th>
                                    <th>Closed at</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{item.shift.status}</td>
                                    <td>
                                      {Moment(item.shift.created_at).format(
                                        "DD/MM/YYYY HH:mm"
                                      )}
                                    </td>
                                    <td>
                                      {item.shift.closed_at
                                        ? Moment(item.shift.closed_at).format(
                                            "DD/MM/YYYY HH:mm"
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {(this.props.onLoadReports ||
          this.props.onLoadShifts ||
          this.props.onLoadBanks ||
          this.props.onLoadGames) && <LoadingOverlay />}
      </>
    );
  };
}

export default compose(
  WithReports,
  WithShifts,
  WithBanks,
  WithGames
)(AccountTransactionsContainer);
