import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      gamePlatformUsers: [],
      gamePlatformUser: {
        user_id: 0,
        game_platform_id: 0,
        starting_amount: 0,
        status: "active",
      },
      showCreateGamePlatformUserModal: false,
      showUpdateGamePlatformUserModal: false,
      showCreateWithdraw: false,
      newGamePlatformUser: {
        user_id: 0,
        game_platform_id: 0,
        starting_amount: 0,
        status: "active",
      },
      postGamePlatformErrors: [],
      gamePlatformsFilteredUserID: [],
    };

    oCGamePlatformUsersHOC = (key, value) => this.setState({ [key]: value });

    getGamePlatformUsers = async (query) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/game_platform_users${query ? query : ""}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ gamePlatformUsers: response.data.data });
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getGamePlatformsFilterUserID = async (userID) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/game_platform_users?q[user_id_eq]=${userID}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ gamePlatformsFilteredUserID: response.data.data });
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getGamePlatformUser = async (id, success) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/game_platform_users/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ gamePlatformUser: response.data });
        if (success) success();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    postGamePlatformUser = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/game_platform_users`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          newGamePlatformUser: {
            user_id: 0,
            game_platform_id: 0,
            starting_amount: 0,
            status: "active",
          },
        });
        this.getGamePlatformUsers();
        toast.success("Starting amount is successfully set for the staff.");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    patchGamePlatformUser = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.patch(
          `${STAGING_BACKEND_URL}/game_platform_users/${data.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        toast.success("Starting amount is successfully updated for the staff.");
        this.getGamePlatformUsers();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    createWithdraw = async (id, amount) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/game_platform_users/${id}/refund`,
          {
            amount,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ showCreateWithdraw: false });
        toast.success("Withdrawal is created successfully.");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          gamePlatformsFilteredUserID={this.state.gamePlatformsFilteredUserID}
          gamePlatformUser={this.state.gamePlatformUser}
          gamePlatformUsers={this.state.gamePlatformUsers}
          newGamePlatformUser={this.state.newGamePlatformUser}
          postGamePlatformErrors={this.state.postGamePlatformErrors}
          onLoadGamePlatformUsers={this.state.loading}
          showCreateGamePlatformUserModal={
            this.state.showCreateGamePlatformUserModal
          }
          showUpdateGamePlatformUserModal={
            this.state.showUpdateGamePlatformUserModal
          }
          showCreateWithdraw={this.state.showCreateWithdraw}
          getGamePlatformUsers={this.getGamePlatformUsers}
          getGamePlatformUser={this.getGamePlatformUser}
          postGamePlatformUser={this.postGamePlatformUser}
          patchGamePlatformUser={this.patchGamePlatformUser}
          oCGamePlatformUsersHOC={this.oCGamePlatformUsersHOC}
          getGamePlatformsFilterUserID={this.getGamePlatformsFilterUserID}
          createWithdraw={this.createWithdraw}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
