import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      bankUsers: [],
      bankUser: {
        bank_id: 0,
        user_id: 0,
        account_number: "",
        status: "active",
        remark: "",
      },
      showCreateBankUserModal: false,
      showUpdateBankUserModal: false,
      newBankUser: {
        bank_account_detail_id: 0,
        // bank_id: 0,
        user_id: 0,
        account_number: "",
        status: "active",
        remark: "",
        // starting_amount: 0.0,
      },
      postBankUserErrors: [],
      bankUsersFilteredUserID: [],
      bankAccountDetailsAll: [],
    };

    oCBankUsersHOC = (key, value) => this.setState({ [key]: value });

    getBankUsers = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(`${STAGING_BACKEND_URL}/bank_users`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        this.setState({ bankUsers: response.data.data });
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getAllBankDetails = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/bank_account_details/all`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ bankAccountDetailsAll: response.data.data });
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getBankUsersFilterUserID = async (userID) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/bank_users${
            userID ? `?q[user_id_eq]=${userID}` : ""
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ bankUsersFilteredUserID: response.data.data });
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getBankUser = async (id, success) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/bank_users/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ bankUser: response.data });
        if (success) success();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    postBankUser = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/bank_users`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          newBankUser: {
            bank_id: "",
            user_id: "",
            account_number: "",
            status: "active",
            remark: "",
            starting_amount: 0.0,
          },
        });
        this.getBankUsers();
        toast.success("Starting amount is successfully set for the staff.");
      } catch (error) {
        console.dir(error, "error");
        toast.error(error.response.data.errors[0]);
      } finally {
        this.setState({ loading: false });
      }
    };

    patchBankUser = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.patch(
          `${STAGING_BACKEND_URL}/bank_users/${data.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        toast.success("Starting amount is successfully updated for the staff.");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          bankUsersFilteredUserID={this.state.bankUsersFilteredUserID}
          bankUser={this.state.bankUser}
          bankUsers={this.state.bankUsers}
          newBankUser={this.state.newBankUser}
          postBankUserErrors={this.state.postBankUserErrors}
          onLoadBankUsers={this.state.loading}
          showCreateBankUserModal={this.state.showCreateBankUserModal}
          showUpdateBankUserModal={this.state.showUpdateBankUserModal}
          bankAccountDetailsAll={this.state.bankAccountDetailsAll}
          getBankUsers={this.getBankUsers}
          getBankUser={this.getBankUser}
          postBankUser={this.postBankUser}
          patchBankUser={this.patchBankUser}
          oCBankUsersHOC={this.oCBankUsersHOC}
          getBankUsersFilterUserID={this.getBankUsersFilterUserID}
          getAllBankDetails={this.getAllBankDetails}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
