import React from "react";
import PageTitle from "components/Title";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Table,
  Input,
  Badge,
  FormGroup,
  Label,
} from "reactstrap";
import _ from "lodash";
import ReactTable from "react-table";
import { compose } from "redux";
import Moment from "moment";

import LoadingIndicator from "../../../components/Indicator/LoadingOverlay";
import NewRow from "./NewRow";

import WithTransfers from "./actions";
import WithGamePlatforms from "../GamePlatforms/actions";
import WithGamePlatformUsers from "../GamePlatformUsers/actions";

class TransferContainer extends React.Component {
  state = {
    search: {
      "q[user_username_eq]": "",
      "q[from_player_account_player_slug_eq]": "",
      "q[from_player_account_game_platform_name_eq]": "",
      "q[from_player_account_game_platform_username_eq]": "",
      "q[to_player_account_game_platform_name_eq]": "",
      "q[to_player_account_game_platform_username_eq]": "",
      "q[status_eq]": null,
    },
  };

  componentDidMount = () => {
    this.props.getTransfers(this.state.search);
    this.props.getGamePlatforms();
    this.props.getGamePlatformUsers();
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Transfers"
          subheading="All transfers in the system."
          icon="pe-7s-way icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: "primary",
              content: "Add new transfer",
              onClick: () => {
                let tmp = _.cloneDeep(this.props.newTransfers);
                tmp.push({ ...this.props.newTransfer });
                return this.props.oCTransferHOC("newTransfers", tmp);
              },
            },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <Table>
                  <thead>
                    <td>Agent</td>
                    <td>Player ID</td>
                    <td>Product</td>
                    <td>Game ID</td>
                    <td>Transfer amount</td>
                    <td>Product</td>
                    <td>Game ID</td>
                    <td>Action</td>
                  </thead>
                  <tbody>
                    {this.props.newTransfers.map((item, index) => {
                      return (
                        <NewRow
                          key={`CreateTransfer-${index}`}
                          profile={this.props.profile}
                          data={item}
                          index={index}
                          removeRow={() => {
                            let tmp = _.cloneDeep(this.props.newTransfers);
                            tmp.splice(index, 1);
                            return this.props.oCTransferHOC(
                              "newTransfers",
                              tmp
                            );
                          }}
                          {...this.props}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Agent ID</Label>
                      <Input
                        type="text"
                        name="q[user_username_eq]"
                        value={this.state.search["q[user_username_eq]"]}
                        onChange={(e) => {
                          let tmp = _.cloneDeep(this.state.search);
                          tmp["q[user_username_eq]"] = e.target.value;
                          return this.setState({ search: tmp });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Player ID</Label>
                      <Input
                        type="text"
                        name="q[from_player_account_player_slug_eq]"
                        value={
                          this.state.search[
                            "q[from_player_account_player_slug_eq]"
                          ]
                        }
                        onChange={(e) => {
                          let tmp = _.cloneDeep(this.state.search);
                          tmp["q[from_player_account_player_slug_eq]"] =
                            e.target.value;
                          return this.setState({ search: tmp });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>From Product</Label>
                      <Input
                        type="select"
                        name="q[from_player_account_game_platform_name_eq]"
                        value={
                          this.state.search[
                            "q[from_player_account_game_platform_name_eq]"
                          ]
                        }
                        onChange={(e) => {
                          let tmp = _.cloneDeep(this.state.search);
                          tmp["q[from_player_account_game_platform_name_eq]"] =
                            e.target.value;
                          return this.setState({ search: tmp });
                        }}
                      >
                        <option></option>
                        {this.props.games.map((item) => {
                          return (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>From Game ID</Label>
                      <Input
                        type="text"
                        name="q[from_player_account_game_platform_username_eq]"
                        value={
                          this.state.search[
                            "q[from_player_account_game_platform_username_eq]"
                          ]
                        }
                        onChange={(e) => {
                          let tmp = _.cloneDeep(this.state.search);
                          tmp[
                            "q[from_player_account_game_platform_username_eq]"
                          ] = e.target.value;
                          return this.setState({ search: tmp });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>To Product</Label>
                      <Input
                        type="select"
                        name="q[to_player_account_game_platform_name_eq]"
                        value={
                          this.state.search[
                            "q[to_player_account_game_platform_name_eq]"
                          ]
                        }
                        onChange={(e) => {
                          let tmp = _.cloneDeep(this.state.search);
                          tmp["q[to_player_account_game_platform_name_eq]"] =
                            e.target.value;
                          return this.setState({ search: tmp });
                        }}
                      >
                        <option></option>
                        {this.props.games.map((item) => {
                          return (
                            <option key={item.id} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>To Game ID</Label>
                      <Input
                        type="text"
                        name="q[to_player_account_game_platform_username_eq]"
                        value={
                          this.state.search[
                            "q[to_player_account_game_platform_username_eq]"
                          ]
                        }
                        onChange={(e) => {
                          let tmp = _.cloneDeep(this.state.search);
                          tmp[
                            "q[to_player_account_game_platform_username_eq]"
                          ] = e.target.value;
                          return this.setState({ search: tmp });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Status</Label>
                      <div className="pl-4">
                        <div>
                          <Input
                            type="radio"
                            checked={this.state.search["q[status_eq]"] === null}
                            onClick={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[status_eq]"] = null;
                              return this.setState({ search: tmp });
                            }}
                          />{" "}
                          All
                        </div>
                        <div>
                          <Input
                            type="radio"
                            checked={this.state.search["q[status_eq]"] === 0}
                            onClick={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[status_eq]"] = 0;
                              return this.setState({ search: tmp });
                            }}
                          />{" "}
                          Pending
                        </div>
                        <div>
                          <Input
                            type="radio"
                            checked={this.state.search["q[status_eq]"] === 1}
                            onClick={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[status_eq]"] = 1;
                              return this.setState({ search: tmp });
                            }}
                          />{" "}
                          Success
                        </div>
                        <div>
                          <Input
                            type="radio"
                            checked={this.state.search["q[status_eq]"] === 2}
                            onClick={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[status_eq]"] = 2;
                              return this.setState({ search: tmp });
                            }}
                          />{" "}
                          Withdraw fail
                        </div>
                        <div>
                          <Input
                            type="radio"
                            checked={this.state.search["q[status_eq]"] === 3}
                            onClick={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[status_eq]"] = 3;
                              return this.setState({ search: tmp });
                            }}
                          />{" "}
                          Deposit fail
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  className="ml-auto"
                  onClick={() => {
                    this.props.getTransfers(this.state.search);
                  }}
                >
                  Search
                </Button>
                <Button
                  color="danger"
                  className="ml-2"
                  onClick={() => {
                    this.setState(
                      {
                        search: {
                          "q[user_username_eq]": "",
                          "q[from_player_account_player_slug_eq]": "",
                          "q[from_player_account_game_platform_name_eq]": "",
                          "q[from_player_account_game_platform_username_eq]":
                            "",
                          "q[to_player_account_game_platform_name_eq]": "",
                          "q[to_player_account_game_platform_username_eq]": "",
                          "q[status_eq]": null,
                        },
                      },
                      () => {
                        this.props.getTransfers(this.state.search);
                      }
                    );
                  }}
                >
                  Reset
                </Button>
              </CardFooter>
            </Card>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  data={this.props.transfers}
                  columns={[
                    {
                      Header: "Created at",
                      Cell: (row) => {
                        return Moment(row.original.created_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                      },
                    },
                    {
                      Header: "Agent",
                      accessor: "user.username",
                    },
                    {
                      Header: "Player ID",
                      accessor: "from_player_account.player.slug",
                    },
                    {
                      Header: "Product",
                      accessor: "from_player_account.game_platform.name",
                    },
                    {
                      Header: "Game ID",
                      accessor: "from_player_account.game_platform_username",
                    },
                    {
                      Header: "Transfer amount",
                      accessor: "amount",
                    },
                    {
                      Header: "Product",
                      accessor: "to_player_account.game_platform.name",
                    },
                    {
                      Header: "Game ID",
                      accessor: "to_player_account.game_platform_username",
                    },
                    {
                      Header: "Status",
                      Cell: (row) => {
                        return (
                          <>
                            {row.original.status === "pending" && (
                              <Badge color="primary">Pending</Badge>
                            )}
                            {row.original.status === "success" && (
                              <Badge color="success">Success</Badge>
                            )}
                            {row.original.status === "withdraw_fail" && (
                              <Badge color="danger">Withdraw fail</Badge>
                            )}
                            {row.original.status === "deposit_fail" && (
                              <Badge color="warning">Deposit fail</Badge>
                            )}
                          </>
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {(this.props.onLoadTransfers ||
          this.props.onLoadGames ||
          this.props.onLoadGamePlatformUsers) && <LoadingIndicator />}
      </>
    );
  };
}

export default compose(
  WithTransfers,
  WithGamePlatforms,
  WithGamePlatformUsers
)(TransferContainer);
