import React from "react";
import { Col, Row, Button, Label } from "reactstrap";
import Slider from "react-slick";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import { GrView, GrFormViewHide } from "react-icons/gr";

import bg1 from "../../assets/TemplateImages/originals/abstract.jpg";
import bg2 from "../../assets/TemplateImages/originals/abstract2.jpg";
import bg3 from "../../assets/TemplateImages/originals/abstract3.jpg";
import icon from "../../assets/images/no-pic.jpg";
import LoadingOverlay from "../../components/Indicator/LoadingOverlay";

import WithLogin from "./actions";

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true,
};

class Login extends React.Component {
  state = {
    userID: "",
    password: "",
    showPassword: false,
  };

  componentDidMount() {
    this.props.getTestCall();
  }

  // handleKeyPress(e) {
  //   if(e.which === 13) {
  //     this.props.login(this.state.userID, this.state.password)
  //   }
  // }

  render() {
    return (
      <div className="h-100">
        <Row className="h-100 no-gutters">
          <Col lg="4" className="d-none d-lg-block">
            <div className="slider-light">
              <Slider {...SliderSettings}>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg"
                    style={{ backgroundImage: "url(" + bg1 + ")" }}
                  />
                  <div className="slider-content"></div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg"
                    style={{ backgroundImage: "url(" + bg2 + ")" }}
                  />
                  <div className="slider-content"></div>
                </div>
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div
                    className="slide-img-bg"
                    style={{ backgroundImage: "url(" + bg3 + ")" }}
                  />
                  <div className="slider-content"></div>
                </div>
              </Slider>
            </div>
          </Col>
          <Col
            lg="8"
            md="12"
            className="h-100 d-flex bg-white justify-content-center align-items-center"
          >
            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
              <img
                src={icon}
                style={{ width: 150, marginLeft: -10, marginBottom: "15px" }}
              />
              <h4 className="mb-0">
                <div>Welcome back,</div>
                <span>Please sign in to your account.</span>
              </h4>
              <Row className="divider" />
              <AvForm>
                <Row form>
                  <Col md={6}>
                    <AvGroup>
                      <Label for="exampleEmail">Username</Label>
                      <AvInput
                        onChange={(e) => {
                          this.setState({ userID: e.target.value });
                        }}
                        type="text"
                        name="userID"
                        id="userID"
                        onKeyPress={this.handleKeyPress}
                        value={this.state.userID}
                        placeholder="Email here..."
                        required
                      />
                      {/* {
												data.ajaxReducer.ajaxErrorMessage !== '' && (
													<AvFeedback>{ processErrorMessage( data.ajaxReducer.ajaxErrorMessage, 'Email' ) }</AvFeedback>
												)
											} */}
                    </AvGroup>
                  </Col>
                  <Col md={6}>
                    <AvGroup>
                      <div className={"d-flex"}>
                        <Label for="examplePassword">Password</Label>
                        <div
                          style={{
                            width: 14,
                            height: 14,
                            marginLeft: 10,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            this.setState({
                              showPassword: !this.state.showPassword,
                            })
                          }
                        >
                          {this.state.showPassword ? (
                            <GrView style={{ width: "100%", height: "100%" }} />
                          ) : (
                            <GrFormViewHide
                              style={{ width: "100%", height: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                      <AvInput
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                        }}
                        type={this.state.showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        value={this.state.password}
                        onKeyPress={this.handleKeyPress}
                        placeholder="Password here..."
                        required
                      />
                      {/* {
												data.ajaxReducer.ajaxErrorMessage !== '' && (
													<AvFeedback>{ processErrorMessage( data.ajaxReducer.ajaxErrorMessage, 'Password' ) }</AvFeedback>
												)
											} */}
                    </AvGroup>
                  </Col>
                </Row>
                {/* { 
									this.props.showErrorMessage && (
										<p style={{ color: '#ff0000' }}>
											{ this.props.errorMessage + '  ' }
											<strong>Forgot Password ?</strong>
										</p>
								)} */}
                {this.props.loginErrors &&
                  this.props.loginErrors.length > 0 &&
                  this.props.loginErrors.map((error, index) => {
                    return (
                      <p key={error} style={{ color: "#ff0000" }}>
                        {error}
                      </p>
                    );
                  })}
                <Row className="divider" />
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <Button
                      color="link"
                      size="lg"
                      className={"pl-1"}
                      onClick={() => this.props.history.push("/signup")}
                    >
                      Not a member? Signup here.
                    </Button>
                  </div>
                  <div className="ml-auto">
                    <Button
                      color={
                        this.state.userID.length < 1 ||
                        this.state.password.length < 1
                          ? "secondary"
                          : "primary"
                      }
                      size="lg"
                      disabled={
                        this.state.userID.length < 1 ||
                        this.state.password.length < 1
                      }
                      onClick={() => {
                        this.props.login(
                          this.state.userID,
                          this.state.password
                        );
                      }}
                    >
                      Login to Dashboard
                    </Button>
                  </div>
                </div>
              </AvForm>
            </Col>
          </Col>
        </Row>
        {this.props.onLoadLogin && <LoadingOverlay />}
      </div>
    );
  }
}

export default WithLogin(Login);
