import React from "react";
import PageTitle from "components/Title";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  Label,
  Form,
  Input,
} from "reactstrap";
import _ from "lodash";
import ReactTable from "react-table";
import Moment from "moment";
import { compose } from "redux";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import ModalUpdatePlayer from "./Update";
import PromptModal from "../../../components/Indicator/Prompt";
import Pagination from "../../../components/Pagination";
import CreatePlayerForm from "./CreatePlayerForm";

import WithPlayers from "./actions";
import WithUsers from "../Users/actions";
import WithPlatforms from "../GamePlatforms/actions";
import WithPlayerAccounts from "./actions/PlayerAccounts";
import WithGamePlatformUsers from "../GamePlatformUsers/actions";

class PlayersContainer extends React.Component {
  state = {
    showUpdatePlayerModal: false,
    selectedPlayer: {},
    selectedUserId: "",
    search: {
      "q[slug_eq]": "",
      "q[parent_username_cont]": "",
      "q[status_eq]": null,
    },
    selectedGamePlatformId: "",
    newPlayer: {},
  };

  componentDidMount() {
    this.props.getPlayers(this.state.search);
    this.props.getGamePlatforms();
    if (this.props.profile.role !== "staff") {
      this.props.getGamePlatformUsers(
        `?is_main_page=false&q[is_selectable_eq]=true`
      );
      this.props.getUsersWithRoleStaffs();
    } else {
      this.props.getGamePlatformUsers();
    }
  }

  render = () => {
    let tmpButtons = [];
    // if (this.props.profile.role === "staff") {
    //   tmpButtons.push({
    //     color: "primary",
    //     content: "Add player",
    //     onClick: () => {
    //       // call api - no info needed backend generate new id
    //       if (this.props.profile.role === "staff") {
    //         this.props.postPlayer(this.props.profile.id);
    //       } else {
    //         toast.error("Only staff can create player.");
    //       }
    //     },
    //   });
    // }
    return (
      <>
        <PageTitle
          heading="Players"
          subheading="All players in the system."
          icon="pe-7s-users icon-gradient bg-happy-itmeo"
          buttons={tmpButtons}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardHeader>Create new player</CardHeader>
              <CardBody>
                <CreatePlayerForm {...this.props} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* create player form here */}
        {/* {(this.props.profile.role === "super_admin" ||
          this.props.profile.role === "admin") && (
          <Row>
            <Col lg={12}>
              <Card className="main-card mb-3">
                <CardHeader>Create new player</CardHeader>
                <CardBody>
                  <CreatePlayerForm {...this.props} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )} */}
        {/* {this.props.profile.role === "staff" && (
          <Row>
            <Col lg={12}>
              <Card className="main-card mb-3">
                <CardHeader>Create new player</CardHeader>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label>Select a game platform</Label>
                      <Input
                        type="select"
                        value={this.state.selectedGamePlatformId}
                        onChange={(e) =>
                          this.setState({
                            selectedGamePlatformId: e.target.value,
                          })
                        }
                      >
                        <option value={""}></option>
                        {this.props.gamePlatformUsers.map((item) => {
                          return (
                            <option key={item.id} value={item.game_platform_id}>
                              {item.game_platform.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    className="ml-auto"
                    color="primary"
                    onClick={() => {
                      if (this.state.selectedGamePlatformId === "") {
                        alert("Please select a game platform.");
                      } else {
                        this.props.createPlayer(
                          this.state.selectedGamePlatformId
                        );
                      }
                    }}
                  >
                    Submit
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        )} */}
        {/* players listings are here */}
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-1">
              <CardBody>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>ID</Label>
                      <Input
                        type="text"
                        name="q[slug_eq]"
                        value={this.state.search["q[slug_eq]"]}
                        onChange={(e) => {
                          let tmp = _.cloneDeep(this.state.search);
                          tmp["q[slug_eq]"] = e.target.value;
                          return this.setState({ search: tmp });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col md={4}>
                    <FormGroup>
                      <Label>Belongs to</Label>
                      <Input
                        type="text"
                        name="q[parent_username_cont]"
                        value={this.state.search["q[parent_username_cont]"]}
                        onChange={(e) => {
                          let tmp = _.cloneDeep(this.state.search);
                          tmp["q[parent_username_cont]"] = e.target.value;
                          return this.setState({ search: tmp });
                        }}
                      />
                    </FormGroup>
                  </Col> */}
                  <Col md={12}>
                    <FormGroup>
                      <Label>Status</Label>
                      <div className="pl-4 d-flex">
                        <FormGroup style={{ width: "60px" }}>
                          <Input
                            type="radio"
                            checked={this.state.search["q[status_eq]"] === null}
                            onClick={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[status_eq]"] = null;
                              return this.setState({ search: tmp });
                            }}
                          />{" "}
                          All
                        </FormGroup>
                        <FormGroup style={{ width: "100px" }}>
                          <Input
                            type="radio"
                            checked={this.state.search["q[status_eq]"] === 1}
                            onClick={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[status_eq]"] = 1;
                              return this.setState({ search: tmp });
                            }}
                          />{" "}
                          Active
                        </FormGroup>
                        <FormGroup style={{ width: "100px" }}>
                          <Input
                            type="radio"
                            checked={this.state.search["q[status_eq]"] === 2}
                            onClick={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[status_eq]"] = 2;
                              return this.setState({ search: tmp });
                            }}
                          />{" "}
                          Suspended
                        </FormGroup>
                        <FormGroup style={{ width: "100px" }}>
                          <Input
                            type="radio"
                            checked={this.state.search["q[status_eq]"] === 3}
                            onClick={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[status_eq]"] = 3;
                              return this.setState({ search: tmp });
                            }}
                          />{" "}
                          Delete
                        </FormGroup>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  className="ml-auto"
                  onClick={() => {
                    this.props.getPlayers(this.state.search);
                  }}
                >
                  Search
                </Button>
                <Button
                  color="danger"
                  className="ml-2"
                  onClick={() => {
                    this.setState(
                      {
                        search: {
                          "q[slug_eq]": "",
                          "q[parent_username_cont]": "",
                          "q[status_eq]": null,
                        },
                      },
                      () => {
                        this.props.getPlayers(this.state.search);
                      }
                    );
                  }}
                >
                  Reset
                </Button>
              </CardFooter>
            </Card>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={this.props.playersMeta.items}
                  data={this.props.players}
                  showPagination={false}
                  columns={[
                    {
                      Header: "ID",
                      accessor: "slug",
                    },
                    // {
                    //   Header: "Game platform",
                    //   accessor: "game_platform.name",
                    // },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Created at",
                      Cell: (row) => (
                        <span>
                          {Moment(row.original.created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </span>
                      ),
                    },
                    {
                      Header: "Action",
                      Cell: (row) => (
                        <>
                          <Button
                            color="primary"
                            className="btn-icon btn-icon-only mr-2"
                            onClick={() => {
                              this.setState({
                                showUpdatePlayerModal: true,
                                selectedPlayer: row.original,
                              });
                            }}
                          >
                            <i className="pe-7s-pen btn-icon-wrapper" />
                          </Button>
                          <Button
                            color="danger"
                            className="btn-icon btn-icon-only"
                            onClick={() => {
                              Promise.all([
                                this.props.oCPlayerHOC(
                                  "toRemovePlayerID",
                                  row.original.id
                                ),
                              ]).then(() => {
                                this.props.oCPlayerHOC(
                                  "showConfirmDeletePlayer",
                                  true
                                );
                              });
                            }}
                          >
                            <i className="pe-7s-trash btn-icon-wrapper" />
                          </Button>
                        </>
                      ),
                    },
                  ]}
                />
                <Pagination
                  pages={this.props.playersMeta.pages}
                  currentPage={this.props.playersMeta.page}
                  onPageChange={(pg) => {
                    this.props.getPlayers(this.state.search, pg);
                  }}
                />
                <PromptModal
                  showPromptModal={this.props.showConfirmDeletePlayer}
                  onClickYes={() => {
                    this.props.deletePlayer();
                  }}
                  onClickNo={() => {
                    this.props.oCPlayerHOC("showConfirmDeletePlayer", false);
                  }}
                  content={"Are you sure to remove the selected player?"}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalUpdatePlayer
          isOpen={this.state.showUpdatePlayerModal}
          selectedPlayer={this.state.selectedPlayer}
          toggle={() => this.setState({ showUpdatePlayerModal: false })}
          games={this.props.games}
          onLoadPlayerAccounts={this.props.onLoadPlayerAccounts}
          playerAccounts={this.props.playerAccounts}
          getPlayerAccounts={this.props.getPlayerAccounts}
          postPlayerAccount={this.props.postPlayerAccount}
          putPlayer={this.props.putPlayer}
          onLoadPlayers={this.props.onLoadPlayers}
          deletePlayerAccount={this.props.deletePlayerAccount}
          showPlayerAccountChangePassword={
            this.props.showPlayerAccountChangePassword
          }
          changePlayerAccountPassword={this.props.changePlayerAccountPassword}
          disablePlayerAccount={this.props.disablePlayerAccount}
          enablePlayerAccount={this.props.enablePlayerAccount}
          oCPlayerAccountsHOC={this.props.oCPlayerAccountsHOC}
          gamePlatformUsers={this.props.gamePlatformUsers}
        />
        {(this.props.onLoadPlayers ||
          this.props.onLoadUsers ||
          this.props.onLoadGames) && <LoadingOverlay />}
      </>
    );
  };
}

export default compose(
  WithPlayers,
  WithUsers,
  WithPlatforms,
  WithPlayerAccounts,
  WithGamePlatformUsers
)(PlayersContainer);
