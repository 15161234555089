import React from "react";
import Axios from "axios";
import { connect } from "react-redux";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      old_password: "",
      new_password: "",
      user_id: 0,
      showChangePassword: false,
    };

    oCPasswordHOC = (key, value) => this.setState({ [key]: value });

    putPassword = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/users/${data.user_id}/change_password`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        toast.success("Password update successfully.");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
          putPassword={this.putPassword}
          onLoadChangePassword={this.state.loading}
          oCPasswordHOC={this.oCPasswordHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
