import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import _ from "lodash";
import { z } from "zod";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

const formSchema = z.object({
  amount: z.number().min(0.0, "Amount is required"),
});

const ModalTopup = (props) => {
  const [amt, setAmt] = React.useState(0);
  const [errors, updateErrors] = React.useState([]);
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"md"}>
      <ModalHeader toggle={props.toggle}>Topup credit</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Amount</Label>
            <Input
              type="number"
              value={amt}
              onChange={(e) => {
                setAmt(e.target.value);
              }}
              min="0"
            />
            {errors.length > 0 &&
              _.findIndex(errors, {
                path: ["amount"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, {
                        path: ["amount"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
        </Form>
        {props.onLoadTopupGamePlatform && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            let tmpErr = formSchema.safeParse({ amount: parseFloat(amt) });
            if (!tmpErr.success) {
              updateErrors(tmpErr.error.issues);
            } else {
              // call api
              props.topupGamePlatform({
                gamePlatformUser: {
                  id: props.gamePlatformUser.id,
                  amount: amt,
                },
              });
            }
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalTopup;
