import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardFooter,
} from "reactstrap";
import { z } from "zod";
import _ from "lodash";
import ReactTable from "react-table";
import Moment from "moment";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

import WithBankUsers from "../BankUsers/actions";

const formSchema = z.object({
  username: z.string().min(1, "Username is required"),
  role: z.string().min(1, "Role is required"),
  status: z.enum(["active", "inactive"]),
  team_id: z.number().nullable(),
});

const UpdateUserModal = (props) => {
  const [selectedUser, setSelectedUser] = React.useState({
    name: "admin",
    role: "admin",
    status: "active",
    username: "",
    team_id: 0,
    parent: {
      id: 0,
    },
    password: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = React.useState([]);

  React.useEffect(() => {
    if (props.isOpen) {
      props.getUsersWithRoleAdmins();
      setSelectedUser(props.user);
      props.getBankUsersFilterUserID(props.user.id);
    }
  }, [props.isOpen]);

  console.log(selectedUser, "selectedUser");

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"lg"}>
      <ModalHeader toggle={props.toggle}>Update selected user</ModalHeader>
      <ModalBody>
        <Card className="mb-2">
          <CardHeader>Basic user info</CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Username</Label>
                    <Input
                      type="text"
                      value={selectedUser.username}
                      disabled={true}
                    />
                  </FormGroup>
                  {/* required */}
                  <FormGroup>
                    <Label for="role">Role</Label>
                    <Input
                      type="select"
                      id="role"
                      className="form-control"
                      value={selectedUser.role}
                      onChange={(e) => {
                        console.log(selectedUser, "selectedUser");
                        setSelectedUser({
                          ...selectedUser,
                          role: e.target.value,
                          name: e.target.value,
                        });
                      }}
                    >
                      <option value="admin">Admin</option>
                      <option value="staff">Staff</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input
                      type="text"
                      className="form-control"
                      value={selectedUser.password}
                      onChange={(e) => {
                        setSelectedUser({
                          ...selectedUser,
                          password: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Confirm password</Label>
                    <Input
                      type="text"
                      className="form-control"
                      value={selectedUser.password_confirmation}
                      onChange={(e) => {
                        setSelectedUser({
                          ...selectedUser,
                          password_confirmation: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="role">Status</Label>
                    <Input
                      type="select"
                      id="role"
                      className="form-control"
                      value={selectedUser.status}
                      onChange={(e) => {
                        setSelectedUser({
                          ...selectedUser,
                          status: e.target.value,
                        });
                      }}
                    >
                      {/* <option value="active">active</option> */}
                      <option value="inactive">suspended</option>
                      <option value="deleted">deleted</option>
                      <option value="active">active</option>
                    </Input>
                  </FormGroup>
                  {(props.profile.role === "super_admin" ||
                    props.profile.role === "admin") && (
                    <FormGroup>
                      <Label for="role">Team ID</Label>
                      <Input
                        type="number"
                        className="form-control"
                        value={selectedUser.team_id}
                        onChange={(e) => {
                          setSelectedUser({
                            ...selectedUser,
                            team_id: parseInt(e.target.value, 10),
                          });
                        }}
                      />
                    </FormGroup>
                  )}
                  {/* required if role is staff */}
                  {selectedUser.role === "staff" &&
                    props.profile.role === "super_admin" && (
                      <FormGroup>
                        <Label>Under which admin</Label>
                        <Input
                          type="select"
                          value={selectedUser.parent?.id}
                          onChange={(e) => {
                            setSelectedUser({
                              ...selectedUser,
                              parent_id: e.target.value,
                            });
                          }}
                        >
                          <option></option>
                          {props.admins.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>
                                {item.username}
                              </option>
                            );
                          })}
                        </Input>
                        {errors.length > 0 &&
                          _.findIndex(errors, { path: ["parent_id"] }) > -1 && (
                            <span className="text-danger">
                              {
                                errors[
                                  _.findIndex(errors, { path: ["parent_id"] })
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup>
                    )}
                </Col>
              </Row>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              className="ml-auto mr-2"
              onClick={() => {
                let tmpErr = formSchema.safeParse(selectedUser);
                if (!tmpErr.success) {
                  console.dir(tmpErr);
                  setErrors(tmpErr.error.issues);
                } else {
                  // call api
                  props.patchUser(selectedUser);
                }
              }}
              color="primary"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>Assigned kiosk balance with bank accounts</CardHeader>
          <CardBody>
            <ReactTable
              data={props.bankUsersFilteredUserID}
              columns={[
                {
                  Header: "Bank name",
                  accessor: "bank.name",
                },
                {
                  Header: "Account number",
                  accessor: "account_number",
                },
                {
                  Header: "Starting amount",
                  accessor: "starting_amount",
                },
                {
                  Header: "Status",
                  accessor: "status",
                },
                {
                  Header: "Created at",
                  Cell: (row) => (
                    <span>
                      {Moment(row.original.created_at).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </span>
                  ),
                },
              ]}
            />
          </CardBody>
        </Card>
        {props.onLoadBankUsers && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WithBankUsers(UpdateUserModal);
