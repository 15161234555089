import React from "react";
import ReactTable from "react-table";
import Moment from "moment";
import { Button, Badge } from "reactstrap";

// reload this table by calling get deposits api every 5s
const TableTransactionResult = (props) => {
  const ColumnsComp = () => {
    let tmp = [
      {
        Header: "Agent",
        Cell: (row) => {
          return <div>{row.original.user.username}</div>;
        },
      },
      {
        Header: "Player ID",
        Cell: (row) => {
          return <div>{row.original.player_account.player.slug}</div>;
        },
      },
      {
        Header: "Game platform",
        Cell: (row) => {
          return (
            <div>{row.original.game_platform_user.game_platform.name}</div>
          );
        },
      },
      {
        Header: "Game ID",
        Cell: (row) => {
          return (
            <div>{row.original.player_account.game_platform_username}</div>
          );
        },
      },
      {
        Header: "Withdraw amount",
        Cell: (row) => {
          return <div>{parseInt(row.original.amount, 10)}</div>;
        },
      },
      {
        Header: "Player bank account holder",
        Cell: (row) => {
          return (
            <div>
              {row.original.player_bank_account
                ? row.original.player_bank_account.holder_name
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Player bank",
        Cell: (row) => {
          return (
            <div>
              {row.original.player_bank_account
                ? row.original.player_bank_account.bank.name
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Player bank account number",
        Cell: (row) => {
          return (
            <div>
              {row.original.player_bank_account
                ? row.original.player_bank_account.account_number
                : ""}
            </div>
          );
        },
      },
      {
        Header: "Status",
        Cell: (row) => (
          <div>
            {row.original.status === "pending" && (
              <Badge color={"primary"}>{row.original.status}</Badge>
            )}
            {row.original.status === "success" && (
              <Badge color={"success"}>{row.original.status}</Badge>
            )}
            {row.original.status === "failed" && (
              <Badge color={"danger"}>{row.original.status}</Badge>
            )}
          </div>
        ),
      },
      {
        Header: "Created at",
        Cell: (row) => {
          return (
            <div>
              {Moment(row.original.created_at).format("DD/MM/YYYY HH:mm")}
            </div>
          );
        },
      },
      {
        Header: "Action",
        Cell: (row) => {
          return (
            <>
              <Button
                color="primary"
                onClick={() => {
                  props.approveWithdrawal(row.original);
                }}
              >
                Approve
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  props.rejectWithdrawal(row.original);
                }}
              >
                Reject
              </Button>
            </>
          );
        },
      },
    ];
    return tmp;
  };

  return (
    <ReactTable
      pageSize={10}
      data={props.withdrawals}
      columns={ColumnsComp()}
    />
  );
};

export default TableTransactionResult;
