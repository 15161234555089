import React, { Component } from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      shifts: [],
      search: {
        scope: "all",
      },
    };

    getShifts = async () => {
      this.setState({ loading: true });
      let tmpSearch = "";
      tmpSearch = tmpSearch + `scope=${this.state.search.scope}&`;
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/shifts?${tmpSearch}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ shifts: response.data.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    closeShift = async () => {
      this.setState({ loading: true });
      try {
        await Axios.post(
          `${STAGING_BACKEND_URL}/shifts/close`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        toast.success(`Shift is closed successfuilly!`);
        this.getShifts();
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          getShifts={this.getShifts}
          closeShift={this.closeShift}
          shifts={this.state.shifts}
          onLoadShifts={this.state.loading}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
