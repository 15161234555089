import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import PromotionForm from "./Form";
import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

const UpdatePromoModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"md"}>
      <ModalHeader toggle={props.toggle}>Update promotion</ModalHeader>
      <ModalBody>
        <PromotionForm
          data={props.promotion}
          onSubmit={(tmp) => {
            props.updatePromotion(tmp);
          }}
        />
        {props.onLoadPromotions && <LoadingOverlay />}
      </ModalBody>
    </Modal>
  );
};

export default UpdatePromoModal;
