import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      reports: {
        data: [],
        meta: {
          page: 1,
          pages: 1,
        },
      },
      selectedReport: {},
      showReportDetails: false,
      showCurrentReport: false,
      currentReport: {},
      showConfirmCloseReport: false,
    };

    oCReportHOC = (key, val) => this.setState({ [key]: val });

    getReports = async (search) => {
      this.setState({ loading: true });
      let tmp = "";
      if (search) {
        Object.keys(search).map((key) => {
          if (
            search[key] &&
            search[key] !== "" &&
            typeof search[key] !== "number"
          ) {
            if (tmp.length === 0) {
              tmp += `${key}=${search[key]}`;
            } else {
              tmp += `&${key}=${search[key]}`;
            }
          }
          if (typeof search[key] === "number") {
            tmp += `${key}=${search[key]}`;
          }
        });
      }
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/reports${tmp.length > 0 ? `?${tmp}` : ``}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ reports: response.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getSelectedReport = async (id) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/reports/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          selectedReport: response.data,
          showReportDetails: true,
        });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getCurrentReport = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/reports/current`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          currentReport: response.data,
          showCurrentReport: true,
        });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    closeCurrentReport = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/reports/close`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState(
          {
            showCurrentReport: false,
            showConfirmCloseReport: false,
          },
          () => {
            toast.success(response.data.result[0]);
            this.getReports({
              page: 1,
            });
          }
        );
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadClosingReports={this.state.loading}
          reports={this.state.reports}
          selectedReport={this.state.selectedReport}
          showReportDetails={this.state.showReportDetails}
          showCurrentReport={this.state.showCurrentReport}
          currentReport={this.state.currentReport}
          showConfirmCloseReport={this.state.showConfirmCloseReport}
          getReports={this.getReports}
          getSelectedReport={this.getSelectedReport}
          oCReportHOC={this.oCReportHOC}
          getCurrentReport={this.getCurrentReport}
          closeCurrentReport={this.closeCurrentReport}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
