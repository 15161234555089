import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

import WithBanks from "../Banks/actions";

const UpdatePlayerBankAccountModal = (props) => {
  React.useEffect(() => {
    if (props.isOpen) {
      props.getBanks();
    }
  }, [props.isOpen]);

  const onChangeData = (key, val) => {
    let tmp = _.cloneDeep(props.selectedPlayerBankAccount);
    tmp[key] = val;
    return props.oCPlayerBankAccountsHOC("selectedPlayerBankAccount", tmp);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        Update Player Bank Account
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Select bank</Label>
            <Input
              type="select"
              value={props.selectedPlayerBankAccount.bank_id}
              onChange={(e) => onChangeData("bank_id", e.target.value)}
            >
              <option></option>
              {props.banks.map((bank) => {
                return (
                  <option key={bank.id} value={bank.id}>
                    {bank.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Holder name</Label>
            <Input
              type="text"
              value={props.selectedPlayerBankAccount.holder_name}
              onChange={(e) => onChangeData("holder_name", e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Account number</Label>
            <Input
              type="text"
              value={props.selectedPlayerBankAccount.account_number}
              onChange={(e) => onChangeData("account_number", e.target.value)}
            />
          </FormGroup>
        </Form>
        {props.onLoadBanks && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            let tmp = {
              id: props.selectedPlayerBankAccount.id,
              player_id: props.selectedPlayerBankAccount.player_id,
              bank_id: props.selectedPlayerBankAccount.bank_id,
              holder_name: props.selectedPlayerBankAccount.holder_name,
              account_number: props.selectedPlayerBankAccount.account_number,
            };
            props.updatePlayerBankAccount(tmp);
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WithBanks(UpdatePlayerBankAccountModal);
