import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      profile: {
        username: '',
        role: '',
        name: '',
      }
    }

    getLoginUserProfile = async () => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(`${STAGING_BACKEND_URL}/profile`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          }
        });
        this.setState({
          profile: response.data,
        });
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          profile={this.state.profile}
          onLoadProfile={this.state.loading}
          getLoginUserProfile={this.getLoginUserProfile}
        />
      );
    }
  }
  return WithHOC;
}

export default HOC;