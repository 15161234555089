import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      showTopupModal: false,
    };

    oCGamePlatformTopupHOC = (key, value) => this.setState({ [key]: value });

    topupGamePlatform = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/game_platform_users/${data.gamePlatformUser.id}/topup`,
          { amount: data.gamePlatformUser.amount },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        toast.success("Topup successful.");
        this.setState({ showTopupModal: false }, () => {
          this.props.getGamePlatformUsers();
        });
      } catch (error) {
        this.setState({ showTopupModal: false });
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          oCGamePlatformTopupHOC={this.oCGamePlatformTopupHOC}
          topupGamePlatform={this.topupGamePlatform}
          showTopupModal={this.state.showTopupModal}
          onLoadTopupGamePlatform={this.state.loading}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
