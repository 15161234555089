import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
  CardFooter,
  Table,
} from "reactstrap";
import _ from "lodash";
import Moment from "moment";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import PageTitle from "components/Title";

import WithReports from "./actions";

class ReportsContainer extends React.Component {
  state = {
    start_date: Moment().format("DD/MM/YYYY"),
    end_date: Moment().format("DD/MM/YYYY"),
  };
  render = () => {
    return (
      <>
        <PageTitle
          heading="Reports"
          subheading="All reports in the system."
          icon="pe-7s-note2 icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label>Start date</Label>
                    <Input
                      type="date"
                      value={this.state.start_date}
                      onChange={(e) => {
                        this.setState({
                          start_date: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>End date</Label>
                    <Input
                      type="date"
                      value={this.state.end_date}
                      onChange={(e) => {
                        this.setState({
                          end_date: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  className="mr-1"
                  onClick={() => {
                    this.props.getAccountTransactionStats(
                      this.state.start_date,
                      this.state.end_date
                    );
                  }}
                >
                  Search
                </Button>
                <Button
                  color="success"
                  className="mr-1"
                  onClick={() => {
                    this.props.getAccountTransactionStats(
                      Moment().subtract(1, "days").format("YYYY-MM-DD"),
                      Moment().subtract(1, "days").format("YYYY-MM-DD")
                    );
                  }}
                >
                  Yesterday
                </Button>
                <Button
                  color="warning"
                  className="mr-1"
                  onClick={() => {
                    this.props.getAccountTransactionStats(
                      Moment()
                        .subtract(1, "months")
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                      Moment()
                        .subtract(1, "months")
                        .endOf("month")
                        .format("YYYY-MM-DD")
                    );
                  }}
                >
                  Last month
                </Button>
                <Button
                  color="danger"
                  className="mr-1"
                  onClick={() => {
                    this.props.getAccountTransactionStats(
                      Moment().startOf("month").format("YYYY-MM-DD"),
                      Moment().endOf("month").format("YYYY-MM-DD")
                    );
                  }}
                >
                  Current month
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        {this.props.stats.map((item, index) => {
          return (
            <Row key={`Stat-${index}`}>
              <Col lg={12}>
                <Card className="main-card mb-3">
                  <CardHeader>{`${item.action_by}`}</CardHeader>
                  <CardBody>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Deposit</th>
                          <th>Withdrawal</th>
                          <th>Player</th>
                          <th>Total Deposit</th>
                          <th>Total Withdrawal</th>
                          <th>Total Bonus</th>
                          <th>Total Win/Loss</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.stats.map((stat, index) => {
                          return (
                            <tr key={`Stats-content-${index}`}>
                              <td>{Moment(stat.date).format("DD/MM/YYYY")}</td>
                              <td>{stat.deposit_quantity}</td>
                              <td>{stat.withdrawal_quantity}</td>
                              <td>{stat.total_players}</td>
                              <td>{`RM${stat.total_deposit}`}</td>
                              <td>{`RM${stat.total_withdrawal}`}</td>
                              <td>{`RM${stat.total_bonus}`}</td>
                              <td>{stat.total_win_loss}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        })}
        {this.props.onLoadReports && <LoadingOverlay />}
      </>
    );
  };
}

export default WithReports(ReportsContainer);
