import React from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
  CardFooter,
  Table,
} from "reactstrap";
import _ from "lodash";
import Moment from "moment";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import PageTitle from "components/Title";
import Pagination from "components/Pagination";
import ModalDetails from "./Details";
import ModalCurrentReport from "./Current";

import WithReports from "./actions";

class ClosingReportContainer extends React.Component {
  state = {
    start_date: Moment().format("DD/MM/YYYY"),
    end_date: Moment().format("DD/MM/YYYY"),
    search: {
      page: 1,
    },
  };

  componentDidMount = () => {
    this.props.getReports(this.state.search);
  };

  render = () => {
    let tmp = [];
    if (this.props.profile.role === "staff") {
      tmp = [
        {
          color: "primary",
          content: "New",
          className: "mr-2",
          onClick: () => {
            this.props.getCurrentReport();
          },
        },
      ];
    }
    return (
      <>
        <PageTitle
          heading="Closing Reports"
          subheading="All reports in the system."
          icon="pe-7s-note2 icon-gradient bg-happy-itmeo"
          buttons={tmp}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.reports.data.map((item, index) => {
                      return (
                        <tr key={`Reports-content-${index}`}>
                          <td>{item.id}</td>
                          <td>{item.created_at}</td>
                          <td>
                            <Button
                              color="success"
                              className="btn-icon btn-icon-only"
                              onClick={() => {
                                this.props.getSelectedReport(item.id);
                              }}
                            >
                              <i className="pe-7s-look btn-icon-wrapper" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Pagination
                  pages={this.props.reports.meta.pages}
                  onPageChange={(pg) => {
                    let tmp = _.cloneDeep(this.state.search);
                    tmp["page"] = pg;
                    this.props.getReports(tmp);
                  }}
                  currentPage={this.state.search.page}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalDetails
          isOpen={this.props.showReportDetails}
          toggle={() => this.props.oCReportHOC("showReportDetails", false)}
          selectedReport={this.props.selectedReport}
        />
        <ModalCurrentReport
          {...this.props}
          isOpen={this.props.showCurrentReport}
          toggle={() => this.props.oCReportHOC("showCurrentReport", false)}
          currentReport={this.props.currentReport}
        />
        {this.props.onLoadClosingReports && <LoadingOverlay />}
      </>
    );
  };
}

export default WithReports(ClosingReportContainer);
