import React from "react";
import PageTitle from "components/Title";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import _ from "lodash";
import ReactTable from "react-table";
import { z } from "zod";
import { compose } from "redux";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import UpdateBankUserModal from "./Update";
import InputTopup from "./InputTopup";

import WithBankUsers from "./actions";
import WithBanks from "../Banks/actions";
import WithUsers from "../Users/actions";
import WithTransact from "./actions/Transact";

const formSchema = z.object({
  bank_account_detail_id: z.number().min(1, "Bank id is required"),
  user_id: z.number().min(1, "Staff id is required"),
  // account_number: z.string().min(1, "Account number is required"),
  status: z.enum(["active", "inactive"]),
  // starting_amount: z.number().min(0.0, "Starting amount is required"),
});

class BankUsersContainer extends React.Component {
  state = {
    bankUsersRowsErrors: [],
    showCreateKioskBalance: false,
  };

  componentDidMount = () => {
    this.props.getBankUsers();
    this.props.getBanks();
    this.props.getUsersWithRoleStaffs();
    this.props.getAllBankDetails();
  };

  onChangeNewBankUser = (key, val) => {
    let tmp = _.cloneDeep(this.props.newBankUser);
    tmp[key] = val;
    this.props.oCBankUsersHOC("newBankUser", tmp);
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Banks assigned to staffs"
          subheading="All kiosk balances - banks in the system."
          icon="pe-7s-config icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <Row className="mb-2">
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardHeader
                onClick={() =>
                  this.setState({
                    showCreateKioskBalance: !this.state.showCreateKioskBalance,
                  })
                }
              >
                Create new kiosk balance setup - Bank
              </CardHeader>
              {this.state.showCreateKioskBalance && (
                <>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label>Assign bank</Label>
                        <Input
                          type="select"
                          value={this.props.newBankUser.bank_account_detail_id}
                          onChange={(e) => {
                            this.onChangeNewBankUser(
                              "bank_account_detail_id",
                              parseInt(e.target.value, 10)
                            );
                          }}
                        >
                          <option></option>
                          {this.props.bankAccountDetailsAll.map((bank) => {
                            return (
                              <option key={`bank-${bank.id}`} value={bank.id}>
                                {`${bank.bank_name} ${bank.account_number}`}
                              </option>
                            );
                          })}
                        </Input>
                        {this.props.postBankUserErrors.length > 0 &&
                          _.findIndex(this.props.postBankUserErrors, {
                            path: ["bank_account_detail_id"],
                          }) > -1 && (
                            <span className="text-danger">
                              {
                                this.props.postBankUserErrors[
                                  _.findIndex(this.props.postBankUserErrors, {
                                    path: ["bank_account_detail_id"],
                                  })
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Staffs</Label>
                        <Input
                          type="select"
                          value={this.props.newBankUser.user_id}
                          onChange={(e) => {
                            this.onChangeNewBankUser(
                              "user_id",
                              parseInt(e.target.value, 10)
                            );
                          }}
                        >
                          <option></option>
                          {this.props.staffs.map((item) => {
                            return (
                              <option key={`staff-${item.id}`} value={item.id}>
                                {item.username}
                              </option>
                            );
                          })}
                        </Input>
                        {this.props.postBankUserErrors.length > 0 &&
                          _.findIndex(this.props.postBankUserErrors, {
                            path: ["user_id"],
                          }) > -1 && (
                            <span className="text-danger">
                              {
                                this.props.postBankUserErrors[
                                  _.findIndex(this.props.postBankUserErrors, {
                                    path: ["user_id"],
                                  })
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup>
                      {/* <FormGroup>
                        <Label>Account number</Label>
                        <Input
                          type="text"
                          value={this.props.newBankUser.account_number}
                          onChange={(e) => {
                            this.onChangeNewBankUser(
                              "account_number",
                              e.target.value
                            );
                          }}
                        />
                        {this.props.postBankUserErrors.length > 0 &&
                          _.findIndex(this.props.postBankUserErrors, {
                            path: ["account_number"],
                          }) > -1 && (
                            <span className="text-danger">
                              {
                                this.props.postBankUserErrors[
                                  _.findIndex(this.props.postBankUserErrors, {
                                    path: ["account_number"],
                                  })
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup> */}
                      <FormGroup>
                        <Label>Status</Label>
                        <Input
                          type="select"
                          value={this.props.newBankUser.status}
                          onChange={(e) => {
                            this.onChangeNewBankUser("status", e.target.value);
                          }}
                        >
                          <option value={"active"}>Active</option>
                          <option value={"inactive"}>Inactive</option>
                        </Input>
                        {this.props.postBankUserErrors.length > 0 &&
                          _.findIndex(this.props.postBankUserErrors, {
                            path: ["status"],
                          }) > -1 && (
                            <span className="text-danger">
                              {
                                this.props.postBankUserErrors[
                                  _.findIndex(this.props.postBankUserErrors, {
                                    path: ["status"],
                                  })
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Remark</Label>
                        <Input
                          type="text"
                          value={this.props.newBankUser.remark}
                          onChange={(e) => {
                            this.onChangeNewBankUser("remark", e.target.value);
                          }}
                        />
                      </FormGroup>
                      {/* <FormGroup>
                        <Label>Starting amount</Label>
                        <Input
                          type="number"
                          value={this.props.newBankUser.starting_amount}
                          onChange={(e) => {
                            this.onChangeNewBankUser(
                              "starting_amount",
                              e.target.value
                            );
                          }}
                          min="0"
                        />
                        {this.props.postBankUserErrors.length > 0 &&
                          _.findIndex(this.props.postBankUserErrors, {
                            path: ["starting_amount"],
                          }) > -1 && (
                            <span className="text-danger">
                              {
                                this.props.postBankUserErrors[
                                  _.findIndex(this.props.postBankUserErrors, {
                                    path: ["starting_amount"],
                                  })
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup> */}
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="ml-auto"
                      color="primary"
                      onClick={() => {
                        let tmp = {
                          ...this.props.newBankUser,
                          starting_amount: parseFloat(
                            this.props.newBankUser.starting_amount,
                            10
                          ),
                        };
                        let tmpErr = formSchema.safeParse(tmp);
                        if (!tmpErr.success) {
                          console.dir(tmpErr);
                          this.props.oCBankUsersHOC(
                            "postBankUserErrors",
                            tmpErr.error.issues
                          );
                        } else {
                          // call api
                          this.props.postBankUser({
                            bank_account_detail_id:
                              this.props.newBankUser.bank_account_detail_id,
                            user_id: this.props.newBankUser.user_id,
                            status: this.props.newBankUser.status,
                            remark: this.props.newBankUser.remark,
                          });
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </CardFooter>
                </>
              )}
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={10}
                  data={this.props.bankUsers}
                  columns={[
                    {
                      Header: "Staff username",
                      accessor: "user.username",
                    },
                    {
                      Header: "Bank",
                      Cell: (row) => {
                        return (
                          <span>{`${row.original.bank_account_detail?.bank?.name} - ${row.original.bank_account_detail?.name}`}</span>
                        );
                      },
                    },
                    {
                      Header: "Bank account number",
                      accessor: "bank_account_detail.account_number",
                    },
                    {
                      Header: "Cash in",
                      accessor: "cash_in",
                    },
                    {
                      Header: "Cash out",
                      accessor: "cash_out",
                    },
                    {
                      Header: "Total deposits",
                      accessor: "total_deposits",
                    },
                    {
                      Header: "Total withdrawals",
                      accessor: "total_withdrawal",
                    },
                    {
                      Header: "Total burns",
                      accessor: "total_burn",
                    },
                    {
                      Header: "Total tips",
                      accessor: "total_tips",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Action",
                      Cell: (row) => (
                        <>
                          <Button
                            color="primary"
                            className="btn-icon btn-icon-only"
                            onClick={() => {
                              this.props.getBankUser(row.original.id, () => {
                                this.props.oCBankUsersHOC(
                                  "showUpdateBankUserModal",
                                  true
                                );
                              });
                            }}
                          >
                            <i className="pe-7s-pen btn-icon-wrapper" />
                          </Button>
                        </>
                      ),
                    },
                    {
                      Header: "Cash in",
                      width: 190,
                      Cell: (row) => (
                        <InputTopup
                          type="in"
                          data={row.original}
                          putTransact={this.props.putTransact}
                          onLoadTransact={this.props.onLoadTransact}
                        />
                      ),
                    },
                    {
                      Header: "Cash out",
                      width: 190,
                      Cell: (row) => (
                        <InputTopup
                          type="out"
                          data={row.original}
                          putTransact={this.props.putTransact}
                          onLoadTransact={this.props.onLoadTransact}
                        />
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <UpdateBankUserModal
          isOpen={this.props.showUpdateBankUserModal}
          toggle={() =>
            this.props.oCBankUsersHOC("showUpdateBankUserModal", false)
          }
          bankUser={this.props.bankUser}
          patchBankUser={this.props.patchBankUser}
          banks={this.props.banks}
          staffs={this.props.staffs}
          oCBankUsersHOC={this.props.oCBankUsersHOC}
          bankAccountDetailsAll={this.props.bankAccountDetailsAll}
        />
        {(this.props.onLoadBankUsers ||
          this.props.onLoadBanks ||
          this.props.onLoadUsers) && <LoadingOverlay />}
      </>
    );
  };
}

export default compose(
  WithBankUsers,
  WithBanks,
  WithUsers,
  WithTransact
)(BankUsersContainer);
