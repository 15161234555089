import React from "react";
import _ from "lodash";
import { z } from "zod";
import {
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardFooter,
} from "reactstrap";

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  claimable_percent: z
    .number()
    .min(0, "Claimable percent is required")
    .max(100, "Claimable percent max is 100"),
  claimable_quantity: z.number().min(1, "Claimable quantity is required"),
  max_capacity: z.number().min(0, "Max capacity is required"),
  status: z.string().min(1, "Status is required"),
  claimable_quantity_per_player_account: z
    .number()
    .min(0, "Claimable quantity per player account is required"),
});

const PromotionForm = (props) => {
  const [data, updateData] = React.useState({
    name: "",
    claimable_percent: 0,
    claimable_quantity: 0,
    max_capacity: 0,
    status: "active",
    claimable_amount: 0,
    claimable_type: "amount",
    claimable_quantity_per_player_account: 0,
    is_flexible_amount: false,
  });
  const [errors, updateErrors] = React.useState([]);

  React.useEffect(() => {
    if (props.data) {
      updateData(props.data);
    }
  }, [props.data]);

  return (
    <>
      <CardBody>
        <Form>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              value={data.name}
              onChange={(e) => {
                updateData({
                  ...data,
                  name: e.target.value,
                });
              }}
            />
            {errors.length > 0 &&
              _.findIndex(errors, { path: ["name"] }) > -1 && (
                <span className="text-danger">
                  {errors[_.findIndex(errors, { path: ["name"] })].message}
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Label for="claimable_amount">Claimable amount</Label>
            <Input
              type="number"
              value={data.claimable_amount}
              onChange={(e) => {
                updateData({
                  ...data,
                  claimable_amount: e.target.value,
                });
              }}
              min="0"
            />
            {errors.length > 0 &&
              _.findIndex(errors, {
                path: ["claimable_amount"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, {
                        path: ["claimable_amount"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Label for="claimable_percent">Claimable percent</Label>
            <Input
              type="number"
              value={data.claimable_percent}
              onChange={(e) => {
                updateData({
                  ...data,
                  claimable_percent: e.target.value,
                });
              }}
              min="0"
            />
            {errors.length > 0 &&
              _.findIndex(errors, {
                path: ["claimable_percent"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, {
                        path: ["claimable_percent"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Label for="claimable_quantity">Claimable quantity</Label>
            <Input
              type="number"
              value={data.claimable_quantity}
              onChange={(e) => {
                updateData({
                  ...data,
                  claimable_quantity: e.target.value,
                });
              }}
              min="0"
            />
            {errors.length > 0 &&
              _.findIndex(errors, {
                path: ["claimable_quantity"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, {
                        path: ["claimable_quantity"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Label for="name">Max capacity</Label>
            <Input
              type="number"
              value={data.max_capacity}
              onChange={(e) => {
                updateData({
                  ...data,
                  max_capacity: e.target.value,
                });
              }}
              min="0"
            />
            {errors.length > 0 &&
              _.findIndex(errors, {
                path: ["max_capacity"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, {
                        path: ["max_capacity"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Label for="name">Claimable quantity per player account</Label>
            <Input
              type="number"
              value={data.claimable_quantity_per_player_account}
              onChange={(e) => {
                updateData({
                  ...data,
                  claimable_quantity_per_player_account: e.target.value,
                });
              }}
              min="0"
            />
            {errors.length > 0 &&
              _.findIndex(errors, {
                path: ["claimable_quantity_per_player_account"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, {
                        path: ["claimable_quantity_per_player_account"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Label for="name">Claimable type</Label>
            <Input
              type="select"
              value={data.claimable_type}
              onChange={(e) => {
                updateData({
                  ...data,
                  claimable_type: e.target.value,
                });
              }}
            >
              <option value="amount">Amount</option>
              <option value="percent">Percent</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="name">Status</Label>
            <Input
              type="select"
              value={data.status}
              onChange={(e) => {
                updateData({
                  ...data,
                  status: e.target.value,
                });
              }}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
          <FormGroup style={{ display: "flex", flexDirection: "row" }}>
            <Input
              addon
              type="checkbox"
              style={{ marginRight: "5px" }}
              checked={data.is_flexible_amount}
              onChange={(e) => {
                updateData({
                  ...data,
                  is_flexible_amount: e.target.checked,
                });
              }}
            />
            <Label for="name" style={{ marginBottom: 0 }}>
              Is flexible amount?
            </Label>
          </FormGroup>
        </Form>
      </CardBody>
      <CardFooter>
        <Button
          className="ml-auto"
          color="primary"
          onClick={() => {
            let tmp = {
              ...data,
              claimable_percent: parseInt(data.claimable_percent, 10),
              claimable_quantity: parseInt(data.claimable_quantity, 10),
              max_capacity: parseInt(data.max_capacity, 10),
              claimable_amount: parseInt(data.claimable_amount, 10),
              claimable_quantity_per_player_account: parseInt(
                data.claimable_quantity_per_player_account,
                10
              ),
            };
            let tmpErr = formSchema.safeParse(tmp);
            if (!tmpErr.success) {
              console.dir(tmpErr);
              updateErrors(tmpErr.error.issues);
            } else {
              // call api
              props.onSubmit(tmp);
            }
          }}
        >
          Submit
        </Button>
      </CardFooter>
    </>
  );
};

export default PromotionForm;
