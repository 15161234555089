import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import _ from "lodash";
import { z } from "zod";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

import WithBankUsers from "../BankUsers/actions";

const formSchema = z.object({
  bank_user_id: z.string().min(1, "Bank user id is required"),
});

const UpdateOriginBankModal = (props) => {
  const [errors, updateErrors] = React.useState([]);

  React.useEffect(() => {
    if (props.isOpen) {
      if (props.profile.role === "staff") {
        props.getBankUsersFilterUserID(3);
      } else {
        props.getBankUsersFilterUserID();
      }
      let tmp = _.cloneDeep(props.newChangeOriginBank);
      tmp["bank_user_id"] = 0;
      props.oCOriginBankHOC("newChangeOriginBank", tmp);
    }
  }, [props.isOpen]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"md"}>
      <ModalHeader toggle={props.toggle}>Update origin bank</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Select bank user</Label>
            <Input
              type="select"
              value={props.newChangeOriginBank.bank_user_id}
              onChange={(e) => {
                let tmp = _.cloneDeep(props.newChangeOriginBank);
                tmp["bank_user_id"] = e.target.value;
                props.oCOriginBankHOC("newChangeOriginBank", tmp);
              }}
            >
              <option></option>
              {props.bankUsersFilteredUserID.map((bankUser) => {
                if (
                  bankUser.id !==
                  props.newChangeOriginBank.original_bank_user_id
                ) {
                  return (
                    <option key={bankUser.id} value={bankUser.id}>
                      {`${bankUser.bank_account_detail.bank.name} - ${bankUser.bank_account_detail.account_number}`}
                    </option>
                  );
                }
              })}
            </Input>
            {errors.length > 0 &&
              _.findIndex(errors, {
                path: ["bank_user_id"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    errors[
                      _.findIndex(errors, {
                        path: ["bank_user_id"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
        </Form>
        {props.onLoadChangeOriginBank && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            let tmpErr = formSchema.safeParse(props.newChangeOriginBank);
            if (!tmpErr.success) {
              updateErrors(tmpErr.error.issues);
            } else {
              // call api
              props.updateOriginBank({
                ...props.newChangeOriginBank,
                bank_user_id: parseInt(
                  props.newChangeOriginBank.bank_user_id,
                  10
                ),
                deposit_id: props.newChangeOriginBank.deposit_id,
              });
            }
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WithBankUsers(UpdateOriginBankModal);
