import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      stats: [],
      accountTxns: [],
    };

    getAccountTransactionStats = async (start_date, end_date) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/account_transactions/stats?start_date=${start_date}&end_date=${end_date}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ stats: response.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    getAccountTransactions = async (
      account_type,
      account_id,
      action_type,
      action_by,
      shift_id
    ) => {
      this.setState({ loading: true });
      let tmp = "";
      if (account_type) {
        tmp = tmp + `q[account_type_eq]=${account_type}&`;
      }
      if (account_id) {
        tmp =
          tmp +
          `q[account_of_PlayerAccount_type_player_slug_eq]=${account_id}&`;
      }
      if (action_type) {
        tmp = tmp + `q[action_type_eq]=${action_type}&`;
      }
      if (action_by) {
        tmp = tmp + `q[action_by_eq]=${action_by}&`;
      }
      if (shift_id) {
        tmp = tmp + `q[shift_id_eq]=${shift_id}&`;
      }
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/account_transactions?${tmp}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ accountTxns: response.data.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadReports={this.state.loading}
          stats={this.state.stats}
          accountTxns={this.state.accountTxns}
          getAccountTransactionStats={this.getAccountTransactionStats}
          getAccountTransactions={this.getAccountTransactions}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
