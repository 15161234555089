import React from "react";
import Axios from "axios";
import _ from "lodash";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: [],
    };

    putTransact = async (data) => {
      let tmp = _.cloneDeep(this.state.loading);
      tmp.push(data.id);
      this.setState({ loading: tmp });
      try {
        await Axios.put(
          `${STAGING_BACKEND_URL}/bank_users/${data.id}/transact`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        toast.success("Topup successful");
        let tmpDone = _.cloneDeep(this.state.loading);
        tmpDone.splice(tmpDone.indexOf(data.id), 1);
        this.setState({ loading: tmpDone }, () => {
          this.getBankUserAfterTransact(data.id);
        });
      } catch (error) {
        toast.error("Topup failed");
        let tmpDone = _.cloneDeep(this.state.loading);
        tmpDone.splice(tmpDone.indexOf(data.id), 1);
        this.setState({ loading: tmpDone });
      }
    };

    getBankUserAfterTransact = async (id) => {
      let tmpIdx = _.findIndex(this.props.bankUsers, { id: id });
      let tmpBUs = _.cloneDeep(this.props.bankUsers);
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/bank_users/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        tmpBUs[tmpIdx] = response.data;
        this.props.oCBankUsersHOC("bankUsers", tmpBUs);
      } catch (error) {
        toast.error("Error getting selected bank user");
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadTransact={this.state.loading}
          putTransact={this.putTransact}
          getBankUserAfterTransact={this.getBankUserAfterTransact}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
