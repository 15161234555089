import { combineReducers } from "redux"

import ThemeOptions from "./ThemeOptions"
import AjaxReducer from "./Ajax"
import AuthReducer from "./Auth"
import ProfileReducer from "./profile"

export default combineReducers({
  AjaxReducer,
  ThemeOptions,
  AuthReducer,
  ProfileReducer
})
