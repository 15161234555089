import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ModalFooter,
} from "reactstrap";
import _ from "lodash";

import LoadingIndicator from "components/Indicator/LoadingOverlay";

import WithPlayerBankAccounts from "../Players/actions/PlayerBankAccounts";

const ModalNewWithdrawal = (props) => {
  const [gamePlatformUserID, setGamePlatformUserID] = React.useState("");
  const [playerID, setPlayerID] = React.useState("");
  const [newWithdrawal, setNewWithdrawal] = React.useState({
    game_platform_user_id: 0,
    player_account_id: 0,
    bank_user_id: 0,
    amount: 0,
    // player_bank_id: 0,
    // player_bank_account_name: "",
    player_bank_account_id: 0,
    user_id: 0,
    total_tips: 0,
    total_burn: 0,
  });
  const [selectedGamePlatform, updateSelectedGamePlatform] = React.useState({});
  const [staff, setStaff] = React.useState(0);

  React.useEffect(() => {
    if (props.showNewWithdrawal) {
      props.getGamePlatformsFilterUserID(props.profile.id);
    }
  }, [props.showNewWithdrawal]);

  return (
    <Modal isOpen={props.showNewWithdrawal}>
      <ModalHeader toggle={() => props.onClose()}>New Withdrawal</ModalHeader>
      <ModalBody>
        <Form>
          {(props.profile.role === "super_admin" ||
            props.profile.role === "admin") && (
            <FormGroup>
              <Label>Select staff</Label>
              <Input
                type="select"
                value={newWithdrawal.user_id}
                onChange={(e) => {
                  props.getGamePlatformsFilterUserID(
                    parseInt(e.target.value, 10)
                  );
                  props.getBankUsersFilterUserID(parseInt(e.target.value, 10));
                  setNewWithdrawal({
                    ...newWithdrawal,
                    user_id: parseInt(e.target.value, 10),
                  });
                  let tmp = _.find(props.gamePlatformsFilteredUserID, {
                    id: parseInt(e.target.value, 10),
                  });
                  updateSelectedGamePlatform(tmp);
                }}
              >
                <option></option>
                {props.staffs.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.username}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          )}
          <FormGroup>
            <Label>Game platform user ID</Label>
            <Input
              type="select"
              value={newWithdrawal.game_platform_user_id}
              onChange={(e) => {
                setNewWithdrawal({
                  ...newWithdrawal,
                  game_platform_user_id: e.target.value,
                });
                let tmp = _.find(props.gamePlatformsFilteredUserID, {
                  id: parseInt(e.target.value, 10),
                });
                updateSelectedGamePlatform(tmp);
              }}
            >
              <option></option>
              {props.gamePlatformsFilteredUserID.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {`${item.user_id} - ${item.game_platform.name} - ${item.starting_amount}`}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Player ID</Label>
            <Input
              type="text"
              value={playerID}
              onChange={(e) => {
                setPlayerID(e.target.value);
              }}
            />
            <Button
              color="primary"
              onClick={() => {
                props.getPlayerAccountsViaSlug(playerID);
                props.getPlayerBankAccountsViaSlug(playerID);
              }}
            >
              Search
            </Button>
            <Input
              type="select"
              value={newWithdrawal.player_account_id}
              onChange={(e) => {
                setNewWithdrawal({
                  ...newWithdrawal,
                  player_account_id: e.target.value,
                });
              }}
            >
              <option></option>
              {props.playerAccounts.map((item) => {
                if (
                  item.game_platform.id ===
                  selectedGamePlatform.game_platform_id
                ) {
                  return (
                    <option key={item.id} value={item.id}>
                      {`${item.player.slug} - ${item.game_platform_username} - ${item.game_platform.name}`}
                    </option>
                  );
                }
              })}
            </Input>
          </FormGroup>
          {/* <FormGroup>
            <Label>Player bank</Label>
            <Input
              type="select"
              value={newWithdrawal.player_bank_id}
              onChange={(e) => {
                setNewWithdrawal({
                  ...newWithdrawal,
                  player_bank_id: e.target.value,
                });
              }}
            >
              <option></option>
              {props.banks.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Player bank account name</Label>
            <Input
              type="text"
              value={newWithdrawal.player_bank_account_name}
              onChange={(e) => {
                setNewWithdrawal({
                  ...newWithdrawal,
                  player_bank_account_name: e.target.value,
                });
              }}
            />
          </FormGroup> */}
          <FormGroup>
            <Label>To player bank</Label>
            <Input
              type="select"
              value={newWithdrawal.player_bank_account_id}
              onChange={(e) => {
                setNewWithdrawal({
                  ...newWithdrawal,
                  player_bank_account_id: e.target.value,
                });
              }}
            >
              <option></option>
              {props.playerBankAccountsSlug.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {`${item.holder_name} - ${item.account_number} - ${item.bank.name}`}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Origin bank</Label>
            <Input
              type="select"
              value={newWithdrawal.bank_user_id}
              onChange={(e) => {
                setNewWithdrawal({
                  ...newWithdrawal,
                  bank_user_id: e.target.value,
                });
              }}
            >
              <option></option>
              {props.bankUsersFilteredUserID.map((item) => {
                return (
                  <option
                    key={item.id}
                    value={item.id}
                  >{`${item.bank_account_detail.bank_name} - ${item.bank_account_detail.account_number}`}</option>
                );
              })}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Amount</Label>
            <Input
              type="text"
              value={newWithdrawal.amount}
              onChange={(e) => {
                setNewWithdrawal({
                  ...newWithdrawal,
                  amount: e.target.value,
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Tips Amount</Label>
            <Input
              type="number"
              value={newWithdrawal.total_tips}
              onChange={(e) => {
                setNewWithdrawal({
                  ...newWithdrawal,
                  total_tips: e.target.value,
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Burn Amount</Label>
            <Input
              type="number"
              value={newWithdrawal.total_burn}
              onChange={(e) => {
                setNewWithdrawal({
                  ...newWithdrawal,
                  total_burn: e.target.value,
                });
              }}
            />
          </FormGroup>
        </Form>
        {(props.onLoadBankUsers ||
          props.onLoadGamePlatformUsers ||
          props.onLoadPlayers ||
          props.onLoadBanks ||
          props.onLoadPlayerAccounts ||
          props.onLoadPlayerBankAccounts ||
          props.loadingCreateWithdrawal) && <LoadingIndicator />}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            props.postWithdrawal({
              ...newWithdrawal,
              bank_user_id: parseInt(newWithdrawal.bank_user_id, 10),
              game_platform_user_id: parseInt(
                newWithdrawal.game_platform_user_id,
                10
              ),
              amount: parseInt(newWithdrawal.amount, 10),
              player_account_id: parseInt(newWithdrawal.player_account_id, 10),
              player_bank_account_id: parseInt(
                newWithdrawal.player_bank_account_id,
                10
              ),
              // player_bank_id: parseInt(newWithdrawal.player_bank_id, 10),
            });
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WithPlayerBankAccounts(ModalNewWithdrawal);
