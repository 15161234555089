import React, { Component } from "react";
import Axios from "axios";
import _ from "lodash";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newChangeOriginBank: {
        bank_user_id: 0,
        deposit_id: 0,
        original_bank_user_id: 0,
      },
      showChangeOriginBank: false,
    };

    oCOriginBankHOC = (key, val) => this.setState({ [key]: val });

    oCDepositId = (id, bank_user_id) => {
      let tmp = _.cloneDeep(this.state.newChangeOriginBank);
      tmp["deposit_id"] = id;
      tmp["original_bank_user_id"] = bank_user_id;
      this.setState({ newChangeOriginBank: tmp, showChangeOriginBank: true });
    };

    updateOriginBank = async (data) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/deposits/${data.deposit_id}/change_origin_bank`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ showChangeOriginBank: false });
        toast.success("Origin bank is successfully updated.");
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          oCOriginBankHOC={this.oCOriginBankHOC}
          oCDepositId={this.oCDepositId}
          updateOriginBank={this.updateOriginBank}
          newChangeOriginBank={this.state.newChangeOriginBank}
          showChangeOriginBank={this.state.showChangeOriginBank}
          onLoadChangeOriginBank={this.state.loading}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
