import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import ReactTable from "react-table";
import _ from "lodash";
import { z } from "zod";
import { toast } from "react-toastify";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import ModalCreatePlayerBankAccount from "./ModalCreatePlayerBankAccount";
import ModalUpdatePlayerBankAccount from "./ModalUpdatePlayerBankAccount";
import PromptModal from "../../../components/Indicator/Prompt";
import ModalUpdatePlayerAccountPassword from "./ModalUpdatePlayerAccountPassword";
import TableGamePlatform from "./TableGamePlatform";

import WithPlayerBankAccounts from "./actions/PlayerBankAccounts";

const formSchema = z.object({
  register_mode: z.enum(["manual", "auto"]),
  game_platform_username: z.string().min(1, "username is required"),
  password: z.string().min(1, "password is required"),
  game_platform_id: z.number().min(1, "game_platform_id is required"),
});

const UpdatePlayerModal = (props) => {
  const [selectedPlayerAccount, updateSelectedPlayerAccount] = React.useState({
    id: 0,
  });
  const [selectedGamePlatformId, updateSelectedGamePlatformId] =
    React.useState(0);
  // manual or auto
  // const [gameCreateMode, updateGameCreateMode] = React.useState("manual");
  const [newGameId, updateNewGameId] = React.useState({
    player_id: 0,
    game_platform_username: "",
    password: "",
    game_platform_id: 0,
    register_mode: "manual",
  });
  const [newGameIdErrors, updateNewGameIdErrors] = React.useState([]);
  const [tmpSlug, updateTmpSlug] = React.useState("");
  const [
    showCreatePlayerBankAccountModal,
    updateShowCreatePlayerBankAccountModal,
  ] = React.useState(false);

  React.useEffect(() => {
    if (props.isOpen) {
      props.getPlayerAccounts(props.selectedPlayer.id);
      updateNewGameId({ ...newGameId, player_id: props.selectedPlayer.id });
      updateTmpSlug(props.selectedPlayer.slug);
      props.getPlayerBankAccounts(props.selectedPlayer.id);
    }
  }, [props.isOpen]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"xl"}>
      <ModalHeader toggle={props.toggle}>Update Player</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="PlayerID">ID</Label>
            <Input
              type="text"
              value={tmpSlug}
              onChange={(e) => {
                updateTmpSlug(e.target.value);
              }}
            />
          </FormGroup>
          <div className="d-flex">
            <Button
              color="primary"
              className="ml-auto mb-3"
              onClick={() => {
                props.putPlayer(props.selectedPlayer.id, tmpSlug);
              }}
            >
              Submit
            </Button>
          </div>
        </Form>
        <Card className="mb-2">
          <CardHeader>Add platform</CardHeader>
          <CardBody>
            <Form>
              <FormGroup>
                <Label>Select a game platform</Label>
                <Input
                  type="select"
                  value={selectedGamePlatformId}
                  onChange={(e) => {
                    updateSelectedGamePlatformId(e.target.value);
                    updateNewGameId({
                      ...newGameId,
                      game_platform_id: e.target.value,
                    });
                  }}
                >
                  <option></option>
                  {props.gamePlatformUsers.map((item) => {
                    return (
                      <option key={item.id} value={item.game_platform_id}>
                        {`${item.game_platform.name}`}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Mode</Label>
                <Input
                  type={"select"}
                  value={newGameId.register_mode}
                  onChange={(e) =>
                    updateNewGameId({
                      ...newGameId,
                      register_mode: e.target.value,
                    })
                  }
                >
                  <option value="manual">Manual</option>
                  <option value="api">Auto</option>
                </Input>
              </FormGroup>
              {newGameId.register_mode === "manual" ? (
                <>
                  <FormGroup>
                    <Label>Username</Label>
                    <Input
                      type="text"
                      value={newGameId.game_platform_username}
                      onChange={(e) => {
                        let tmp = _.cloneDeep(newGameId);
                        tmp.game_platform_username = e.target.value;
                        updateNewGameId(tmp);
                      }}
                    />
                    {newGameIdErrors.length > 0 &&
                      _.findIndex(newGameIdErrors, {
                        path: ["game_platform_username"],
                      }) > -1 && (
                        <span className="text-danger">
                          {
                            newGameIdErrors[
                              _.findIndex(newGameIdErrors, {
                                path: ["game_platform_username"],
                              })
                            ].message
                          }
                        </span>
                      )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input
                      type="text"
                      value={newGameId.password}
                      onChange={(e) => {
                        let tmp = _.cloneDeep(newGameId);
                        tmp.password = e.target.value;
                        updateNewGameId(tmp);
                      }}
                    />
                    {newGameIdErrors.length > 0 &&
                      _.findIndex(newGameIdErrors, {
                        path: ["password"],
                      }) > -1 && (
                        <span className="text-danger">
                          {
                            newGameIdErrors[
                              _.findIndex(newGameIdErrors, {
                                path: ["password"],
                              })
                            ].message
                          }
                        </span>
                      )}
                  </FormGroup>
                </>
              ) : (
                <>
                  <FormGroup>
                    <Label>Username</Label>
                    <Input type="text" disabled={true} />
                    <span>
                      System will auto create username upon connected to the
                      game.
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <Label>Password</Label>
                    <Input
                      type="text"
                      value={newGameId.password}
                      onChange={(e) => {
                        let tmp = _.cloneDeep(newGameId);
                        tmp.password = e.target.value;
                        updateNewGameId(tmp);
                      }}
                    />
                    {newGameIdErrors.length > 0 &&
                      _.findIndex(newGameIdErrors, {
                        path: ["password"],
                      }) > -1 && (
                        <span className="text-danger">
                          {
                            newGameIdErrors[
                              _.findIndex(newGameIdErrors, {
                                path: ["password"],
                              })
                            ].message
                          }
                        </span>
                      )}
                  </FormGroup>
                </>
              )}
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              color="primary"
              className="ml-auto"
              onClick={() => {
                let tmp = {
                  ...newGameId,
                  game_platform_id: parseInt(selectedGamePlatformId, 10),
                };
                if (newGameId.register_mode === "manual") {
                  let tmpErr = formSchema.safeParse(tmp);
                  if (!tmpErr.success) {
                    console.dir(tmpErr);
                    updateNewGameIdErrors(tmpErr.error.issues);
                  } else {
                    // call api
                    props.postPlayerAccount(newGameId, props.selectedPlayer);
                  }
                } else {
                  if (newGameId.password.length <= 6) {
                    toast.error("Password must be at least 6 characters long");
                  } else {
                    // call api
                    props.postPlayerAccount(newGameId, props.selectedPlayer);
                  }
                }
              }}
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
        <Card className="mb-2">
          <CardHeader>
            Bank Accounts
            <Button
              color="primary"
              className="ml-auto"
              onClick={() => {
                Promise.all([
                  props.oCPlayerBankAccountsHOC(
                    "showCreatePlayerBankAccount",
                    true
                  ),
                ]).then(() => {
                  props.oCPlayerBankAccountsHOC("newPlayerBankAccount", {
                    ...props.newPlayerBankAccount,
                    player_id: props.selectedPlayer.id,
                  });
                });
              }}
            >
              Create
            </Button>
          </CardHeader>
          <CardBody>
            <ModalCreatePlayerBankAccount
              isOpen={props.showCreatePlayerBankAccount}
              toggle={() =>
                props.oCPlayerBankAccountsHOC(
                  "showCreatePlayerBankAccount",
                  false
                )
              }
              newPlayerBankAccount={props.newPlayerBankAccount}
              oCPlayerBankAccountsHOC={props.oCPlayerBankAccountsHOC}
              createPlayerBankAccount={props.createPlayerBankAccount}
            />
            <ModalUpdatePlayerBankAccount
              isOpen={props.showUpdatePlayerBankAccount}
              toggle={() =>
                props.oCPlayerBankAccountsHOC(
                  "showUpdatePlayerBankAccount",
                  false
                )
              }
              selectedPlayerBankAccount={props.selectedPlayerBankAccount}
              oCPlayerBankAccountsHOC={props.oCPlayerBankAccountsHOC}
              updatePlayerBankAccount={props.updatePlayerBankAccount}
            />
            <ReactTable
              data={props.playerBankAccounts}
              pageSize={props.playerBankAccounts.length}
              columns={[
                {
                  Header: "Bank",
                  accessor: "bank.name",
                },
                {
                  Header: "Account number",
                  accessor: "account_number",
                },
                {
                  Header: "Holder name",
                  accessor: "holder_name",
                },
                {
                  Header: "Actions",
                  Cell: (row) => {
                    return (
                      <>
                        <Button
                          color="primary"
                          className="btn-icon btn-icon-only mr-2"
                          onClick={() => {
                            props.getSelectedPlayerBankAccount(row.original);
                          }}
                        >
                          <i className="pe-7s-pen btn-icon-wrapper" />
                        </Button>
                        <Button
                          color="danger"
                          className="btn-icon btn-icon-only"
                          onClick={() => {
                            Promise.all([
                              props.oCPlayerBankAccountsHOC(
                                "toDeletePlayerBankAccountId",
                                row.original.id
                              ),
                            ]).then(() => {
                              props.oCPlayerBankAccountsHOC(
                                "showConfirmDeletePlayerBankAccount",
                                true
                              );
                            });
                          }}
                        >
                          <i className="pe-7s-trash btn-icon-wrapper" />
                        </Button>
                      </>
                    );
                  },
                },
              ]}
            />
            <PromptModal
              showPromptModal={props.showConfirmDeletePlayerBankAccount}
              onClickYes={() => {
                props.deletePlayerBankAccount(
                  props.toDeletePlayerBankAccountId
                );
              }}
              onClickNo={() => {
                props.oCPlayerBankAccountsHOC(
                  "showConfirmDeletePlayerBankAccount",
                  false
                );
              }}
              content={
                "Are you sure to remove the selected player bank account?"
              }
            />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Game Platforms</CardHeader>
          <CardBody>
            <TableGamePlatform
              {...props}
              updateSelectedPlayerAccount={updateSelectedPlayerAccount}
            />
            <ModalUpdatePlayerAccountPassword
              isOpen={props.showPlayerAccountChangePassword}
              toggle={() =>
                props.oCPlayerAccountsHOC(
                  "showPlayerAccountChangePassword",
                  false
                )
              }
              changePlayerAccountPassword={props.changePlayerAccountPassword}
              selectedPlayerAccount={selectedPlayerAccount}
              selectedPlayer={props.selectedPlayer}
            />
          </CardBody>
        </Card>
        {(props.onLoadPlayerAccounts ||
          props.onLoadPlayers ||
          props.onLoadPlayerBankAccounts) && <LoadingOverlay />}
      </ModalBody>
    </Modal>
  );
};

export default WithPlayerBankAccounts(UpdatePlayerModal);
