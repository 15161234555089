import React from "react";
import PageTitle from "components/Title";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import _ from "lodash";
import ReactTable from "react-table";
import { z } from "zod";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import UpdateBank from "./Update";

import WithBanks from "./actions";

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
});

class BanksContainer extends React.Component {
  state = {
    newBank: {
      name: "",
    },
    newBankDetails: {
      bank_id: 0,
      account_number: "",
      starting_amount: 0.0,
    },
    errors: [],
  };

  componentDidMount = () => {
    this.props.getBanks();
    this.props.getBankDetails();
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Bank accounts"
          subheading="All bank accounts in the system."
          icon="pe-7s-cash icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        {/* create promotion from here */}
        <Row>
          <Col lg={12}>
            {/* <Card className="main-card mb-3">
              <CardHeader>Create bank</CardHeader>
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label>Name</Label>
                    <Input
                      type="text"
                      value={this.state.newBank.name}
                      onChange={(e) => {
                        this.setState({
                          newBank: {
                            ...this.state.newBank,
                            name: e.target.value,
                          },
                        });
                      }}
                    />
                    {this.state.errors.length > 0 &&
                      _.findIndex(this.state.errors, { path: ["name"] }) >
                        -1 && (
                        <span className="text-danger">
                          {
                            this.state.errors[
                              _.findIndex(this.state.errors, { path: ["name"] })
                            ].message
                          }
                        </span>
                      )}
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  className="ml-auto"
                  onClick={() => {
                    let tmpErr = formSchema.safeParse(this.state.newBank);
                    if (!tmpErr.success) {
                      console.dir(tmpErr);
                      this.setState({ errors: tmpErr.error.issues });
                    } else {
                      // call api
                      this.props.postBank(this.state.newBank);
                    }
                  }}
                >
                  Submit
                </Button>
              </CardFooter>
            </Card> */}
            <Card className="main-card mb-3">
              <CardHeader>Create bank</CardHeader>
              <CardBody>
                <Form>
                  <FormGroup>
                    <Label>Select bank</Label>
                    <Input
                      type="select"
                      value={this.state.newBankDetails.bank_id}
                      onChange={(e) => {
                        this.setState({
                          newBankDetails: {
                            ...this.state.newBankDetails,
                            bank_id: e.target.value,
                          },
                        });
                      }}
                    >
                      <option></option>
                      {this.props.banks.map((bank) => {
                        return (
                          <option key={`bank-${bank.id}`} value={bank.id}>
                            {bank.name}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Bank account name</Label>
                    <Input
                      type="text"
                      value={this.state.newBankDetails.bank_account_name}
                      onChange={(e) => {
                        this.setState({
                          newBankDetails: {
                            ...this.state.newBankDetails,
                            bank_account_name: e.target.value,
                          },
                        });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Account number</Label>
                    <Input
                      type="text"
                      value={this.state.newBankDetails.account_number}
                      onChange={(e) => {
                        this.setState({
                          newBankDetails: {
                            ...this.state.newBankDetails,
                            account_number: e.target.value,
                          },
                        });
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Starting amount</Label>
                    <Input
                      type="text"
                      value={this.state.newBankDetails.starting_amount}
                      onChange={(e) => {
                        this.setState({
                          newBankDetails: {
                            ...this.state.newBankDetails,
                            starting_amount: e.target.value,
                          },
                        });
                      }}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  className="ml-auto"
                  onClick={() => {
                    // call api
                    this.props.postBankDetails(this.state.newBankDetails);
                  }}
                >
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={10}
                  data={this.props.bankDetails}
                  columns={[
                    {
                      Header: "Bank account name",
                      Cell: (row) => (
                        <span>{`${
                          row.original.name ? row.original.name : ""
                        } - ${row.original.bank?.name}`}</span>
                      ),
                    },
                    {
                      Header: "Account number",
                      accessor: "account_number",
                    },
                    {
                      Header: "Starting amount",
                      accessor: "starting_amount",
                    },
                    {
                      Header: "Cash in",
                      accessor: "cash_in",
                    },
                    {
                      Header: "Cash out",
                      accessor: "cash_out",
                    },
                    {
                      Header: "Total deposits",
                      accessor: "total_deposits",
                    },
                    {
                      Header: "Total withdrawal",
                      accessor: "total_withdrawal",
                    },
                    {
                      Header: "Bank charge",
                      accessor: "bank_charge",
                    },
                    {
                      Header: "Incentive",
                      accessor: "incentive",
                    },
                    {
                      Header: "Total tips",
                      accessor: "total_tips",
                    },
                    {
                      Header: "Balance",
                      accessor: "balance",
                    },
                    {
                      Header: "Total burn",
                      accessor: "total_burn",
                    },
                    {
                      Header: "Action",
                      Cell: (row) => (
                        <Button
                          color="primary"
                          className="btn-icon btn-icon-only"
                          onClick={() => {
                            Promise.all([
                              this.props.oCBanksHOC("bank", row.original),
                            ]).then(() => {
                              this.props.oCBanksHOC("showUpdate", true);
                            });
                          }}
                        >
                          <i className="pe-7s-pen btn-icon-wrapper" />
                        </Button>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.props.onLoadBanks && <LoadingOverlay />}
        <UpdateBank
          showUpdate={this.props.showUpdate}
          toggle={() => this.props.oCBanksHOC("showUpdate", false)}
          bank={this.props.bank}
          oCBanksHOC={this.props.oCBanksHOC}
          putBank={this.props.putBank}
        />
      </>
    );
  };
}

export default WithBanks(BanksContainer);
