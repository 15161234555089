import React from "react";
import PageTitle from "components/Title";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Table,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import _ from "lodash";
import { compose } from "redux";
import { faker } from "@faker-js/faker";

import NewRow from "./NewRow";
import TableDeposit from "./Table";
import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import ModalUpdateOriginBank from "./UpdateOriginBank";

import WithBanks from "../Banks/actions";
import WithGamePlatforms from "../GamePlatforms/actions";
import WithPromotions from "../Promotions/actions";
import WithPlayers from "../Players/actions";
import WithKioskDeposits from "./actions/kioskDeposit";
import WithBankUsers from "../BankUsers/actions";
import WithGamePlatformUsers from "../GamePlatformUsers/actions";
import WithUsers from "../Users/actions";
import WithChangeBank from "./actions/withChangeBank";

export const Banks = [
  {
    id: 1,
    name: "Maybank",
    color: "warning",
  },
  {
    id: 2,
    name: "CIMB",
    color: "danger",
  },
  {
    id: 3,
    name: "BSN",
    color: "primary",
  },
];

class Deposits extends React.Component {
  state = {
    // newDeposits: [],
    newDeposit: {
      uuid: "",
      game_platform_id: 0,
      bank_id: 0,
      player_id: "",
      promotion_id: 0,
      bonus_amount: 0,
      deposit_amount: "",
    },
    disableCreateIndexes: [],
    errorCreateIndexes: [],
    search: {
      "q[user_username_eq]": "",
      "q[bank_user_bank_name_cont]": "",
      "q[player_account_player_slug_eq]": "",
      "q[game_platform_user_game_platform_name_cont]": "",
      "q[player_account_game_platform_username_eq]": "",
      "q[promotion_name_cont]": "",
      "q[status_eq]": null,
      page: 1,
    },
    selectedStaffID: this.props.profile.id,
    showSearch: false,
  };

  componentDidMount = () => {
    this.props.getBanks();
    // this.props.getBankUsers();
    this.props.getGamePlatforms();
    this.props.getPromotions();
    this.props.getPlayers();
    this.props.getGamePlatformUsers();
    this.props.getDeposits(this.state.search);
    this.props.getUsersWithRoleStaffs();
    if (this.props.profile.role === "staff") {
      this.props.getBankUsersFilterUserID(this.state.selectedStaffID);
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    // if (
    //   this.props.lastSucceededDepositIndex !==
    //   prevProps.lastSucceededDepositIndex
    // ) {
    //   if (this.props.lastSucceededDepositIndex !== null) {
    //     let tmp = _.cloneDeep(this.state.newDeposits);
    //     tmp.splice(this.props.lastSucceededDepositIndex, 1);
    //     this.setState(
    //       {
    //         newDeposits: tmp,
    //       },
    //       () => {
    //         this.props.oCKioskDepositHOC("lastSucceededDepositIndex", null);
    //       }
    //     );
    //   }
    // }
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Deposits"
          subheading="Place to reload deposits for the players."
          icon="pe-7s-cash icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-1">
              <CardBody>
                <Row>
                  <Col md={12}>
                    {/* <Button
                      color={"primary"}
                      className="mr-2"
                      onClick={() => {
                        let tmp = _.cloneDeep(this.props.newDeposits);
                        let tmpDeposit = _.cloneDeep(this.state.newDeposit);
                        tmpDeposit.bank_id = 0;
                        console.dir(tmpDeposit);
                        tmpDeposit.uuid = faker.string.uuid();
                        tmp.push(tmpDeposit);
                        return this.setState(
                          {
                            newDeposit: tmpDeposit,
                          },
                          () => {
                            this.props.oCKioskDepositHOC("newDeposits", tmp);
                          }
                        );
                      }}
                    >
                      {`Create deposit`}
                    </Button> */}
                    {this.props.profile.role !== "staff" && (
                      <FormGroup>
                        <Label>Select a staff</Label>
                        <Input
                          type="select"
                          className="form-control"
                          value={this.state.selectedStaffID}
                          onChange={(e) => {
                            this.setState(
                              {
                                selectedStaffID: parseInt(e.target.value, 10),
                              },
                              () => {
                                this.props.getBankUsersFilterUserID(
                                  this.state.selectedStaffID
                                );
                              }
                            );
                          }}
                        >
                          {this.props.staffs.map((item) => {
                            return (
                              <option key={`Staff-${item.id}`} value={item.id}>
                                {item.username}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    )}
                    {this.props.bankUsersFilteredUserID.length === 0 && (
                      <div>
                        <h5>No bank is associated with this user.</h5>
                      </div>
                    )}
                    {this.props.bankUsersFilteredUserID.map((bankUser) => {
                      return (
                        <Button
                          key={bankUser.bank_account_detail.bank_name}
                          color={"primary"}
                          className="mr-2"
                          onClick={() => {
                            let tmp = _.cloneDeep(this.props.newDeposits);
                            let tmpDeposit = _.cloneDeep(this.state.newDeposit);
                            tmpDeposit.bank_user_id = bankUser.id;
                            tmpDeposit.user_id = this.state.selectedStaffID;
                            console.dir(tmpDeposit);
                            tmpDeposit.uuid = faker.string.uuid();
                            tmp.push(tmpDeposit);
                            return this.setState(
                              {
                                newDeposit: tmpDeposit,
                              },
                              () => {
                                this.props.oCKioskDepositHOC(
                                  "newDeposits",
                                  tmp
                                );
                                this.props.getGamePlatformsFilterUserID(
                                  this.state.selectedStaffID
                                );
                              }
                            );
                          }}
                        >
                          {`${bankUser.bank_account_detail.bank.name}(Click to add data)`}
                        </Button>
                      );
                    })}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-1">
              <CardBody>
                <h5 className="text-danger">
                  Must do from left to right or else will have error!
                </h5>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Staff</th>
                      <th>Player ID</th>
                      <th>Product</th>
                      <th>Game ID</th>
                      <th>Deposit amount</th>
                      <th>Bonus</th>
                      <th>Total bonus given</th>
                      <th>Bank name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.newDeposits.map((item, index) => {
                      return (
                        <NewRow
                          key={item.uuid}
                          item={item}
                          index={index}
                          banks={this.props.banks}
                          games={this.props.games}
                          promotions={this.props.promotions}
                          players={this.props.players}
                          removeRow={() => {
                            let tmp = _.cloneDeep(this.props.newDeposits);
                            tmp.splice(index, 1);
                            return this.props.oCKioskDepositHOC(
                              "newDeposits",
                              tmp
                            );
                          }}
                          profile={this.props.profile}
                          getDeposits={this.props.getDeposits}
                          search={this.state.search}
                          newDeposits={this.props.newDeposits}
                          oCKioskDepositHOC={this.props.oCKioskDepositHOC}
                          gamePlatformUsers={this.props.gamePlatformUsers}
                          staffs={this.props.staffs}
                          bankUsers={this.props.bankUsers}
                          getBankUsersFilterUserID={
                            this.props.getBankUsersFilterUserID
                          }
                          bankUsersFilteredUserID={
                            this.props.bankUsersFilteredUserID
                          }
                          selectedStaffID={this.state.selectedStaffID}
                          gamePlatformsFilteredUserID={
                            this.props.gamePlatformsFilteredUserID
                          }
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-1">
              <CardHeader>
                <Button
                  color="primary"
                  onClick={() =>
                    this.setState({ showSearch: !this.state.showSearch })
                  }
                >
                  Toggle search
                </Button>
              </CardHeader>
              {this.state.showSearch && (
                <>
                  <CardBody>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Agent ID</Label>
                          <Input
                            type="text"
                            name="q[user_username_eq]"
                            value={this.state.search["q[user_username_eq]"]}
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[user_username_eq]"] = e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Bank</Label>
                          <Input
                            type="select"
                            name="q[bank_user_bank_name_cont]"
                            value={
                              this.state.search["q[bank_user_bank_name_cont]"]
                            }
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[bank_user_bank_name_cont]"] =
                                e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          >
                            <option></option>
                            {this.props.banks.map((bank) => {
                              return (
                                <option key={bank.id} value={bank.name}>
                                  {bank.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Player ID</Label>
                          <Input
                            type="text"
                            name="q[player_account_player_slug_eq]"
                            value={
                              this.state.search[
                                "q[player_account_player_slug_eq]"
                              ]
                            }
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[player_account_player_slug_eq]"] =
                                e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Product</Label>
                          <Input
                            type="select"
                            name="q[game_platform_user_game_platform_name_cont]"
                            value={
                              this.state.search[
                                "q[game_platform_user_game_platform_name_cont]"
                              ]
                            }
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp[
                                "q[game_platform_user_game_platform_name_cont]"
                              ] = e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          >
                            <option></option>
                            {this.props.games.map((item) => (
                              <option
                                key={`GamePlatform-${item.id}`}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Game ID</Label>
                          <Input
                            type="text"
                            name="q[player_account_game_platform_username_eq]"
                            value={
                              this.state.search[
                                "q[player_account_game_platform_username_eq]"
                              ]
                            }
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp[
                                "q[player_account_game_platform_username_eq]"
                              ] = e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Promotion</Label>
                          <Input
                            type="select"
                            name="q[promotion_name_cont]"
                            value={this.state.search["q[promotion_name_cont]"]}
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[promotion_name_cont]"] = e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          >
                            <option></option>
                            {this.props.promotions.map((item) => (
                              <option
                                key={`Promotion-${item.id}`}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <Label>Status</Label>
                          <div className="pl-4 d-flex">
                            <FormGroup style={{ width: "60px" }}>
                              <Input
                                type="radio"
                                checked={
                                  this.state.search["q[status_eq]"] === null
                                }
                                onClick={(e) => {
                                  let tmp = _.cloneDeep(this.state.search);
                                  tmp["q[status_eq]"] = null;
                                  return this.setState({ search: tmp });
                                }}
                              />{" "}
                              All
                            </FormGroup>
                            <FormGroup style={{ width: "100px" }}>
                              <Input
                                type="radio"
                                checked={
                                  this.state.search["q[status_eq]"] === 0
                                }
                                onClick={(e) => {
                                  let tmp = _.cloneDeep(this.state.search);
                                  tmp["q[status_eq]"] = 0;
                                  return this.setState({ search: tmp });
                                }}
                              />{" "}
                              Pending
                            </FormGroup>
                            <FormGroup style={{ width: "100px" }}>
                              <Input
                                type="radio"
                                checked={
                                  this.state.search["q[status_eq]"] === 1
                                }
                                onClick={(e) => {
                                  let tmp = _.cloneDeep(this.state.search);
                                  tmp["q[status_eq]"] = 1;
                                  return this.setState({ search: tmp });
                                }}
                              />{" "}
                              Success
                            </FormGroup>
                            <FormGroup style={{ width: "100px" }}>
                              <Input
                                type="radio"
                                checked={
                                  this.state.search["q[status_eq]"] === 2
                                }
                                onClick={(e) => {
                                  let tmp = _.cloneDeep(this.state.search);
                                  tmp["q[status_eq]"] = 2;
                                  return this.setState({ search: tmp });
                                }}
                              />{" "}
                              Fail
                            </FormGroup>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="primary"
                      className="ml-auto"
                      onClick={() => {
                        this.props.getDeposits(this.state.search);
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => {
                        this.setState(
                          {
                            search: {
                              "q[user_username_eq]": "",
                              "q[bank_user_bank_name_cont]": "",
                              "q[player_account_player_slug_eq]": "",
                              "q[game_platform_user_game_platform_name_cont]":
                                "",
                              "q[player_account_game_platform_username_eq]": "",
                              "q[promotion_name_cont]": "",
                              "q[status_eq]": null,
                              page: 1,
                            },
                          },
                          () => {
                            this.props.getDeposits(this.state.search);
                          }
                        );
                      }}
                    >
                      Reset
                    </Button>
                  </CardFooter>
                </>
              )}
            </Card>
            <Card className="main-card mb-3">
              <CardBody>
                <TableDeposit
                  deposits={this.props.deposits}
                  getDeposits={this.props.getDeposits}
                  search={this.state.search}
                  onChangeSearch={(data) => this.setState({ search: data })}
                  onClickEditBank={(id, bank_user_id) => {
                    this.props.oCDepositId(id, bank_user_id);
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalUpdateOriginBank
          oCOriginBankHOC={this.props.oCOriginBankHOC}
          newChangeOriginBank={this.props.newChangeOriginBank}
          isOpen={this.props.showChangeOriginBank}
          onLoadChangeOriginBank={this.props.onLoadChangeOriginBank}
          toggle={() => {
            this.props.oCOriginBankHOC("showChangeOriginBank", false);
          }}
          updateOriginBank={this.props.updateOriginBank}
          profile={this.props.profile}
        />
        {(this.props.onLoadBankUsers ||
          this.props.onLoadGames ||
          this.props.onLoadPromotions ||
          this.props.onLoadPlayers ||
          this.props.onLoadDeposits ||
          this.props.onLoadChangeOriginBank) && <LoadingOverlay />}
      </>
    );
  };
}

export default compose(
  WithBanks,
  WithGamePlatforms,
  WithPromotions,
  WithPlayers,
  WithKioskDeposits,
  WithBankUsers,
  WithGamePlatformUsers,
  WithUsers,
  WithChangeBank
)(Deposits);
