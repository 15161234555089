import React from "react";
import { Input, Button } from "reactstrap";
import _ from "lodash";

import WithPlayerAccounts from "../Deposits/actions/playerAccounts";

const NewRow = (props) => {
  const [fromGameId, updateFromGameId] = React.useState(0);
  const [toGameId, updateToGameId] = React.useState(0);
  const [playerSlug, updatePlayerSlug] = React.useState("");

  React.useEffect(() => {
    if (props.playerAccounts.length > 0) {
      let tmp = _.cloneDeep(props.newTransfers);
      tmp[props.index].from_player_account_id = props.playerAccounts[0].id;
      props.oCTransferHOC("newTransfers", tmp);
    }
  }, [props.playerAccounts]);

  return (
    <tr>
      <td>{props.profile.username}</td>
      <td>
        <Input
          type="text"
          value={playerSlug}
          onChange={(e) => {
            updatePlayerSlug(e.target.value);
          }}
        />
      </td>
      <td>
        <Input
          type="select"
          value={fromGameId}
          onChange={(e) => {
            updateFromGameId(e.target.value);
            props.findPlayerAccounts(playerSlug, e.target.value);
          }}
        >
          <option></option>
          {props.gamePlatformUsers.map((item) => (
            <option
              key={`GamePlatform-${item.id}`}
              value={item.game_platform.id}
            >
              {`${item.game_platform.name} - ${item.user.name}`}
            </option>
          ))}
        </Input>
      </td>
      <td>
        {props.onLoadPlayerAccounts ? (
          <span>Loading....</span>
        ) : (
          <Input
            type="select"
            value={props.data.from_player_account_id}
            onChange={(e) => {
              let tmp = _.cloneDeep(props.newTransfers);
              tmp[props.index].from_player_account_id = e.target.value;
              props.oCTransferHOC("newTransfers", tmp);
              // updateData({...data, from_player_account_id: e.target.value });
            }}
          >
            <option></option>
            {props.playerAccounts.map((item) => (
              <option key={`PlayerAccount-${item.id}`} value={item.id}>
                {item.game_platform_username}
              </option>
            ))}
          </Input>
        )}
      </td>
      <td>
        <Input
          type="number"
          value={props.data.amount}
          onChange={(e) => {
            // updateData({...data, amount: e.target.value });
            let tmp = _.cloneDeep(props.newTransfers);
            tmp[props.index].amount = e.target.value;
            props.oCTransferHOC("newTransfers", tmp);
          }}
        />
      </td>
      <td>
        <Input
          type="select"
          value={toGameId}
          onChange={(e) => {
            updateToGameId(e.target.value);
            props.findPlayerAccountsSub(playerSlug, e.target.value);
          }}
        >
          <option></option>
          {props.games.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </Input>
      </td>
      <td>
        {props.loadingSub ? (
          <span>Loading....</span>
        ) : (
          <Input
            type="select"
            value={props.data.to_player_account_id}
            onChange={(e) => {
              let tmp = _.cloneDeep(props.newTransfers);
              tmp[props.index].to_player_account_id = e.target.value;
              props.oCTransferHOC("newTransfers", tmp);
              // updateData({...data, to_player_account_id: e.target.value });
            }}
          >
            <option></option>
            {props.playerAccountsSub.map((item) => {
              return (
                <option key={`PlayerAccount-${item.id}`} value={item.id}>
                  {item.game_platform_username}
                </option>
              );
            })}
          </Input>
        )}
      </td>
      <td className="d-flex">
        {props.onLoadTransfers ? (
          <span>Loading...</span>
        ) : (
          <>
            <Button
              color="primary"
              className="btn-icon btn-icon-only mr-2"
              onClick={() => {
                props.createTransfer({
                  ...props.data,
                  amount: parseInt(props.data.amount, 10),
                  from_player_account_id: parseInt(
                    props.data.from_player_account_id,
                    10
                  ),
                  to_player_account_id: parseInt(
                    props.data.to_player_account_id,
                    10
                  ),
                });
              }}
            >
              <i className="pe-7s-check" />
            </Button>
            <Button
              color="danger"
              className="btn-icon btn-icon-only mr-2"
              onClick={() => {
                props.removeRow();
              }}
            >
              <i className="pe-7s-trash" />
            </Button>
          </>
        )}
      </td>
    </tr>
  );
};

export default WithPlayerAccounts(NewRow);
