import React from "react";
import PageTitle from "components/Title";
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardHeader,
  Input,
  CardFooter,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import _ from "lodash";
import { compose } from "redux";
import { toast } from "react-toastify";

import ModalNewWithdrawal from "./NewWithdrawal";
import TableTransactionResult from "./TransactionResult";
import TableWithdrawalResult from "./WithdrawalResult";
import LoadingIndicator from "components/Indicator/LoadingOverlay";

import WithBankUsers from "../BankUsers/actions";
import WithGamePlatformUsers from "../GamePlatformUsers/actions";
import WithPlayers from "../Players/actions";
import WithBanks from "../Banks/actions";
import WithPlayerAccounts from "../Players/actions/PlayerAccounts";
import WithWithdrawal from "./actions";
import WithGamePlatforms from "../GamePlatforms/actions";
import WithUsers from "../Users/actions";
import ModalUpdateOriginBank from "./UpdateOriginBank";

import WithChangeBank from "./actions/withChangeBank";

let tmpSetInterval = null;

const soundUrl = "/withdrawal_effects.mp3";

let audio = new Audio(soundUrl);

class Withdrawals extends React.Component {
  state = {
    newWithdrawals: [],
    newWithdrawal: {
      bank_user_id: 0,
      game_platform_user_id: 0,
      amount: 0,
      player_bank_id: 0,
      player_account_id: 0,
      player_bank_account_name: "",
    },
    disableCreateIndexes: [],
    errorCreateIndexes: [],
    refreshTime: 5,
    search: {
      "q[user_username_eq]": "",
      "q[player_account_player_slug_eq]": "",
      "q[player_account_game_platform_name_cont]": "",
      "q[player_account_game_platform_username_eq]": "",
      "q[player_bank_account_holder_name_cont]": "",
      "q[player_bank_account_bank_name_eq]": "",
      "q[player_bank_account_account_number_cont]": "",
      "q[status_eq]": null,
      page: 1,
    },
    play: false,
  };

  componentDidMount = () => {
    this.props.getBankUsersFilterUserID(this.props.profile.id);
    this.props.getBanks();
    this.props.getWithdrawals();
    this.props.getSuccessWithdrawals(this.state.search);
    this.props.getGamePlatforms();
    this.props.getUsersWithRoleStaffs();

    tmpSetInterval = setInterval(() => {
      this.props.getWithdrawals();
      this.props.getSuccessWithdrawals(this.state.search);
    }, this.state.refreshTime * 1000);

    audio.addEventListener("ended", () => this.setState({ play: false }));
  };

  componentWillUnmount = () => {
    clearInterval(tmpSetInterval);
    audio.removeEventListener("ended", () => this.setState({ play: false }));
  };

  componentDidUpdate = (pp) => {
    console.log(this.props.withdrawals);
    if (this.props.withdrawals.length > 0) {
      audio.play();
    }
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Withdrawals"
          subheading="Place to perform withdrawals for the players."
          icon="pe-7s-cash icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: "primary",
              content: "Add withdrawal",
              onClick: () => {
                this.props.oCWithdrawHOC("showNewWithdrawal", true);
              },
            },
          ]}
        />
        <ModalNewWithdrawal
          {...this.props}
          onClose={() => this.props.oCWithdrawHOC("showNewWithdrawal", false)}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardHeader>Change refresh time(seconds)</CardHeader>
              <CardBody>
                <Input
                  type="number"
                  value={this.state.refreshTime}
                  onChange={(e) => {
                    this.setState({ refreshTime: e.target.value });
                  }}
                />
              </CardBody>
              <CardFooter>
                <Button
                  color="primary"
                  className="mr-2"
                  onClick={() => {
                    clearInterval(tmpSetInterval);
                    tmpSetInterval = setInterval(() => {
                      this.props.getWithdrawals();
                      this.props.getSuccessWithdrawals();
                    }, this.state.refreshTime * 1000);
                    toast.success("Successfully set new reload time.");
                  }}
                >
                  Submit
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    clearInterval(tmpSetInterval);
                    toast.success("Successfully disable refresh.");
                  }}
                >
                  Stop refresh
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardHeader>Transaction result</CardHeader>
              <CardBody>
                <TableTransactionResult
                  profile={this.props.profile}
                  withdrawals={this.props.withdrawals}
                  approveWithdrawal={this.props.approveWithdrawal}
                  rejectWithdrawal={this.props.rejectWithdrawal}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardHeader>Withdrawal result</CardHeader>
              <CardBody>
                <Card className="mb-1">
                  <CardBody>
                    <Row>
                      <Col md={3}>
                        <FormGroup>
                          <Label>Agent ID</Label>
                          <Input
                            type="text"
                            name="q[user_username_eq]"
                            value={this.state.search["q[user_username_eq]"]}
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[user_username_eq]"] = e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <Label>Player ID</Label>
                        <Input
                          type="text"
                          name="q[player_account_player_slug_eq]"
                          value={
                            this.state.search[
                              "q[player_account_player_slug_eq]"
                            ]
                          }
                          onChange={(e) => {
                            let tmp = _.cloneDeep(this.state.search);
                            tmp["q[player_account_player_slug_eq]"] =
                              e.target.value;
                            return this.setState({ search: tmp });
                          }}
                        />
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>Game Platform</Label>
                          <Input
                            type="select"
                            name="q[game_platform_user_game_platform_name_cont]"
                            value={
                              this.state.search[
                                "q[game_platform_user_game_platform_name_cont]"
                              ]
                            }
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp[
                                "q[game_platform_user_game_platform_name_cont]"
                              ] = e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          >
                            <option></option>
                            {this.props.games.map((item) => (
                              <option
                                key={`GamePlatform-${item.id}`}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label>Game ID</Label>
                          <Input
                            type="text"
                            name="q[player_account_game_platform_username_eq]"
                            value={
                              this.state.search[
                                "q[player_account_game_platform_username_eq]"
                              ]
                            }
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp[
                                "q[player_account_game_platform_username_eq]"
                              ] = e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Player Bank Account Holder</Label>
                          <Input
                            type="text"
                            name="q[player_bank_account_holder_name_cont]"
                            value={
                              this.state.search[
                                "q[player_bank_account_holder_name_cont]"
                              ]
                            }
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[player_bank_account_holder_name_cont]"] =
                                e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Player Bank Name</Label>
                          <Input
                            type="select"
                            name="q[player_bank_account_bank_name_eq]"
                            value={
                              this.state.search[
                                "q[player_bank_account_bank_name_eq]"
                              ]
                            }
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp["q[player_bank_account_bank_name_eq]"] =
                                e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          >
                            <option></option>
                            {this.props.banks.map((item) => {
                              return (
                                <option key={item.name} value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Player Bank Account Number</Label>
                          <Input
                            type="text"
                            name="q[player_bank_account_account_number_cont]"
                            value={
                              this.state.search[
                                "q[player_bank_account_account_number_cont]"
                              ]
                            }
                            onChange={(e) => {
                              let tmp = _.cloneDeep(this.state.search);
                              tmp[
                                "q[player_bank_account_account_number_cont]"
                              ] = e.target.value;
                              return this.setState({ search: tmp });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="primary"
                      className="ml-auto"
                      onClick={() => {
                        this.props.getSuccessWithdrawals(this.state.search);
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => {
                        this.setState(
                          {
                            search: {
                              "q[user_username_eq]": "",
                              "q[player_account_player_slug_eq]": "",
                              "q[player_account_game_platform_name_cont]": "",
                              "q[player_account_game_platform_username_eq]": "",
                              "q[player_bank_account_holder_name_cont]": "",
                              "q[player_bank_account_bank_name_eq]": "",
                              "q[player_bank_account_account_number_cont]": "",
                              "q[status_eq]": null,
                              page: 1,
                            },
                          },
                          () => {
                            this.props.getSuccessWithdrawals(this.state.search);
                          }
                        );
                      }}
                    >
                      Reset
                    </Button>
                  </CardFooter>
                </Card>
                <TableWithdrawalResult
                  profile={this.props.profile}
                  successWithdrawals={this.props.successWithdrawals}
                  search={this.state.search}
                  onChangeSearch={(data) => this.setState({ search: data })}
                  getSuccessWithdrawals={this.props.getSuccessWithdrawals}
                  onClickEditBank={(id, bank_user_id) => {
                    this.props.oCWithdrawalId(id, bank_user_id);
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalUpdateOriginBank
          oCOriginBankHOC={this.props.oCOriginBankHOC}
          newChangeOriginBank={this.props.newChangeOriginBank}
          isOpen={this.props.showChangeOriginBank}
          onLoadChangeOriginBank={this.props.onLoadChangeOriginBank}
          toggle={() => {
            this.props.oCOriginBankHOC("showChangeOriginBank", false);
          }}
          updateOriginBank={this.props.updateOriginBank}
          profile={this.props.profile}
        />
        {(this.props.onLoadBankUsers ||
          this.props.onLoadGamePlatformUsers ||
          this.props.onLoadPlayers ||
          this.props.onLoadBanks ||
          this.props.onLoadPlayerAccounts ||
          this.props.onLoadWithdrawals ||
          this.props.onLoadChangeOriginBank) && <LoadingIndicator />}
      </>
    );
  };
}

export default compose(
  WithBankUsers,
  WithGamePlatformUsers,
  WithPlayers,
  WithBanks,
  WithPlayerAccounts,
  WithWithdrawal,
  WithGamePlatforms,
  WithUsers,
  WithChangeBank
)(Withdrawals);
