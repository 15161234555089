import React, { Component } from "react";
import Axios from "axios";
import _ from "lodash";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      newDeposit: {
        bonus_deposit_data: "",
      },
      histories: {
        data: [],
        meta: {
          pages: 1,
          page: 1,
        },
      },
      deposits: {
        data: [],
        meta: {
          pages: 1,
          page: 1,
        },
      },
    };

    oCKioskDepositHOC = (key, val) => this.setState({ [key]: val });

    listDeposits = async (pg) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/bonus_deposits${pg ? `?page=${pg}` : ``}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          deposits: response.data,
        });
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.setState({ loading: false });
      }
    };

    getDepositHistories = async (pg) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/invalid_action_histories${
            pg ? `?page=${pg}` : ``
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          histories: response.data,
        });
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.setState({ loading: false });
      }
    };

    postDeposit = async (data, uuid) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/kiosks/bulk_bonus_deposit`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({
          newDeposit: {
            bonus_deposit_data: "",
          },
        });
        toast.success("Deposit is created successfully");
      } catch (error) {
        console.log(error, "error");
        toast.error(error.response.data.errors[0]);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          oCKioskDepositHOC={this.oCKioskDepositHOC}
          postDeposit={this.postDeposit}
          getDepositHistories={this.getDepositHistories}
          listDeposits={this.listDeposits}
          onLoadDeposits={this.state.loading}
          newDeposit={this.state.newDeposit}
          histories={this.state.histories}
          deposits={this.state.deposits}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
