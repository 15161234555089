import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import _ from "lodash";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

const ModalReportDetails = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"xl"}>
      <ModalHeader toggle={props.toggle}>
        View selected report details
      </ModalHeader>
      <ModalBody>
        <Card className="mb-1">
          <CardHeader>Bank account details</CardHeader>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  {/* <th>Bank account number</th> */}
                  <th>Bank name</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Used</th>
                  <th>Total cash in</th>
                  <th>Total cash out</th>
                </tr>
              </thead>
              <tbody>
                {props.selectedReport &&
                  props.selectedReport.bank_account_detail &&
                  props.selectedReport.bank_account_detail.map(
                    (item, index) => {
                      console.log(item, "item");
                      return (
                        <tr key={`Bank-both_bank_account_detail-${index}`}>
                          {/* <td>{item.account_number}</td> */}
                          <td>{`${item.bank_name} - ${item.account_number}`}</td>
                          <td>{item.start_balance}</td>
                          <td>{item.end_balance}</td>
                          <td>{item.used_balance}</td>
                          <td>{item.total_cash_in}</td>
                          <td>{item.total_cash_out}</td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </Table>
          </CardBody>
          <div style={{ paddingRight: "15px" }}>
            <h5
              style={{ textAlign: "right" }}
            >{`Cash in: ${props.selectedReport.total_report_cash_in}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Cash out: ${props.selectedReport.total_report_cash_out}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Total: ${props.selectedReport.total_current_bank_account_detail_balance}`}</h5>
          </div>
        </Card>
        <Card className="mb-1">
          <CardHeader>Game platform</CardHeader>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <th>Game</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Used</th>
                  <th>Kiosk in</th>
                  <th>Kiosk out</th>
                  <th>Burn</th>
                  <th>Tips</th>
                </tr>
              </thead>
              <tbody>
                {props.selectedReport &&
                  props.selectedReport.game_platform &&
                  props.selectedReport.game_platform.map((item, index) => {
                    return (
                      <tr key={`Game-platform-${index}`}>
                        <td>{item.game_platform_name}</td>
                        <td>{item.start_balance}</td>
                        <td>{item.end_balance}</td>
                        <td>{item.used_balance}</td>
                        <td>{item.total_topup}</td>
                        <td>{item.total_refund}</td>
                        <td>{item.total_burn}</td>
                        <td>{item.total_tips}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </CardBody>
          <div style={{ paddingRight: "15px" }}>
            <h5
              style={{ textAlign: "right" }}
            >{`Total kiosk in: ${props.selectedReport.total_report_topup}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Total kiosk out: ${props.selectedReport.total_report_refund}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Total: ${props.selectedReport.total_current_game_platform_balance}`}</h5>
          </div>
        </Card>
        <Card>
          <CardHeader>Promotions</CardHeader>
          <CardBody>
            <Table>
              <thead>
                <tr>
                  <th>Promo name</th>
                  <th>Bonus Amount</th>
                </tr>
              </thead>
              <tbody>
                {props.selectedReport &&
                  props.selectedReport.promotion &&
                  props.selectedReport.promotion.map((item, index) => {
                    return (
                      <tr key={`Promo-${index}`}>
                        <td>{item.promotion_name}</td>
                        <td>{item.bonus_amount}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </CardBody>
          <div style={{ paddingRight: "15px" }}>
            <h5
              style={{ textAlign: "right" }}
            >{`Total: ${props.selectedReport.total_current_promotion_balance}`}</h5>
          </div>
        </Card>
        {/* <Card>
          <Table>
            <thead>
              <tr>
                <th>Bank name</th>
                <th>Start</th>
                <th>End</th>
                <th>Used</th>
              </tr>
            </thead>
            <tbody>
              {props.selectedReport &&
                props.selectedReport.bank_user &&
                props.selectedReport.bank_user.map((item, index) => {
                  return (
                    <tr key={`Bank-user-${index}`}>
                      <td>{item.bank_name}</td>
                      <td>{item.start_balance}</td>
                      <td>{item.end_balance}</td>
                      <td>{item.used_balance}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div style={{ paddingRight: "15px" }}>
            <h5
              style={{ textAlign: "right" }}
            >{`Cash in: ${props.selectedReport.total_cash_in}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Cash out: ${props.selectedReport.total_cash_out}`}</h5>
            <h5
              style={{ textAlign: "right" }}
            >{`Total: ${props.selectedReport.total_bank_user}`}</h5>
          </div>
        </Card> */}
        {/* <Card>
          <Table>
            <thead>
              <tr>
                <th>Game</th>
                <th>Start</th>
                <th>End</th>
                <th>Used</th>
              </tr>
            </thead>
            <tbody>
              {props.selectedReport &&
                props.selectedReport.game_platform_user &&
                props.selectedReport.game_platform_user.map((item, index) => {
                  return (
                    <tr key={`Game-platform-user-${index}`}>
                      <td>{item.game_platform_name}</td>
                      <td>{item.start_balance}</td>
                      <td>{item.end_balance}</td>
                      <td>{item.used_balance}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div style={{ paddingRight: "15px" }}>
            <h5
              style={{ textAlign: "right" }}
            >{`Total: ${props.selectedReport.total_game_platform_user}`}</h5>
          </div>
        </Card> */}
        {/* <Card>
          <Table>
            <thead>
              <tr>
                <th>Promo name</th>
                <th>Bonus Amount</th>
              </tr>
            </thead>
            <tbody>
              {props.selectedReport &&
                props.selectedReport.promotion &&
                props.selectedReport.promotion.map((item, index) => {
                  return (
                    <tr key={`Promo-${index}`}>
                      <td>{item.promotion_name}</td>
                      <td>{item.bonus_amount}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div style={{ paddingRight: "15px" }}>
            <h5
              style={{ textAlign: "right" }}
            >{`Total: ${props.selectedReport.total_promotion}`}</h5>
          </div>
        </Card> */}
        <Card>
          <CardBody>
            <h4
              style={{ textAlign: "right" }}
            >{`Total final balance: ${props.selectedReport.total_current_final_balance}`}</h4>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default ModalReportDetails;
