import React from "react";
import { FormGroup, Label, Form, Input, Button } from "reactstrap";
import _ from "lodash";
import z from "zod";
import { toast } from "react-toastify";

const formSchema = z.object({
  register_mode: z.enum(["manual", "auto"]),
  game_platform_username: z.string().min(1, "username is required"),
  password: z.string().min(1, "password is required"),
  game_platform_id: z.number().min(1, "game_platform_id is required"),
});

const CreatePlayerForm = (props) => {
  const { newGameId, updateNewGameId } = React.useState({
    player_id: 0,
    game_platform_username: "",
    password: "",
    game_platform_id: 0,
    register_mode: "manual",
  });
  const [newGameIdErrors, updateNewGameIdErrors] = React.useState([]);

  React.useEffect(() => {
    updateNewGameIdErrors(newGameIdErrors);
  }, [props.newPlayer]);

  return (
    <Form>
      <FormGroup>
        <Label>Player ID</Label>
        <Input
          type="text"
          value={props.newPlayer.player_slug}
          onChange={(e) => {
            let tmp = _.cloneDeep(props.newPlayer);
            tmp.player_slug = e.target.value;
            props.oCPlayerHOC("newPlayer", tmp);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label>Select a game platform</Label>
        <Input
          type="select"
          value={props.newPlayer.game_platform_id}
          onChange={(e) => {
            let tmp = _.cloneDeep(props.newPlayer);
            tmp.game_platform_id = e.target.value;
            props.oCPlayerHOC("newPlayer", tmp);
          }}
        >
          <option></option>
          {props.gamePlatformUsers.map((item) => {
            return (
              <option key={item.id} value={item.game_platform_id}>
                {item.game_platform.name}
              </option>
            );
          })}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>Mode</Label>
        <Input
          type={"select"}
          value={props.newPlayer.register_mode}
          onChange={(e) => {
            let tmp = _.cloneDeep(props.newPlayer);
            tmp.register_mode = e.target.value;
            props.oCPlayerHOC("newPlayer", tmp);
          }}
        >
          <option value="manual">Manual</option>
          <option value="api">Auto</option>
        </Input>
      </FormGroup>
      {props.newPlayer.register_mode === "manual" ? (
        <>
          <FormGroup>
            <Label>Username</Label>
            <Input
              type="text"
              value={props.newPlayer.game_platform_username}
              onChange={(e) => {
                let tmp = _.cloneDeep(props.newPlayer);
                tmp.game_platform_username = e.target.value;
                props.oCPlayerHOC("newPlayer", tmp);
              }}
            />
            {newGameIdErrors.length > 0 &&
              _.findIndex(newGameIdErrors, {
                path: ["game_platform_username"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    newGameIdErrors[
                      _.findIndex(newGameIdErrors, {
                        path: ["game_platform_username"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type="text"
              value={props.newPlayer.password}
              onChange={(e) => {
                let tmp = _.cloneDeep(props.newPlayer);
                tmp.password = e.target.value;
                props.oCPlayerHOC("newPlayer", tmp);
              }}
            />
            {newGameIdErrors.length > 0 &&
              _.findIndex(newGameIdErrors, {
                path: ["password"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    newGameIdErrors[
                      _.findIndex(newGameIdErrors, {
                        path: ["password"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
        </>
      ) : (
        <>
          <FormGroup>
            <Label>Username</Label>
            <Input type="text" disabled={true} />
            <span>
              System will auto create username upon connected to the game.
            </span>
          </FormGroup>
          <FormGroup>
            <Label>Password</Label>
            <Input
              type="text"
              value={props.newPlayer.password}
              onChange={(e) => {
                let tmp = _.cloneDeep(props.newPlayer);
                tmp.password = e.target.value;
                props.oCPlayerHOC("newPlayer", tmp);
              }}
            />
            {newGameIdErrors.length > 0 &&
              _.findIndex(newGameIdErrors, {
                path: ["password"],
              }) > -1 && (
                <span className="text-danger">
                  {
                    newGameIdErrors[
                      _.findIndex(newGameIdErrors, {
                        path: ["password"],
                      })
                    ].message
                  }
                </span>
              )}
          </FormGroup>
        </>
      )}
      <Button
        className="ml-auto"
        color="primary"
        onClick={() => {
          if (props.newPlayer.game_platform_id === "") {
            alert("Please select a game platform.");
          } else {
            let tmp = {
              ...props.newPlayer,
              game_platform_id: parseInt(props.newPlayer.game_platform_id, 10),
            };
            if (props.newPlayer.register_mode === "manual") {
              let tmpErr = formSchema.safeParse(tmp);
              if (!tmpErr.success) {
                console.dir(tmpErr);
                updateNewGameIdErrors(tmpErr.error.issues);
              } else {
                // call api
                props.postPlayer(props.newPlayer);
              }
            } else {
              if (props.newPlayer.password.length <= 6) {
                toast.error("Password must be at least 6 characters long");
              } else {
                // call api
                props.postPlayer(props.newPlayer);
              }
            }
          }
        }}
      >
        Submit
      </Button>
    </Form>
  );
};

export default CreatePlayerForm;
