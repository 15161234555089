import { HashRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import AppRouter from "./router";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <HashRouter>
        <AppRouter />
      </HashRouter>
      <ToastContainer
        position={toast.POSITION.BOTTOM_RIGHT}
        toastStyle={{
          color: "#fff",
        }}
      />
    </>
  );
}

export default App;
