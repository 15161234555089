import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      playerAccounts: [],
      newPlayerAccount: {
        player_id: 0,
        game_platform_username: "string",
        game_platform_id: 0,
        register_mode: "string",
        password: "string",
      },
      showPlayerAccountChangePassword: false,
    };

    oCPlayerAccountsHOC = (key, value) => this.setState({ [key]: value });

    getPlayerAccounts = async (player_id) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/player_accounts?q[player_id_eq]=${player_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ playerAccounts: response.data.data });
      } catch (error) {
        toast.error("Error fetching player accounts");
      } finally {
        this.setState({ loading: false });
      }
    };

    getPlayerAccountsViaSlug = async (player_id) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/player_accounts?q[player_slug_eq]=${player_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ playerAccounts: response.data.data });
      } catch (error) {
        toast.error("Error fetching player accounts");
      } finally {
        this.setState({ loading: false });
      }
    };

    postPlayerAccount = async (data, selectedPlayer) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/player_accounts`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.getPlayerAccounts(selectedPlayer.id);
      } catch (error) {
        toast.error("Error posting player account");
      } finally {
        this.setState({ loading: false });
      }
    };

    deletePlayerAccount = async (id, selectedPlayer) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.delete(
          `${STAGING_BACKEND_URL}/player_accounts/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.getPlayerAccounts(selectedPlayer.id);
        toast.success("Player account removed successfully");
      } catch (error) {
        toast.error("Error set player account to inactive");
      } finally {
        this.setState({ loading: false });
      }
    };

    enablePlayerAccount = async (id, selectedPlayer) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/player_accounts/${id}/enable`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.getPlayerAccounts(selectedPlayer.id);
      } catch (error) {
        toast.error("Error set player account to active");
      } finally {
        this.setState({ loading: false });
      }
    };

    disablePlayerAccount = async (id, selectedPlayer) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/player_accounts/${id}/disable`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.getPlayerAccounts(selectedPlayer.id);
      } catch (error) {
        toast.error("Error set player account to inactive");
      } finally {
        this.setState({ loading: false });
      }
    };

    changePlayerAccountPassword = async (
      id,
      new_password,
      password_confirmation,
      selectedPlayer
    ) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.put(
          `${STAGING_BACKEND_URL}/player_accounts/${id}/change_password`,
          {
            new_password,
            password_confirmation,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ showPlayerAccountChangePassword: false });
        this.getPlayerAccounts(selectedPlayer.id);
      } catch (error) {
        toast.error("Error changing player account password");
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          oCPlayerAccountsHOC={this.oCPlayerAccountsHOC}
          getPlayerAccounts={this.getPlayerAccounts}
          postPlayerAccount={this.postPlayerAccount}
          getPlayerAccountsViaSlug={this.getPlayerAccountsViaSlug}
          deletePlayerAccount={this.deletePlayerAccount}
          enablePlayerAccount={this.enablePlayerAccount}
          disablePlayerAccount={this.disablePlayerAccount}
          changePlayerAccountPassword={this.changePlayerAccountPassword}
          onLoadPlayerAccounts={this.state.loading}
          playerAccounts={this.state.playerAccounts}
          showPlayerAccountChangePassword={
            this.state.showPlayerAccountChangePassword
          }
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
