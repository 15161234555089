import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import _ from "lodash";
import { z } from "zod";
import { toast } from "react-toastify";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

const CreateWithdrawModal = (props) => {
  const [amt, updateAmt] = React.useState("0");
  React.useEffect(() => {
    updateAmt("0");
  }, [props.isOpen]);
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"md"}>
      <ModalHeader toggle={props.toggle}>Create withdraw</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Amount</Label>
            <Input
              type="number"
              value={amt}
              min="0"
              onChange={(e) => {
                updateAmt(e.target.value);
              }}
            />
          </FormGroup>
        </Form>
        {props.onLoadGamePlatformUsers && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            let tmp = parseFloat(amt);
            if (tmp <= 0) {
              toast.error("Amount must be greater than 0");
            } else {
              props.createWithdraw(props.gamePlatformUser.id, tmp);
            }
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateWithdrawModal;
