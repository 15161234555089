import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import _ from "lodash";
import { z } from "zod";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

const formSchema = z.object({
  // account_number: z.string().min(1, "Account number is required"),
});

const UpdateBankUsers = (props) => {
  const [errors, updateErrors] = React.useState([]);

  const onChangeBUData = (key, value) => {
    let tmp = _.cloneDeep(props.bankUser);
    tmp[key] = value;
    return props.oCBankUsersHOC("bankUser", tmp);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size={"md"}>
      <ModalHeader toggle={props.toggle}>Update kiosk balance</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Staff</Label>
            <Input
              type="select"
              value={props.bankUser.user_id}
              onChange={(e) => {
                onChangeBUData("user_id", e.target.value);
              }}
            >
              {props.staffs.map((user) => {
                return (
                  <option key={`Staff${user.id}`} value={user.id}>
                    {user.username}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          {/* <FormGroup>
            <Label>Bank</Label>
            <Input
              type="select"
              value={props.bankUser.bank_account_detail.bank_id}
              onChange={(e) => {
                onChangeBUData("bank_id", e.target.value);
              }}
            >
              {props.banks.map((bank) => {
                return (
                  <option key={`Bank${bank.id}`} value={bank.id}>
                    {bank.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup> */}
          <FormGroup>
            <Label>Assign bank</Label>
            <Input
              type="select"
              value={props.bankUser.bank_account_detail_id}
              onChange={(e) => {
                onChangeBUData("bank_account_detail_id", e.target.value);
              }}
            >
              <option></option>
              {props.bankAccountDetailsAll.map((bank) => {
                return (
                  <option key={`bank-${bank.id}`} value={bank.id}>
                    {`${bank.bank_name} ${bank.account_number}`}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          {/* <FormGroup>
            <Label>Account number</Label>
            <Input
              type="text"
              value={props.bankUser.bank_account_detail.account_number}
              onChange={(e) => {
                onChangeBUData("account_number", e.target.value);
              }}
            />
          </FormGroup> */}
          {/* <FormGroup>
            <Label>Starting amount</Label>
            <Input
              type="number"
              value={props.bankUser.bank_account_detail.starting_amount}
              disabled={true}
              min="0"
            />
          </FormGroup> */}
          <FormGroup>
            <Label>Status</Label>
            <Input
              type="select"
              value={props.bankUser.status}
              onChange={(e) => {
                onChangeBUData("status", e.target.value);
              }}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
          </FormGroup>
        </Form>
        {props.onLoadBankUsers && <LoadingOverlay />}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            let tmpErr = formSchema.safeParse(props.bankUser);
            if (!tmpErr.success) {
              updateErrors(tmpErr.error.issues);
            } else {
              // call api
              props.patchBankUser({
                id: props.bankUser.id,
                bank_account_detail_id: props.bankUser.bank_account_detail_id,
                user_id: props.bankUser.user_id,
                status: props.bankUser.status,
                remark: props.bankUser.remark,
              });
            }
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateBankUsers;
