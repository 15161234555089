import React from "react";
import ReactTable from "react-table";
import { Button } from "reactstrap";
import Moment from "moment";

import PromptModal from "../../../components/Indicator/Prompt";

const TableGamePlatform = (props) => {
  const [showConfirmDeleteGamePlatform, updateShowConfirmDeleteGamePlatform] =
    React.useState(false);
  const [selectedGamePlatformId, updateSelectedGamePlatformId] =
    React.useState(null);
  return (
    <>
      <ReactTable
        data={props.playerAccounts}
        pageSize={props.playerAccounts.length}
        columns={[
          {
            Header: "Game platform",
            accessor: "game_platform.name",
          },
          {
            Header: "Game username",
            accessor: "game_platform_username",
          },
          {
            Header: "Password",
            accessor: "decrypted_game_platform_password",
          },
          {
            Header: "Total deposits",
            accessor: "total_deposits",
          },
          {
            Header: "Total withdrawal",
            accessor: "total_withdrawal",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Created at",
            Cell: (row) => (
              <span>
                {Moment(row.original.created_at).format("YYYY-MM-DD HH:mm")}
              </span>
            ),
          },
          {
            Header: "Actions",
            Cell: (row) => {
              return (
                <>
                  <Button
                    color="danger"
                    className="mr-2"
                    onClick={() => {
                      updateShowConfirmDeleteGamePlatform(true);
                      updateSelectedGamePlatformId(row.original.id);
                    }}
                  >
                    <i className="pe-7s-trash btn-icon-wrapper" />
                  </Button>
                  {row.original.status === "active" && (
                    <Button
                      color="warning"
                      className="mr-2"
                      onClick={() => {
                        props.disablePlayerAccount(
                          row.original.id,
                          props.selectedPlayer
                        );
                      }}
                    >
                      Disable
                    </Button>
                  )}
                  {row.original.status !== "active" && (
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={() => {
                        props.enablePlayerAccount(
                          row.original.id,
                          props.selectedPlayer
                        );
                      }}
                    >
                      Enable
                    </Button>
                  )}
                  <br />
                  <Button
                    color="primary"
                    onClick={() => {
                      Promise.all([
                        props.updateSelectedPlayerAccount(row.original),
                      ]).then(() => {
                        props.oCPlayerAccountsHOC(
                          "showPlayerAccountChangePassword",
                          true
                        );
                      });
                    }}
                  >
                    Change password
                  </Button>
                </>
              );
            },
          },
        ]}
      />
      <PromptModal
        showPromptModal={showConfirmDeleteGamePlatform}
        onClickYes={() => {
          props.deletePlayerAccount(
            selectedGamePlatformId,
            props.selectedPlayer
          );
        }}
        onClickNo={() => {
          updateShowConfirmDeleteGamePlatform(false);
        }}
        content={"Are you sure to remove the selected game platform?"}
      />
    </>
  );
};

export default TableGamePlatform;
