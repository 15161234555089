import React from "react";
import { Input, Button } from "reactstrap";
import _ from "lodash";
import { compose } from "redux";

import WithPlayerAccounts from "./actions/playerAccounts";
import WithDeposits from "./actions/withDeposit";

const NewRow = (props) => {
  const [formFields, updateFormFields] = React.useState({
    game_platform_id: 0,
    bank_user_id: props.item.bank_user_id,
    player_id: "",
    promotion_id: 0,
    is_flexible_amount: false,
    bonus_amount: "",
    deposit_amount: "",
    user_id: props.selectedStaffID,
  });
  const [errorFields, updateErrorFields] = React.useState([]);
  const [disabledSubmit, updateDisabledSubmit] = React.useState(false);

  React.useEffect(() => {
    // updateFormFields(props.item);
    // updateDisabledSubmit(true);
    props.getBankUsersFilterUserID(parseInt(formFields.user_id, 10));
  }, [props.item]);

  React.useEffect(() => {
    if (errorFields.length > 0) {
      updateDisabledSubmit(true);
    } else {
      updateDisabledSubmit(false);
      if (props.playerAccounts.length === 0) {
        updateDisabledSubmit(true);
      }
    }
  }, [errorFields]);

  React.useEffect(() => {
    if (props.playerAccounts.length === 0) {
      updateDisabledSubmit(true);
    } else {
      let tmp = _.cloneDeep(formFields);
      tmp.player_account_id = props.playerAccounts[0].id;
      updateFormFields(tmp);
    }
  }, [props.playerAccounts]);

  const countPromoAmount = (promo_id, tmp) => {
    let tmpPromo = _.find(props.promotions, {
      id: parseInt(promo_id, 10),
    });
    let tmpDiscount =
      tmpPromo.claimable_type === "amount"
        ? parseFloat(tmpPromo.claimable_amount)
        : tmpPromo.claimable_percent / 100;
    tmp.promotion_id = parseInt(promo_id, 10);
    tmp.bonus_amount =
      tmpPromo.claimable_type === "amount"
        ? parseFloat(tmpDiscount)
        : parseFloat(tmp.deposit_amount) * parseFloat(tmpDiscount);
    tmp.bonus_amount = tmp.bonus_amount.toFixed(0);
    if (tmp.bonus_amount > parseFloat(tmpPromo.max_capacity)) {
      tmp.bonus_amount = tmpPromo.max_capacity;
    }
    return tmp;
  };

  return (
    <tr key={props.key}>
      <td>
        {props.profile.role === "staff" && (
          <Input
            style={{ width: "80px" }}
            className="form-control"
            value={props.profile.username}
            disabled={true}
          />
        )}
        {props.profile.role !== "staff" && (
          <Input
            type="select"
            className="form-control"
            value={formFields.user_id}
            onChange={(e) => {
              let tmp = _.cloneDeep(formFields);
              tmp.user_id = e.target.value;
              updateFormFields(tmp);
              props.getBankUsersFilterUserID(parseInt(tmp.user_id, 10));
            }}
            disabled={true}
          >
            {props.staffs.map((item) => {
              return (
                <option key={`Staff-${item.id}`} value={item.id}>
                  {item.username}
                </option>
              );
            })}
          </Input>
        )}
      </td>
      <td>
        <Input
          type="text"
          className="form-control"
          value={formFields.player_id}
          onChange={(e) => {
            let tmp = _.cloneDeep(formFields);
            tmp.player_id = e.target.value;
            updateFormFields(tmp);
          }}
          disabled={props.onLoadDeposits}
        />
      </td>
      <td>
        <Input
          type="select"
          className="form-control"
          value={formFields.game_platform_id}
          onChange={(e) => {
            let tmp = _.cloneDeep(formFields);
            tmp.game_platform_id = e.target.value;
            updateFormFields(tmp);
            props.findPlayerAccounts(tmp.player_id, tmp.game_platform_id);
          }}
          disabled={props.onLoadDeposits}
        >
          <option></option>
          {props.gamePlatformsFilteredUserID.map((item) => (
            <option
              key={`GamePlatform-${item.id}`}
              value={item.game_platform.id}
            >
              {item.game_platform.name}
            </option>
          ))}
        </Input>
      </td>
      <td>
        {props.onLoadPlayerAccounts ? (
          <p>Loading.....</p>
        ) : (
          <Input
            type="select"
            value={formFields.player_account_id}
            onChange={(e) => {
              let tmp = _.cloneDeep(formFields);
              tmp.player_account_id = e.target.value;
              updateFormFields(tmp);
            }}
            disabled={props.onLoadDeposits}
          >
            <option></option>
            {props.playerAccounts.map((item) => (
              <option key={`PlayerAccount-${item.id}`} value={item.id}>
                {item.game_platform_username}
              </option>
            ))}
          </Input>
        )}
      </td>
      <td>
        <Input
          type="number"
          style={{ width: "80px" }}
          className="form-control"
          value={formFields.deposit_amount.toString()}
          disabled={props.onLoadDeposits}
          min="0"
          onChange={(e) => {
            let tmp = _.cloneDeep(formFields);
            tmp.deposit_amount = e.target.value;
            if (
              e.target.value <= 0 &&
              errorFields.indexOf("deposit_amount") === -1
            ) {
              let tmpErrorFields = _.cloneDeep(errorFields);
              tmpErrorFields.push("deposit_amount");
              updateErrorFields(tmpErrorFields);
            } else {
              let tmpErrorFields = _.cloneDeep(errorFields);
              _.remove(tmpErrorFields, "deposit_amount");
              tmpErrorFields.splice(
                tmpErrorFields.indexOf("deposit_amount"),
                1
              );
              updateErrorFields(tmpErrorFields);
              if (formFields.promotion_id > 0) {
                tmp = countPromoAmount(formFields.promotion_id, tmp);
              }
            }
            updateFormFields(tmp);
          }}
        />
        {formFields.amount <= 0 && (
          <span className="text-danger">
            Cannot have amount less than or equals to 0.
          </span>
        )}
      </td>
      <td>
        <Input
          type="select"
          className="form-control"
          value={formFields.promotion_id}
          disabled={formFields.deposit_amount === 0 || props.onLoadDeposits}
          onChange={(e) => {
            let tmp = _.cloneDeep(formFields);
            if (e.target.value !== "") {
              let tmpFoundPromo = _.find(props.promotions, {
                id: parseInt(e.target.value, 10),
              });
              if (!tmpFoundPromo.is_flexible_amount) {
                tmp = countPromoAmount(e.target.value, tmp);
                tmp.is_flexible_amount = false;
              } else {
                tmp.is_flexible_amount = true;
              }
              tmp.promotion_id = parseInt(e.target.value, 10);
            } else {
              tmp.promotion_id = 0;
              tmp.bonus_amount = 0;
            }
            updateFormFields(tmp);
          }}
        >
          <option value={""}></option>
          {props.promotions.map((item) => (
            <option key={`Promotion-${item.id}`} value={item.id}>
              {item.name}
            </option>
          ))}
        </Input>
      </td>
      <td>
        {/* auto compute */}
        {formFields.is_flexible_amount ? (
          <Input
            className="form-control"
            value={formFields.bonus_amount}
            style={{ width: "80px" }}
            disabled={false}
            type={"number"}
            placeholder="Key in promo amount here"
            onChange={(e) => {
              let tmp = _.cloneDeep(formFields);
              tmp.bonus_amount = e.target.value;
              updateFormFields(tmp);
            }}
          />
        ) : (
          <Input
            className="form-control"
            value={formFields.bonus_amount}
            style={{ width: "80px" }}
            disabled={true}
            type={"number"}
          />
        )}
      </td>
      <td>
        <Input
          type="select"
          className="form-control"
          value={formFields.bank_user_id}
          onChange={(e) => {
            let tmp = _.cloneDeep(formFields);
            tmp.bank_user_id = e.target.value;
            updateFormFields(tmp);
          }}
          disabled={true}
        >
          {props.bankUsersFilteredUserID.map((bankUser) => {
            return (
              <option key={bankUser.id} value={bankUser.id}>
                {bankUser.bank_account_detail.bank_name}
              </option>
            );
          })}
        </Input>
      </td>
      <td>
        <Button
          color="primary"
          className="btn-icon btn-icon-only"
          disabled={props.onLoadDeposits}
          onClick={() => {
            // check data on submit
            // submit data and remove this row from the data lists
            props.postDeposit(
              {
                ...formFields,
                game_platform_id: parseFloat(formFields.game_platform_id),
                bank_user_id: parseFloat(formFields.bank_user_id),
                player_id: formFields.player_id,
                player_account_id: parseFloat(formFields.player_account_id),
                promotion_id: parseFloat(formFields.promotion_id),
                bonus_amount: parseFloat(formFields.bonus_amount),
                deposit_amount: parseFloat(formFields.deposit_amount),
                user_id: parseFloat(formFields.user_id),
              },
              props.item.uuid
            );
          }}
        >
          <i className="pe-7s-cloud-upload btn-icon-wrapper" />
        </Button>
        <Button
          color="danger"
          className="btn-icon btn-icon-only"
          disabled={props.onLoadDeposits}
          onClick={() => {
            props.removeRow();
          }}
        >
          <i className="pe-7s-trash btn-icon-wrapper" />
        </Button>
      </td>
    </tr>
  );
};

export default compose(WithPlayerAccounts, WithDeposits)(NewRow);
