import React, { Component } from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      stats: {
        bank_balance: [],
        kiosk_balance: [],
        total_sales: {
          total_new_player: 0,
        },
        pending_withdrawal: {},
      },
    };

    getDashboardStats = async (search) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/dashboard${
            search
              ? `?start_date=${search.start_date}&end_date=${search.end_date}`
              : ""
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ stats: response.data });
      } catch (error) {
        toast.error("Error fetching dashboard stats data.");
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadStats={this.state.loading}
          stats={this.state.stats}
          getDashboardStats={this.getDashboardStats}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
