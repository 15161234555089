import React from "react";
import PageTitle from "components/Title";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import _ from "lodash";
import ReactTable from "react-table";
import { z } from "zod";
import { compose } from "redux";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";
import UpdateGamePlatformUserModal from "./Update";
import TopupGamePlatformModal from "./Topup";
import CreateWithdrawModal from "./Withdraw";

import WithGamePlatformUsers from "./actions";
import WithGames from "../GamePlatforms/actions";
import WithUsers from "../Users/actions";
import WithTopupGamePlatforms from "./actions/Topup";

const formSchema = z.object({
  user_id: z.number().min(1, "Bank id is required"),
  game_platform_id: z.number().min(1, "Staff id is required"),
  status: z.enum(["active", "inactive"]),
  // starting_amount: z.number().min(0.0, "Starting amount is required"),
});

class GamePlatformUsersContainer extends React.Component {
  state = {
    bankUsersRowsErrors: [],
    showCreateKioskBalance: false,
  };

  componentDidMount = () => {
    this.props.getGamePlatformUsers();
    this.props.getGamePlatforms();
    this.props.getUsersWithRoleStaffs();
  };

  onChangeNewGamePlatformUser = (key, val) => {
    let tmp = _.cloneDeep(this.props.newGamePlatformUser);
    tmp[key] = val;
    this.props.oCGamePlatformUsersHOC("newGamePlatformUser", tmp);
  };

  render = () => {
    return (
      <>
        <PageTitle
          heading="Game platforms assigned to staffs"
          subheading="All kiosk balances - games in the system."
          icon="pe-7s-config icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <Row className="mb-2">
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardHeader
                onClick={() =>
                  this.setState({
                    showCreateGamePlatformUserModal:
                      !this.state.showCreateGamePlatformUserModal,
                  })
                }
              >
                Create new kiosk balance setup - Game
              </CardHeader>
              {this.state.showCreateGamePlatformUserModal && (
                <>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label>Assign game</Label>
                        <Input
                          type="select"
                          value={
                            this.props.newGamePlatformUser.game_platform_id
                          }
                          onChange={(e) => {
                            this.onChangeNewGamePlatformUser(
                              "game_platform_id",
                              parseInt(e.target.value, 10)
                            );
                          }}
                        >
                          <option></option>
                          {this.props.games.map((item) => {
                            return (
                              <option key={`game-${item.id}`} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Input>
                        {this.props.postGamePlatformErrors.length > 0 &&
                          _.findIndex(this.props.postGamePlatformErrors, {
                            path: ["game_platform_id"],
                          }) > -1 && (
                            <span className="text-danger">
                              {
                                this.props.postGamePlatformErrors[
                                  _.findIndex(
                                    this.props.postGamePlatformErrors,
                                    {
                                      path: ["game_platform_id"],
                                    }
                                  )
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Staffs</Label>
                        <Input
                          type="select"
                          value={this.props.newGamePlatformUser.user_id}
                          onChange={(e) => {
                            this.onChangeNewGamePlatformUser(
                              "user_id",
                              parseInt(e.target.value, 10)
                            );
                          }}
                        >
                          <option></option>
                          {this.props.staffs.map((item) => {
                            return (
                              <option key={`staff-${item.id}`} value={item.id}>
                                {item.username}
                              </option>
                            );
                          })}
                        </Input>
                        {this.props.postGamePlatformErrors.length > 0 &&
                          _.findIndex(this.props.postGamePlatformErrors, {
                            path: ["user_id"],
                          }) > -1 && (
                            <span className="text-danger">
                              {
                                this.props.postGamePlatformErrors[
                                  _.findIndex(
                                    this.props.postGamePlatformErrors,
                                    {
                                      path: ["user_id"],
                                    }
                                  )
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup>
                      <FormGroup>
                        <Label>Status</Label>
                        <Input
                          type="select"
                          value={this.props.newGamePlatformUser.status}
                          onChange={(e) => {
                            this.onChangeNewGamePlatformUser(
                              "status",
                              e.target.value
                            );
                          }}
                        >
                          <option value={"active"}>Active</option>
                          <option value={"inactive"}>Inactive</option>
                        </Input>
                        {this.props.postGamePlatformErrors.length > 0 &&
                          _.findIndex(this.props.postGamePlatformErrors, {
                            path: ["status"],
                          }) > -1 && (
                            <span className="text-danger">
                              {
                                this.props.postGamePlatformErrors[
                                  _.findIndex(
                                    this.props.postGamePlatformErrors,
                                    {
                                      path: ["status"],
                                    }
                                  )
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup>
                      {/* <FormGroup>
                        <Label>Starting amount</Label>
                        <Input
                          type="number"
                          value={this.props.newGamePlatformUser.starting_amount}
                          onChange={(e) => {
                            this.onChangeNewGamePlatformUser(
                              "starting_amount",
                              e.target.value
                            );
                          }}
                          min="0"
                        />
                        {this.props.postGamePlatformErrors.length > 0 &&
                          _.findIndex(this.props.postGamePlatformErrors, {
                            path: ["starting_amount"],
                          }) > -1 && (
                            <span className="text-danger">
                              {
                                this.props.postGamePlatformErrors[
                                  _.findIndex(
                                    this.props.postGamePlatformErrors,
                                    {
                                      path: ["starting_amount"],
                                    }
                                  )
                                ].message
                              }
                            </span>
                          )}
                      </FormGroup> */}
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className="ml-auto"
                      color="primary"
                      onClick={() => {
                        let tmp = {
                          ...this.props.newGamePlatformUser,
                          starting_amount: parseFloat(
                            this.props.newGamePlatformUser.starting_amount
                          ),
                        };
                        let tmpErr = formSchema.safeParse(tmp);
                        if (!tmpErr.success) {
                          console.dir(tmpErr);
                          this.props.oCGamePlatformUsersHOC(
                            "postGamePlatformErrors",
                            tmpErr.error.issues
                          );
                        } else {
                          // call api
                          this.props.postGamePlatformUser(
                            this.props.newGamePlatformUser
                          );
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </CardFooter>
                </>
              )}
            </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={10}
                  data={this.props.gamePlatformUsers}
                  columns={[
                    {
                      Header: "Staff username",
                      accessor: "user.username",
                    },
                    {
                      Header: "Game",
                      accessor: "game_platform.name",
                    },
                    {
                      Header: "Total deposits",
                      accessor: "total_deposits",
                    },
                    {
                      Header: "Total withdrawals",
                      accessor: "total_withdrawal",
                    },
                    {
                      Header: "Total bonus",
                      accessor: "total_bonus",
                    },
                    {
                      Header: "Topup",
                      accessor: "topup",
                    },
                    {
                      Header: "Total Transfer In",
                      accessor: "total_transfer_in",
                    },
                    {
                      Header: "Total Transfer Out",
                      accessor: "total_transfer_out",
                    },
                    {
                      Header: "Balance",
                      accessor: "balance",
                    },
                    {
                      Header: "Refund",
                      accessor: "refund",
                    },
                    {
                      Header: "Action",
                      Cell: (row) => (
                        <>
                          <Button
                            color="primary"
                            className="btn-icon btn-icon-only"
                            onClick={() => {
                              this.props.getGamePlatformUser(
                                row.original.id,
                                () => {
                                  this.props.oCGamePlatformUsersHOC(
                                    "showUpdateGamePlatformUserModal",
                                    true
                                  );
                                }
                              );
                            }}
                          >
                            <i className="pe-7s-pen btn-icon-wrapper" />
                          </Button>
                          <Button
                            color="success"
                            className="btn-icon btn-icon-only ml-2"
                            onClick={() => {
                              this.props.oCGamePlatformUsersHOC(
                                "gamePlatformUser",
                                row.original
                              );
                              this.props.oCGamePlatformTopupHOC(
                                "showTopupModal",
                                true
                              );
                            }}
                          >
                            <i className="pe-7s-plus btn-icon-wrapper" />
                          </Button>
                          <Button
                            color="success"
                            className="btn-icon btn-icon-only ml-2"
                            onClick={() => {
                              Promise.all([
                                this.props.oCGamePlatformUsersHOC(
                                  "gamePlatformUser",
                                  row.original
                                ),
                              ]).then(() => {
                                this.props.oCGamePlatformUsersHOC(
                                  "showCreateWithdraw",
                                  true
                                );
                              });
                            }}
                          >
                            <i className="pe-7s-download btn-icon-wrapper" />
                          </Button>
                        </>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <UpdateGamePlatformUserModal
          isOpen={this.props.showUpdateGamePlatformUserModal}
          toggle={() =>
            this.props.oCGamePlatformUsersHOC(
              "showUpdateGamePlatformUserModal",
              false
            )
          }
          gamePlatformUser={this.props.gamePlatformUser}
          patchGamePlatformUser={this.props.patchGamePlatformUser}
          games={this.props.games}
          staffs={this.props.staffs}
          oCGamePlatformUsersHOC={this.props.oCGamePlatformUsersHOC}
        />
        <TopupGamePlatformModal
          onLoadTopupGamePlatform={this.props.onLoadTopupGamePlatform}
          gamePlatformUser={this.props.gamePlatformUser}
          isOpen={this.props.showTopupModal}
          topupGamePlatform={this.props.topupGamePlatform}
          toggle={() => {
            this.props.oCGamePlatformTopupHOC("showTopupModal", false);
          }}
        />
        <CreateWithdrawModal
          isOpen={this.props.showCreateWithdraw}
          onLoadGamePlatformUsers={this.props.onLoadGamePlatformUsers}
          gamePlatformUser={this.props.gamePlatformUser}
          toggle={() => {
            this.props.oCGamePlatformUsersHOC("showCreateWithdraw", false);
          }}
          createWithdraw={this.props.createWithdraw}
        />
        {(this.props.onLoadGamePlatformUsers ||
          this.props.onLoadGames ||
          this.props.onLoadUsers) && <LoadingOverlay />}
      </>
    );
  };
}

export default compose(
  WithGamePlatformUsers,
  WithGames,
  WithUsers,
  WithTopupGamePlatforms
)(GamePlatformUsersContainer);
