import React from "react";
import PageTitle from "components/Title";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Table,
  FormGroup,
  Label,
  Input,
  Badge,
} from "reactstrap";
import ReactTable from "react-table";
import Moment from "moment";
import { compose } from "redux";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import ModalViewAdjustment from "./View";
import ModalUploadAdjustment from "./Upload";

import WithAdjustments from "./actions";
import WithBanks from "../Banks/actions";
import WithPromotions from "../Promotions/actions";
import WithGamePlatforms from "../GamePlatforms/actions";
import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";

import "./index.css";

class AdjustmentsListings extends React.Component {
  state = {
    selectedAdjustment: {
      failing_payload: {
        deposits: [],
        withdrawals: [],
      },
    },
    showSelectedAdjustment: false,
  };

  componentDidMount = () => {
    this.props.getAdjustments();
    this.props.getBanks();
    this.props.getPromotions();
    this.props.getGamePlatforms();
  };

  render() {
    return (
      <>
        <PageTitle
          heading="Adjustments"
          subheading="Place to store all adjustments in the system."
          icon="pe-7s-repeat icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: "primary",
              content: "Upload excel",
              className: "mr-2",
              onClick: () => {
                this.props.oCAdjustments("showUploadExcel", true);
              },
            },
            {
              color: "success",
              content: "Download template",
              onClick: () => {
                window.open(
                  "https://gameport-assets.s3.ap-southeast-1.amazonaws.com/AdjustmentsTemplate.xlsx",
                  "_blank"
                );
              },
            },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={10}
                  data={this.props.adjustments.data}
                  content
                  columns={[
                    {
                      Header: "Created at",
                      Cell: (row) => (
                        <div>
                          {Moment(row.original.created_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </div>
                      ),
                      className: "center",
                    },
                    {
                      Header: "Username",
                      accessor: "user.username",
                    },
                    {
                      Header: "Document",
                      Cell: (row) => (
                        <Button
                          color="success"
                          className="btn-icon btn-icon-only"
                          onClick={() => {
                            window.open(
                              `${STAGING_BACKEND_URL}${row.original.document_link}`,
                              "_blank"
                            );
                          }}
                        >
                          <i className="pe-7s-download btn-icon-wrapper" />
                        </Button>
                      ),
                    },
                    {
                      Header: "Status",
                      Cell: (row) => {
                        if (row.original.status === "partially_failed") {
                          return <Badge color="danger">Partially failed</Badge>;
                        }
                        if (row.original.status === "pending") {
                          return <Badge color="primary">Pending</Badge>;
                        }
                        if (row.original.status === "success") {
                          return <Badge color="success">Success</Badge>;
                        }
                      },
                    },
                    {
                      Header: "Failing stats",
                      Cell: (row) => {
                        return (
                          <div>
                            <p>{`Total deposits count: ${
                              row.original.failing_payload?.deposits
                                ? row.original.failing_payload?.deposits.length
                                : 0
                            }`}</p>
                            <p>{`Total withdrawals count: ${
                              row.original.failing_payload?.withdrawals
                                ? row.original.failing_payload?.withdrawals
                                    .length
                                : 0
                            }`}</p>
                          </div>
                        );
                      },
                    },
                    {
                      Header: "Action",
                      Cell: (row) => {
                        return (
                          <Button
                            color="primary"
                            className="btn-icon btn-icon-only"
                            onClick={() => {
                              this.setState({
                                selectedAdjustment: row.original,
                                showSelectedAdjustment: true,
                              });
                            }}
                          >
                            <i className="pe-7s-search btn-icon-wrapper" />
                          </Button>
                        );
                      },
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalViewAdjustment
          isOpen={this.state.showSelectedAdjustment}
          selectedAdjustment={this.state.selectedAdjustment}
          toggle={() => {
            this.setState({ showSelectedAdjustment: false });
          }}
          {...this.props}
        />
        <ModalUploadAdjustment
          isOpen={this.props.showUploadExcel}
          toggle={() => {
            this.props.oCAdjustments("showUploadExcel", false);
          }}
          uploadAdjustmentExcel={this.props.uploadAdjustmentExcel}
          newAdjustment={this.props.newAdjustment}
          oCAdjustments={this.props.oCAdjustments}
        />
        {(this.props.onLoadAdjustments ||
          this.props.onLoadBanks ||
          this.props.onLoadPromotions ||
          this.props.onLoadGames) && <LoadingOverlay />}
      </>
    );
  }
}

export default compose(
  WithBanks,
  WithAdjustments,
  WithPromotions,
  WithGamePlatforms
)(AdjustmentsListings);
