export const GET_PROFILE = 'GET_PROFILE'

const initialState = {
  profile: {
    roles: [],
    admin_panel_language: "en",
    email: '',
    last_name: "",
    first_name: '',
    status_id: 1
  }
}

export const setUserProfile = payload => ({
  type: GET_PROFILE,
  payload,
})

export default ( state = initialState, action ) => {
  switch( action.type ) {
    case GET_PROFILE: 
      return {
        ...state,
        profile: action.payload
      }
    default: return state
  }
}