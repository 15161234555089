import React, { Component } from "react";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import _ from "lodash";

import { setEnableMobileMenu } from "reducers/ThemeOptions";

import { StaffNav, AdminNav } from "./data";

class VerticalNavWrapper extends Component {
  state = {
    navData: [],
  };

  componentDidMount = () => {
    let tmp = _.cloneDeep(this.state.navData);
    if (
      this.props.profile.role === "admin" ||
      this.props.profile.role === "super_admin"
    ) {
      AdminNav.map((item) => tmp.push(item));
    }
    if (this.props.profile.role === "staff") {
      StaffNav.map((item) => tmp.push(item));
    }
    return this.setState({ navData: tmp });
  };

  render = () => {
    return (
      <>
        <br />
        <MetisMenu
          content={this.state.navData}
          onSelected={() =>
            this.props.setEnableMobileMenu(!this.props.enableMobileMenu)
          }
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </>
    );
  };
}

const mapStateToProps = (state) => ({
  data: state,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

export default connect(mapStateToProps, {
  setEnableMobileMenu,
})(VerticalNavWrapper);
