import React, { Component } from "react";
import Axios from "axios";
import _ from "lodash";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    };

    oCKioskDepositHOC = (key, val) => this.setState({ [key]: val });

    postDeposit = async (data, uuid) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/kiosks/deposit`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        let tmp = _.cloneDeep(this.props.newDeposits);
        _.remove(tmp, { uuid: uuid });
        console.log(tmp, "tmp");
        this.props.oCKioskDepositHOC("newDeposits", tmp);
        this.props.getDeposits(this.props.search);
        toast.success("Deposit is created successfully");
      } catch (error) {
        console.log(error, "error");
        toast.error(error.response.data.errors[0]);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          postDeposit={this.postDeposit}
          oCKioskDepositHOC={this.oCKioskDepositHOC}
          onLoadDeposits={this.state.loading}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
