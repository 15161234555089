import React from "react";
import Axios from "axios";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      loginErrors: [],
    };

    getTestCall = async () => {
      let tmp = await Axios.get(`${STAGING_BACKEND_URL}/ping`, {
        headers: {
          "x-public-key":
            "EojFTsxiDnonhPfHtWp0uHbXX8anS3R0rk6tgS9S8rqKgjs454OnGx3xldqQK",
        },
      });
      if (tmp.status === 200) {
        toast.success("Backend server is up and running");
      }
    };

    login = async (id, password) => {
      this.setState({ loading: true });
      try {
        let tmp = await Axios.post(
          `${STAGING_BACKEND_URL}/login`,
          {
            username: id,
            password,
          },
          {
            headers: {
              "x-public-key": X_PUBLIC_KEY,
            },
          }
        );
        window.localStorage.setItem(
          "GAMEPORT_REFRESH_TOKEN",
          tmp.data.refresh_token
        );
        window.localStorage.setItem("GAMEPORT_ACCESS_TOKEN", tmp.data.token);
        window.localStorage.setItem(
          "GAMEPORT_ACCESS_TOKEN_EXPIRED",
          tmp.data.exp
        );
        window.localStorage.setItem(
          "GAMEPORT_CURRENT_LOGIN_USER_ID",
          tmp.data.user_id
        );
        this.props.history.push("/dashboard/stats");
      } catch (err) {
        console.log(err);
        this.setState({ loginErrors: err.response.data.errors });
      } finally {
        this.setState({ loading: false });
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onLoadLogin={this.state.loading}
          loginErrors={this.state.loginErrors}
          getTestCall={this.getTestCall}
          login={this.login}
        />
      );
    }
  }
  return WithHOC;
};

export default HOC;
