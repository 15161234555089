import React from "react";
import PageTitle from "components/Title";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import _ from "lodash";
import ReactTable from "react-table";
import Moment from "moment";

import CreateUserModal from "./Create";
import UpdateUserModal from "./Update";
import PromptModal from "../../../components/Indicator/Prompt";
import LoadingIndicator from "../../../components/Indicator/LoadingOverlay";

import WithUsers from "./actions";

class UsersContainer extends React.Component {
  state = {
    users: [],
  };

  componentDidMount() {
    this.props.getUsers();
  }

  render = () => {
    return (
      <>
        <PageTitle
          heading="Users"
          subheading="All users in the system."
          icon="pe-7s-user icon-gradient bg-happy-itmeo"
          buttons={[
            {
              color: "primary",
              content: "Add user",
              onClick: () => {
                this.props.oCUsersHOC("showCreateUserModal", true);
              },
            },
          ]}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  pageSize={10}
                  data={this.props.users}
                  columns={[
                    {
                      Header: "Username",
                      accessor: "username",
                    },
                    {
                      Header: "Role",
                      accessor: "role",
                    },
                    {
                      Header: "Parent admin for staff",
                      accessor: "parent.username",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Team ID",
                      accessor: "team_id",
                    },
                    {
                      Header: "Created at",
                      Cell: (row) => (
                        <span>
                          {Moment(row.original.created_at).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </span>
                      ),
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      Cell: (row) => (
                        <>
                          <Button
                            color="primary"
                            className="btn-icon btn-icon-only mr-2"
                            onClick={() => {
                              this.props.getUser(row.original.id, () => {
                                this.props.oCUsersHOC(
                                  "showUpdateUserModal",
                                  true
                                );
                              });
                            }}
                          >
                            <i className="pe-7s-pen btn-icon-wrapper" />
                          </Button>
                          <Button
                            color="danger"
                            className="btn-icon btn-icon-only"
                            onClick={() => {
                              // show confirm delete modal
                              Promise.all([
                                this.props.oCUsersHOC(
                                  "tempDeleteUserID",
                                  row.original.id
                                ),
                              ]).then(() => {
                                this.props.oCUsersHOC("showPromptModal", true);
                              });
                            }}
                          >
                            <i className="pe-7s-trash btn-icon-wrapper" />
                          </Button>
                        </>
                      ),
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CreateUserModal
          isOpen={this.props.showCreateUserModal}
          toggle={() => this.props.oCUsersHOC("showCreateUserModal", false)}
          postUser={this.props.postUser}
          users={this.props.users}
          getUsersWithRoleAdmins={this.props.getUsersWithRoleAdmins}
          admins={this.props.admins}
          profile={this.props.profile}
        />
        <UpdateUserModal
          isOpen={this.props.showUpdateUserModal}
          toggle={() => this.props.oCUsersHOC("showUpdateUserModal", false)}
          patchUser={this.props.patchUser}
          user={this.props.user}
          getUsersWithRoleAdmins={this.props.getUsersWithRoleAdmins}
          admins={this.props.admins}
          profile={this.props.profile}
        />
        <PromptModal
          showPromptModal={this.props.showPromptModal}
          onClickYes={() => {
            this.props.deleteUser(this.props.tempDeleteUserID);
          }}
          onClickNo={() => {
            this.props.oCUsersHOC("showPromptModal", false);
          }}
          content={"Are you sure to remove the user?"}
        />
        {this.props.onLoadUsers && <LoadingIndicator />}
      </>
    );
  };
}

export default WithUsers(UsersContainer);
