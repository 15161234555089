import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import { toast } from "react-toastify";

const UploadAdjustmentModal = (props) => {
  const [files, updateFiles] = React.useState([]);
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Upload Adjustment</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="name">Upload excel</Label>
            <Input
              type="file"
              files={files}
              onChange={(e) => {
                updateFiles(e.target.files);
              }}
              accept=".xlsm,.xls,.xlsx"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            if (files.length > 0) {
              let reader = new FileReader();
              reader.readAsDataURL(files[0]);
              reader.onload = function () {
                props.uploadAdjustmentExcel({
                  data: reader.result,
                  filename: files[0].name,
                  content_type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  identify: false,
                });
              };
              reader.onerror = function (error) {
                console.log("Error: ", error);
              };
            } else {
              toast.error("Please upload excel file");
            }
          }}
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadAdjustmentModal;
