import React from "react";
import ReactTable from "react-table";
import Moment from "moment";
import { Badge, Button } from "reactstrap";
import _ from "lodash";
import Pagination from "components/Pagination";

// reload this table by calling get deposits api every 5s
const TableDeposit = (props) => {
  return (
    <>
      <ReactTable
        data={props.deposits.data}
        pageSize={props.deposits.data.length}
        showPagination={false}
        align="center"
        columns={[
          {
            Header: "Agent/Admin",
            accessor: "game_platform_user.user.username",
          },
          {
            Header: "Bank",
            Cell: (row) => (
              <span>
                {row.original.bank_user.bank_account_detail
                  ? row.original.bank_user.bank_account_detail.bank.name
                  : ""}
              </span>
            ),
          },
          {
            Header: "Player ID",
            Cell: (row) => (
              <span>
                {row.original.player_account.player
                  ? row.original.player_account.player.slug
                  : ""}
              </span>
            ),
          },
          {
            Header: "Product",
            Cell: (row) => (
              <span>
                {row.original.game_platform_user.game_platform
                  ? row.original.game_platform_user.game_platform.name
                  : ""}
              </span>
            ),
          },
          {
            Header: "Game ID",
            Cell: (row) => (
              <span>
                {row.original.player_account
                  ? row.original.player_account.game_platform_username
                  : ""}
              </span>
            ),
          },
          {
            Header: "Deposit",
            Cell: (row) => (
              <span>{parseInt(row.original.deposit_amount, 10)}</span>
            ),
          },
          {
            Header: "Promotion",
            accessor: "promotion.name",
          },
          {
            Header: "Promo Amount",
            Cell: (row) => (
              <span>
                {row.original.bonus_amount
                  ? parseInt(row.original.bonus_amount, 10)
                  : ""}
              </span>
            ),
          },
          {
            Header: "Status",
            Cell: (row) => (
              <>
                <Badge
                  color={
                    row.original.status === "success" ? "success" : "danger"
                  }
                >
                  {row.original.status}
                </Badge>
              </>
            ),
          },
          {
            Header: "Created at",
            Cell: (row) => (
              <span>
                {Moment(row.original.created_at).format("DD/MM/YYYY HH:mm")}
              </span>
            ),
          },
          {
            Header: "Actions",
            Cell: (row) => (
              <div>
                <Button
                  color="primary"
                  className="btn-icon btn-icon-only"
                  onClick={() => {
                    props.onClickEditBank(
                      row.original.id,
                      row.original.bank_user_id
                    );
                  }}
                >
                  <i className="pe-7s-pen btn-icon-wrapper" />
                </Button>
              </div>
            ),
          },
        ]}
      />
      <Pagination
        pages={props.deposits.meta.pages}
        onPageChange={(pg) => {
          let tmp = _.cloneDeep(props.search);
          tmp["page"] = pg;
          props.onChangeSearch(tmp);
          props.getDeposits(tmp);
        }}
        currentPage={props.search.page}
      />
    </>
  );
};

export default TableDeposit;
