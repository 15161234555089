import React from 'react';
import {
  Button
} from 'reactstrap';

const Pagination = (props) => {
  const [pages, setPages] = React.useState([]);
  const [range, setRange] = React.useState([1, 10]);

  React.useEffect(() => {
    let tmp = [];
    for (let i = 1; i <= props.pages; i++) {
      tmp.push(i);
    }    
    setPages(tmp);
  }, [props.pages]);

  return (
    <div className='d-flex align-items-center justify-content-center pt-3'>
      {
        range[0] > 10 && (
          <Button 
            color={props.currentPage === 1? "success" : "primary"}
            className='mr-1'
            onClick={() => {
              setRange([range[0] - 10, range[1] - 10]);
            }}>
            <i className="fa fa-angle-double-left"></i>
          </Button>
        )
      }
      {pages.map((item, index) => {
        if(index + 1 >= range[0] && index <= range[1]) {
          return (
            <Button 
              key={`Page-${item}`}
              color={props.currentPage === item ? "success" : "primary"}
              className='mr-1'
              onClick={() => {
                props.onPageChange(item);
              }}>
              {item}
            </Button>
          )
        }
      })}
      {
        pages.length > 10 && range[1] <= pages.length && (
          <Button 
            color={props.currentPage === pages.length? "success" : "primary"}
            className='mr-1'
            onClick={() => {
              setRange([range[0] + 10, range[1] + 10]);
            }}>
            <i className="fa fa-angle-double-right"></i>
          </Button>
        )
      }
    </div>
  )
}

export default Pagination;