import React from "react";
import PageTitle from "components/Title";
import { Row, Col, Card, CardHeader, CardBody, Table } from "reactstrap";
import _ from "lodash";
import ReactTable from "react-table";
import { compose } from "redux";

import LoadingOverlay from "../../../components/Indicator/LoadingOverlay";

import WithGamePlatformUsers from "../GamePlatformUsers/actions";
import WithGames from "../GamePlatforms/actions";
import WithBankUsers from "../BankUsers/actions";
import WithBanks from "../Banks/actions";

class KioskBalances extends React.Component {
  componentDidMount = () => {
    this.props.getGamePlatformsFilterUserID(this.props.profile.id);
    this.props.getBankUsersFilterUserID(this.props.profile.id);
  };

  render = () => {
    let total_starting_amount = 0;
    let total_deposits = 0;
    let total_withdrawal = 0;
    let total_total_bonus = 0;
    let total_topup = 0;
    let total_balance = 0;
    let total_transfer_in = 0;
    let total_transfer_out = 0;
    this.props.gamePlatformsFilteredUserID.map((item) => {
      total_starting_amount =
        total_starting_amount + parseFloat(item.game_platform.starting_amount);
      total_deposits = total_deposits + parseFloat(item.total_deposits);
      total_withdrawal = total_withdrawal + parseFloat(item.total_withdrawal);
      total_total_bonus = total_total_bonus + parseFloat(item.total_bonus);
      total_topup = total_topup + parseFloat(item.topup);
      total_balance = total_balance + parseFloat(item.balance);
      total_transfer_in =
        total_transfer_in + parseFloat(item.total_transfer_in);
      total_transfer_out =
        total_transfer_out + parseFloat(item.total_transfer_out);
    });
    let bank_user_total_start_amount = 0;
    let bank_user_total_deposits = 0;
    let bank_user_total_withdrawal = 0;
    let bank_user_total_cash_out = 0;
    let bank_user_total_cash_in = 0;
    let bank_user_total_balance = 0;
    this.props.bankUsersFilteredUserID.map((item) => {
      bank_user_total_start_amount =
        bank_user_total_start_amount +
        parseFloat(item.bank_account_detail.starting_amount);
      bank_user_total_deposits =
        bank_user_total_deposits + parseFloat(item.total_deposits);
      bank_user_total_withdrawal =
        bank_user_total_withdrawal + parseFloat(item.total_withdrawal);
      bank_user_total_cash_out =
        bank_user_total_cash_out + parseFloat(item.cash_out);
      bank_user_total_cash_in =
        bank_user_total_cash_in + parseFloat(item.cash_in);
      bank_user_total_balance =
        bank_user_total_balance + parseFloat(item.balance);
    });
    return (
      <>
        <PageTitle
          heading="Kiosk Balances"
          subheading="Kiosk balances in the system."
          icon="pe-7s-config icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <Row>
          <Col lg={12}>
            <Card className="main-card mb-3">
              <CardBody>
                <Table bordered hover responsive>
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th>Product</th>
                      <th>Start Amount</th>
                      <th>Total Deposit</th>
                      <th>Total Withdrawal</th>
                      <th>Total Bonus</th>
                      <th>Topup</th>
                      <th>Total Transfer In</th>
                      <th>Total Transfer Out</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.gamePlatformsFilteredUserID.map((item) => {
                      return (
                        <tr key={item.id} style={{ textAlign: "center" }}>
                          <td>{item.game_platform.name}</td>
                          <td>{item.game_platform.starting_amount}</td>
                          <td>{item.total_deposits}</td>
                          <td>{item.total_withdrawal}</td>
                          <td>{item.total_bonus}</td>
                          <td>{item.topup}</td>
                          <td>{item.total_transfer_in}</td>
                          <td>{item.total_transfer_out}</td>
                          <td>{item.balance}</td>
                        </tr>
                      );
                    })}
                    <tr style={{ fontWeight: "bold", textAlign: "center" }}>
                      <td>Total</td>
                      <td>{total_starting_amount}</td>
                      <td>{total_deposits}</td>
                      <td>{total_withdrawal}</td>
                      <td>{total_total_bonus.toFixed(2)}</td>
                      <td>{total_topup}</td>
                      <td>{total_transfer_in}</td>
                      <td>{total_transfer_out}</td>
                      <td>{total_balance}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <Card className="mt-2">
              <CardBody>
                <Table bordered hover responsive>
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th>Bank</th>
                      <th>Start Amount</th>
                      <th>Total Deposit</th>
                      <th>Total Withdrawal</th>
                      <th>Cash out</th>
                      <th>Cash in</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.bankUsersFilteredUserID.map((item) => {
                      return (
                        <tr key={item.id} style={{ textAlign: "center" }}>
                          <td>{`${item.bank_account_detail.name} - ${item.bank_account_detail.bank.name}`}</td>
                          <td>{item.bank_account_detail.starting_amount}</td>
                          <td>{item.total_deposits}</td>
                          <td>{item.total_withdrawal}</td>
                          <td>{item.cash_out}</td>
                          <td>{item.cash_in}</td>
                          <td>{item.balance}</td>
                        </tr>
                      );
                    })}
                    <tr style={{ fontWeight: "bold", textAlign: "center" }}>
                      <td>Total</td>
                      <td>{bank_user_total_start_amount}</td>
                      <td>{bank_user_total_deposits}</td>
                      <td>{bank_user_total_withdrawal}</td>
                      <td>{bank_user_total_cash_out}</td>
                      <td>{bank_user_total_cash_in}</td>
                      <td>{bank_user_total_balance}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {(this.props.onLoadBankUsers ||
          this.props.onLoadBanks ||
          this.props.onLoadGamePlatformUsers ||
          this.props.onLoadGamePlatforms) && <LoadingOverlay />}
      </>
    );
  };
}

export default compose(
  WithGamePlatformUsers,
  WithGames,
  WithBankUsers,
  WithBanks
)(KioskBalances);
