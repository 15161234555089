import React, { Component } from "react";
import Axios from "axios";
import _ from "lodash";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      lastSucceededDepositIndex: null,
      deposits: {
        data: [],
        meta: {
          pages: 1,
        },
      },
      newDeposits: [],
    };

    oCKioskDepositHOC = (key, val) => this.setState({ [key]: val });

    getDeposits = async (search) => {
      this.setState({ loading: true });
      let tmp = "";
      if (search) {
        Object.keys(search).map((key) => {
          if (
            search[key] &&
            search[key] !== "" &&
            typeof search[key] !== "number"
          ) {
            if (tmp.length === 0) {
              tmp += `${key}=${search[key]}`;
            } else {
              tmp += `&${key}=${search[key]}`;
            }
          }
          if (typeof search[key] === "number") {
            tmp += `&${key}=${search[key]}`;
          }
        });
      }
      try {
        const response = await Axios.get(
          `${STAGING_BACKEND_URL}/deposits${tmp.length > 0 ? `?${tmp}` : ``}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ deposits: response.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    postDeposit = async (data, uuid) => {
      this.setState({ loading: true });
      try {
        const response = await Axios.post(
          `${STAGING_BACKEND_URL}/kiosks/deposit`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        let tmp = _.cloneDeep(this.state.newDeposits);
        _.remove(tmp, { uuid: uuid });
        console.log(tmp, "tmp");
        this.setState({ newDeposits: tmp }, () => {
          this.getDeposits(this.state.search);
          toast.success("Deposit is created successfully");
        });
      } catch (error) {
        console.log(error, "error");
        toast.error(error.response.data.errors[0]);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          getDeposits={this.getDeposits}
          postDeposit={this.postDeposit}
          oCKioskDepositHOC={this.oCKioskDepositHOC}
          lastSucceededDepositIndex={this.state.lastSucceededDepositIndex}
          onLoadDeposits={this.state.loading}
          deposits={this.state.deposits}
          newDeposits={this.state.newDeposits}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
