import React from "react";
import Axios from "axios";
import _ from "lodash";

import { STAGING_BACKEND_URL, X_PUBLIC_KEY } from "constants";
import { toast } from "react-toastify";

const HOC = (WrappedComponent) => {
  class WithHOC extends React.Component {
    state = {
      loading: false,
      transfers: [],
      newTransfers: [],
      newTransfer: {
        amount: "",
        from_player_account_id: "",
        to_player_account_id: "",
      },
    };

    oCTransferHOC = (key, val) => this.setState({ [key]: val });

    getTransfers = async (search) => {
      this.setState({ loading: true });
      let tmp = "";
      if (search) {
        Object.keys(search).map((key) => {
          if (search[key] && search[key] !== "") {
            if (tmp.length === 0) {
              tmp += `${key}=${search[key]}`;
            } else {
              tmp += `&${key}=${search[key]}`;
            }
          }
        });
      }
      try {
        let res = await Axios.get(
          `${STAGING_BACKEND_URL}/transfers${tmp.length > 0 ? `?${tmp}` : ``}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "GAMEPORT_ACCESS_TOKEN"
              )}`,
              "X-Public-Key": X_PUBLIC_KEY,
            },
          }
        );
        this.setState({ transfers: res.data.data });
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    createTransfer = async (data, index) => {
      this.setState({ loading: true });
      try {
        let res = await Axios.post(`${STAGING_BACKEND_URL}/transfers`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "GAMEPORT_ACCESS_TOKEN"
            )}`,
            "X-Public-Key": X_PUBLIC_KEY,
          },
        });
        let tmp = _.cloneDeep(this.state.newTransfers);
        tmp.splice(index, 1);
        this.setState({ newTransfers: tmp });
        this.getTransfers();
        toast.success("Transfer is created successfully");
      } catch (error) {
        toast.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    };

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadTransfers={this.state.loading}
          newTransfer={this.state.newTransfer}
          newTransfers={this.state.newTransfers}
          transfers={this.state.transfers}
          getTransfers={this.getTransfers}
          createTransfer={this.createTransfer}
          oCTransferHOC={this.oCTransferHOC}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
