import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardSubtitle,
  Button,
} from "reactstrap";
import ReactTable from "react-table";
import Moment from "moment";

import LoadingIndicator from "components/Indicator/LoadingOverlay";
import PageTitle from "components/Title";

import WithShifts from "./actions/Shifts";

class Statistics extends React.Component {
  componentDidMount = () => {
    this.props.getShifts();
  };

  render = () => {
    let tmpButton = [];
    if (this.props.profile.role === "staff") {
      tmpButton.push({
        color: "danger",
        content: "Close shift",
        onClick: () => {
          this.props.closeShift();
        },
      });
    }
    return (
      <>
        <PageTitle
          heading="Shifts"
          subheading="All shifts in the system."
          icon="pe-7s-users icon-gradient bg-happy-itmeo"
          buttons={tmpButton}
        />
        <Container>
          <Row>
            <Card>
              <CardBody>
                <ReactTable
                  pageSize={20}
                  data={this.props.shifts}
                  columns={[
                    {
                      Header: "ID",
                      accessor: "id",
                    },
                    {
                      Header: "User ID",
                      accessor: "user.username",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Started at",
                      Cell: (row) => {
                        return (
                          <span>
                            {Moment(row.original.created_at).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </span>
                        );
                      },
                    },
                    {
                      Header: "Closed at",
                      Cell: (row) => {
                        return (
                          <span>
                            {row.original.closed_at
                              ? Moment(row.original.closed_at).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : ""}
                          </span>
                        );
                      },
                    },
                    {
                      Header: "Total active players",
                      accessor: "total_active_players",
                    },
                    {
                      Header: "Total new players",
                      accessor: "total_new_players",
                    },
                  ]}
                />
              </CardBody>
            </Card>
          </Row>
          {this.props.onLoadShifts && <LoadingIndicator />}
        </Container>
      </>
    );
  };
}

export default WithShifts(Statistics);
