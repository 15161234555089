import React from "react";
import { Row, Col, Button, FormGroup, Label, Input, Table } from "reactstrap";
import _ from "lodash";

const PromotionsTab = (props) => {
  const [data, updateData] = React.useState({
    deposit_amount: 0,
    bonus_amount: 0,
    promotion_id: 0,
  });
  return (
    <Col md={3}>
      {/* <Col md={6}>
        <FormGroup className="pb-2">
          <Label className="mr-2">Deposit amount</Label>
          <Input
            type="number"
            value={data.deposit_amount}
            onChange={(e) => {
              updateData({
                ...data,
                deposit_amount: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup className="pb-2">
          <Label className="mr-2">Promotions</Label>
          <Input
            type="select"
            className="form-control"
            value={data.promotion_id}
            onChange={(e) => {
              updateData({
                ...data,
                promotion_id: e.target.value,
              });
            }}
          >
            <option value={""}></option>
            {props.promotions.map((item) => (
              <option key={`Promotion-${item.id}`} value={item.id}>
                {item.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Button
            color="primary"
            disabled={data.deposit_amount === 0 || data.promotion_id === 0}
            onClick={() => {
              if (data.deposit_amount !== 0 || data.promotion_id !== 0) {
                let tmpBonusAmount = 0;
                let tmpPromo = _.find(props.promotions, {
                  id: parseInt(data.promotion_id, 10),
                });
                console.log(tmpPromo, "tmpPromo");
                let tmpDiscount =
                  tmpPromo.claimable_type === "amount"
                    ? parseFloat(tmpPromo.claimable_amount)
                    : tmpPromo.claimable_percent / 100;
                console.log(tmpDiscount, "tmpDiscount");
                // tmp.promotion_id = e.target.value;
                tmpBonusAmount =
                  tmpPromo.claimable_type === "amount"
                    ? parseFloat(tmpDiscount)
                    : parseFloat(data.deposit_amount) * parseFloat(tmpDiscount);
                if (tmpBonusAmount > parseFloat(tmpPromo.max_capacity)) {
                  tmpBonusAmount = tmpPromo.max_capacity;
                }
                updateData({
                  ...data,
                  bonus_amount: tmpBonusAmount,
                });
              }
            }}
          >
            Submit
          </Button>
        </FormGroup>
      </Col>
      <Col md={6}>
        <Table>
          <thead>
            <td>Deposit Amount</td>
            <td>Promotion ID - Bonus</td>
            <td>Total amount given</td>
          </thead>
          <tbody>
            <tr>
              <td>{data.deposit_amount}</td>
              <td>
                <span style={{marginRight:"3px"}}>{
                  _.find(props.promotions, {
                    id: parseInt(data.promotion_id, 10),
                  })?.id
                }</span>
                <span>- </span>
                {
                  _.find(props.promotions, {
                    id: parseInt(data.promotion_id, 10),
                  })?.name
                }
              </td>
              <td>{data.bonus_amount}</td>
            </tr>
          </tbody>
        </Table>
      </Col> */}
      <FormGroup className="pb-2">
        <Label className="mr-2">Promotions</Label>
        <Table>
          <thead>
            <td>ID</td>
            <td>Name</td>
          </thead>
          <tbody>
            {props.promotions.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </FormGroup>
    </Col>
  );
};

export default PromotionsTab;
